import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  customStyle,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const WorkPeriodReport = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);

  let [newWorkPeriodReport, setNewWorkPeriodReport] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);
  const [stockData, setStockData] = useState(null);
  

  const componentRef = useRef();
  const componentRef1 = useRef();
  //settle order
  const [startDate, setStartDate] = useState(new Date());
  const [generatedReport, setGeneratedReport] = useState(false);
  const [stockReport, setStockReport] = useState(false);
  const [siteName, setSiteName] = useState("");
  const componentRefThermal = useRef();
  const componentRefStockThermal = useRef();
  //useEffect == componentDidMount()
  const { authUserInfo } = useContext(UserContext);
  const [option,setOption] = useState("excel");

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
  
  }, [generalSettings]);

  const handleBranch = (branch) => {
    setNewWorkPeriodReport({
      ...newWorkPeriodReport,
      branch,
    });
    // setBranch(branch);
  };
  // excel or print option
  const handleOption  = (e) =>{
    setOption(e.target.value)
  }

  const thermalPrint = useReactToPrint({
    content: () => componentRefThermal.current,
  });

  const thermalPrintStock = useReactToPrint({
    content: () => componentRefStockThermal.current,
  });
  
  // get branch wise stock
  const getBranchWiseStock = () => {
    setStockData(null);
    let $branch_id = "";
    if (
      authUserInfo.details !== null &&
      (authUserInfo.details.user_type !== "staff"
      || authUserInfo.details.user_type !== "manager")
    ) {
      $branch_id = newWorkPeriodReport?.branch?.id;
    } else {
      $branch_id = authUserInfo?.details?.branch_id;
    }

    if ($branch_id !== undefined && startDate !== null) {
      setLoading(true);
      let fromDate = moment(startDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/day-end-physical-stock-report";

      let formData = new FormData();
      formData.append("document_date", fromDate);
      formData.append("branch_id", $branch_id);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setStockData(res.data.data);

          setLoading(false);
          setStockReport(true);
          if(option==="excel"){
            componentRef1.current.handleDownload();
          }else{
            thermalPrintStock();
          }
          setNewWorkPeriodReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select date and branch to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };
  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setReportData(null);
    let $branch_id = "";
    if (
      authUserInfo.details !== null &&
      (authUserInfo.details.user_type !== "staff"
      || authUserInfo.details.user_type !== "manager")
    ) {
      $branch_id = newWorkPeriodReport?.branch?.id;
    } else {
      $branch_id = authUserInfo?.details?.branch_id;
    }

    if ($branch_id !== undefined && startDate !== null) {
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-work-period-report";

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("branch_id", $branch_id);
      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("WorkPeriodReportResult", res);
          setReportData(res.data[0]);

          setLoading(false);
          setGeneratedReport(true);
          if(option==="excel"){
            componentRef.current.handleDownload();
          }else{
            thermalPrint();
          }
          setNewWorkPeriodReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select date and branch to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let total_qty = 0;
  let total_amount = 0;

  let total_qty_print = 0;
  let total_amount_print = 0;

  let departPaymentSales = [];
  for (const keys in reportData?.data?.payment_sales) {
    departPaymentSales.push(
      <tr>
        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
          {keys}
        </th>
        {
          option === "excel" && (
            <td></td>
          )
        }
        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
          {Math.round(reportData?.data?.payment_sales[keys], 2)}
        </td>
      </tr>
    );
  }

  // Order types
  let orderTypesSales = [];
  for (const keys in reportData?.data?.order_type_sales) {
    orderTypesSales.push(
      <tr>
        <th className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
          {keys}
        </th>
        <td className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
          {reportData?.data && reportData.data?.order_type_sales_num[keys]}
        </td>
        <td className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
          {reportData?.data && reportData.data?.order_type_sales[keys]}
        </td>
      </tr>
    );
  }

  // Party Wise
  let partyWiseSales = [];
  for (const keys in reportData?.data?.party_wise_sales) {
    partyWiseSales.push(
      <tr>
        <th className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
          {keys}
        </th>
        <td className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
          {reportData?.data.party_wise_sales_no[keys]}
        </td>
        <td className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
          {reportData?.data.party_wise_sales[keys]}
        </td>
      </tr>
    );
  }
  console.log("Abcd44",newWorkPeriodReport)
  return (
    <>
      <Helmet>
        <title>{_t(t("Work Period Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Work Period Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="stock-to-xls"
        filename="Day End Stock Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef1}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="5" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Work Period Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                        </p>
                                        <p className="text-center">
                                          {reportData.data.day_status}
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                <tr>
                                  <th>
                                    Report Date
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.report_date}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Terminal
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.branch_name}
                                  </td>
                                </tr>
                                {/* <tr>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                    Open Date
                                  </th>
                                  <td></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {reportData.data.workperiod_status.start}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                    Close Date
                                  </th>
                                  <td></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {reportData.data.workperiod_status.end}
                                  </td>
                                </tr> */}
                                <tr>
                                  <th>
                                    User Name
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.user.name}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <th
                                    colspan="3"
                                  >
                                    SALES DETAILS
                                  </th>
                                </tr>
                                <tr>
                                  <th>
                                    Sales Variance
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details?.sale_variance}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Gross Sales
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details.gross_total}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Discount
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details?.discount}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Cancelled
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details?.cancelled}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Refund
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details?.total_refund}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Free of Cost
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details?.total_foc}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Net Sales
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details?.net_sales}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Tax Amount
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.sales_details?.tax_amount}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Net Sales Including Tax
                                  </th>
                                  <td></td>
                                  <td>
                                    {
                                      reportData.data.sales_details
                                        ?.taxable_total
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Delivery Charges
                                  </th>
                                  <td></td>
                                  <td>
                                    {
                                      reportData.data.sales_details
                                        ?.service_charge
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Service Charges
                                  </th>
                                  <td></td>
                                  <td>
                                    {
                                      reportData.data.sales_details
                                        ?.service_amount
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Department Commission
                                  </th>
                                  <td></td>
                                  <td>
                                    {
                                      reportData.data.sales_details
                                        ?.dept_commission
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Total Collected
                                  </th>
                                  <td></td>
                                  <td>
                                    {
                                      reportData.data.sales_details
                                        ?.total_collected
                                    }
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <th
                                    colspan="3"
                                  >
                                    INSIGHT
                                  </th>
                                </tr>
                                <tr>
                                  <th>
                                    Number Of Orders
                                  </th>
                                  <td></td>
                                  <td>
                                    {reportData.data.insights?.number_of_orders}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Number Of Customers
                                  </th>
                                  <td></td>
                                  <td>
                                    {
                                      reportData.data.insights
                                        ?.number_of_customers
                                    }
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <th
                                    colspan="3"
                                  >
                                    PAYMENT WISE SALES
                                  </th>
                                </tr>
                                {departPaymentSales}
                                <tr>
                                  <th>
                                    Total
                                  </th>
                                  <th></th>
                                  <th>
                                    {
                                      reportData.data.sales_details
                                        ?.payment_type_total
                                    }
                                  </th>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <th
                                    colspan="3"
                                  >
                                    ORDER TYPE SALES
                                  </th>
                                </tr>
                                {orderTypesSales}
                                <tr>
                                  <th>
                                    Total
                                  </th>
                                  <th></th>
                                  <th>
                                    {
                                      reportData.data.sales_details
                                        ?.order_type_total
                                    }
                                  </th>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <th
                                    colspan="3"
                                  >
                                    PARTY WISE SALES
                                  </th>
                                </tr>
                                {partyWiseSales}
                                <tr>
                                  <th>
                                    Total
                                  </th>
                                  <th></th>
                                  <th>
                                    {reportData.data.sales_details?.party_total}
                                  </th>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                  <th
                                    colspan="5"
                                  >
                                    Item Details
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Food Group"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Food Item"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Variation"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                </tr>
                                {reportData.data.order_details.map(
                                  (item, i) => {
                                    total_qty += parseFloat(item.quantity);
                                    total_amount += parseFloat(item.price);
                                    return (
                                      <tr>
                                        <td
                                          scope="row"
                                        >
                                          {item.food_group}
                                        </td>
                                        <td
                                          scope="row"
                                        >
                                          {item.food_item}
                                        </td>
                                        <td
                                          scope="row"
                                        >
                                          {item.variation}
                                        </td>
                                        <td
                                          scope="row"
                                        >
                                          {item.quantity}
                                        </td>
                                        <td
                                          scope="row"
                                        >
                                          {item.price}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                <tr>
                                  <th
                                    colspan="3"
                                  >
                                    Total
                                  </th>
                                  <th>{total_qty}</th>
                                  <th>{total_amount.toFixed(2)}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Print Button stock */}
      <div className="d-none">
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {stockData !== null &&
                  stockData !== undefined &&
                  stockReport === true && (
                    <>
                      <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="stock-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                <th colspan="16" style={{ border: "0px" }}>
                                  <div className="row">
                                    <div className="col-12">
                                      <h3 className="text-center mb-0">
                                        {siteName}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                        Day End Stock Report
                                      </h3>
                                      <p className="mt-0 text-center lg-text">
                                        {newWorkPeriodReport?.branch?.name}
                                      </p>
                                      <p className="text-center">
                                        FROM&nbsp;
                                        <Moment format="LL">
                                          {startDate}
                                        </Moment>
                                      </p>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr className="align-middle">
                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Item
                                </th>
                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Opening
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Purchase
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Trans In
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Prod In
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Total
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Trans Out
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Sale
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Made Void
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Discard
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Production
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Total
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Sys End Count
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Phy End Count
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Diff
                                </th>

                                <th scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                                  Amt
                                </th>
                              </tr>                                                           
                            </thead>
                            <tbody className="align-middle">
                                {
                                stockData && stockData?.map((value)=>{  
                                const totalIn = parseFloat(value?.Opening) + parseFloat(value?.Purchase) + parseFloat(value?.TrasferInn) + parseFloat(value?.ProductionInn); 
                                const totalOut =  parseFloat(value?.TrasferOut) + parseFloat(value?.Sales) + parseFloat(value?.MadeVoid) + parseFloat(value?.Discard) + parseFloat(value.Production);
                                const sysEndCount = totalIn - totalOut;
                                const diff = parseFloat(value?.PhysicalEndCount) - sysEndCount;
                                return(
                                  <tr className="align-middle">
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.name}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.Opening}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Purchase).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.TrasferInn).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.ProductionInn).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(totalIn).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.TrasferOut).toFixed(2)}</td> 
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Sales).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.MadeVoid).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Discard).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Production).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(totalOut).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(sysEndCount).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.PhysicalEndCount}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(diff).toFixed(2)}</td>
                                    <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>0</td>
                                  </tr>
                                  )
                                })
                                }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

    {/* stock print */}
    <div className="d-none">
       <div ref={componentRefStockThermal}>
         <div className="fk-print"  style={{margin:"20px 30px"}}>
           {
            stockData &&
            stockData !== null && stockReport && (
              <div className="row">
                 <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="xslg-text text-center mb-0">
                        {siteName}
                      </div>
                      <div className="mt-2 xslg-text text-center">
                        Day End Stock Report
                      </div>
                      <p className="mt-2 xslg-text text-center">
                        FROM&nbsp;
                        <Moment format="LL">
                          {startDate}
                        </Moment>
                      </p>
                      <p className="mt-0 mb-2 text-center lg-text">
                        {newWorkPeriodReport?.branch?.name}
                      </p>
                      <p className="mt-0 mb-2 text-center lg-text">
                        Print Time:{" "} 
                        <Moment format="LLL">
                          {new Date()}
                        </Moment>
                      </p>
                    </div>
                  </div>
                 {/*stock wise report */}
                 <div className="col-12 mt-2">
                  <table className="table table-bordered-sm table-hover">
                    <thead className="align-middle">
                      <tr className="align-middle">
                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Item
                        </th>
                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Opening
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Purchase
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Trans In
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Prod In
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Total
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Trans Out
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Sale
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Made Void
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Discard
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Production
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Total
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Sys End Count
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Phy End Count
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Diff
                        </th>

                        <th scope="col"
                          className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"3px" }}>
                          Amt
                        </th>
                      </tr> 
                    </thead>
                    <tbody className="align-middle">
                        {
                        stockData && stockData?.map((value)=>{  
                        const totalIn = parseFloat(value?.Opening) + parseFloat(value?.Purchase) + parseFloat(value?.TrasferInn) + parseFloat(value?.ProductionInn); 
                        const totalOut =  parseFloat(value?.TrasferOut) + parseFloat(value?.Sales) + parseFloat(value?.MadeVoid) + parseFloat(value?.Discard) + parseFloat(value.Production);
                        const sysEndCount = totalIn - totalOut;
                        const diff = parseFloat(value?.PhysicalEndCount) - sysEndCount;
                        return(
                          <tr className="align-middle">
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.name}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.Opening}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Purchase).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.TrasferInn).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.ProductionInn).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(totalIn).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.TrasferOut).toFixed(2)}</td> 
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Sales).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.MadeVoid).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Discard).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(value?.Production).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(totalOut).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(sysEndCount).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{value?.PhysicalEndCount}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>{parseFloat(diff).toFixed(2)}</td>
                            <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"3px" }}>0</td>
                          </tr>
                          )
                        })
                        }
                    </tbody>
                  </table>
                   
                 </div>
               </div> 
              </div> 
            )
           }
         </div> 
       </div> 
    </div>

      {/* print */}
      <div className="d-none">
       <div ref={componentRefThermal}>
           <div className="fk-print"  style={{margin:"20px 30px"}}>
           {
            reportData &&
            reportData !== null && (
              <div className="row">
                 <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="xslg-text text-center mb-0">
                        {siteName}
                      </div>
                      <div className="mt-2 xslg-text text-center">
                        Work Period Report
                      </div>
                      <p className="mt-2 xslg-text text-center">
                        FROM&nbsp;
                        <Moment format="LL">
                          {startDate}
                        </Moment>
                      </p>
                      <p className="mt-0 text-center lg-text">
                        Print Time:{" "} 
                        <Moment format="LLL">
                          {new Date()}
                        </Moment>
                      </p>
                    </div>
                  </div>
                 <div>
                    <p className="mt-0 lg-text text-center">
                      {reportData.data.day_status}
                    </p>
                 </div>
                 {/*production wise report */}
                 <div className="col-12 mt-2">
                   <table className="table">
                    <tbody className="align-middle">
                      <tr className="align-middle">
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Report Date
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.report_date}
                        </td>
                      </tr>
                      <tr className="align-middle">
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Terminal
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.branch_name}
                        </td>
                      </tr>
                      <tr className="align-middle">
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          User Name
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.user.name}
                        </td>
                      </tr>

                    </tbody> 
                   </table>   
                   <div className="myBorder my-2"></div>
                    <div
                      className="mt-2 mb-1 font-weight-md xslg-text text-center"
                    >
                      SALES DETAILS
                    </div>
                  
                  <table className="table">  
                    <tbody>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Sales Variance
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details?.sale_variance}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Gross Sales
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details.gross_total}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Discount
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details?.discount}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Cancelled
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details.cancelled}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Refund
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details.total_refund}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Free of Cost
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details?.total_foc}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Net Sales
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details?.net_sales}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Tax Amount
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.sales_details?.tax_amount}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Net Sales Including Tax
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {
                            reportData.data.sales_details
                              ?.taxable_total
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Delivery Charges
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {
                            reportData.data.sales_details
                              ?.service_charge
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Service Charges
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {
                            reportData.data.sales_details
                              ?.service_amount
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Department Commission
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {
                            reportData.data.sales_details
                              ?.dept_commission
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Total Collected
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {
                            reportData.data.sales_details
                              ?.total_collected
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table> 
                  <div className="myBorder my-2"></div>
                    <div
                      className="mt-2 mb-1 font-weight-md xslg-text text-center"
                    >
                      INSIGHT
                    </div>
                    <table className="table">
                     <tbody>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Number Of Orders
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {reportData.data.insights?.number_of_orders}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Number Of Customers
                        </th>
                        <td className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {
                            reportData.data.insights
                              ?.number_of_customers
                          }
                        </td>
                      </tr>
                     </tbody> 
                    </table>
                    <div className="myBorder my-2"></div>
                    <div
                      className="mt-2 mb-1 font-weight-md xslg-text text-center"
                    >
                      PAYMENT WISE SALES
                    </div>
                   
                    <table className="table">
                    <tbody>
                     {departPaymentSales}
                      <tr>
                        <th className="table-text text-capitalize align-middle text-left border-0 p-1">
                          Total
                        </th>
                        <th className="table-text text-capitalize align-middle text-right border-0 p-1">
                          {
                            reportData.data.sales_details
                              ?.payment_type_total
                          }
                        </th>
                      </tr>
                     </tbody> 
                    </table>  
                    <div className="myBorder my-2"></div>

                    <div
                      className="mt-2 mb-1 font-weight-md xslg-text text-center"
                    >
                      ORDER TYPE SALES
                    </div>
                   
                    <table className="table">
                     <tbody>
                      {orderTypesSales}
                      <tr>
                        <th colspan="2" className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
                          Total
                        </th>
                        <th className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
                          {
                            reportData.data.sales_details
                              ?.order_type_total
                          }
                        </th>
                      </tr>
                     </tbody> 
                    </table>  
                    <div className="myBorder my-2"></div>

                    <div
                      className="mt-2 mb-1 font-weight-md xslg-text text-center"
                    >
                      PARTY WISE SALES
                    </div>
                  
                    <table className="table">
                     <tbody>
                      {partyWiseSales}
                      <tr>
                        <th colspan="2" className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
                          Total
                        </th>
                        <th className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">
                          {reportData.data.sales_details?.party_total}
                        </th>
                      </tr>
                      </tbody>
                    </table>  
                   <div className="myBorder my-2"></div>

                    <div
                      className="mt-2 mb-1 font-weight-md xslg-text text-center"
                    >
                      ITEM DETAILS
                    </div>
  
                    <table className="table">
                     <thead>
                      <tr>
                      <th
                        scope="col"
                        className="lg-text font-weight-md text-capitalize align-middle text-center border-1 border-dark p-1"
                      >
                        {_t(t("Food Group"))}
                      </th>
                      <th
                        scope="col"
                        className="lg-text font-weight-md text-capitalize align-middle text-center border-1 border-dark p-1"
                      >
                        {_t(t("Food Item"))}
                      </th>
                      <th
                        scope="col"
                        className="lg-text font-weight-md text-capitalize align-middle text-center border-1 border-dark p-1"
                      >
                        {_t(t("Variation"))}
                      </th>
                      <th
                        scope="col"
                        className="lg-text font-weight-md text-capitalize align-middle text-center border-1 border-dark p-1"
                      >
                        {_t(t("Qty"))}
                      </th>
                      <th
                        scope="col"
                        className="lg-text font-weight-md text-capitalize align-middle text-center border-1 border-dark p-1"
                      >
                        {_t(t("Amount"))}
                      </th>
                      </tr>
                     </thead>
                     <tbody>
                      {reportData.data.order_details.map(
                        (item, i) => {
                          total_qty_print += parseFloat(item.quantity);
                          total_amount_print += parseFloat(item.price);
                          return (
                            <tr>
                              <td
                                scope="row"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"
                              >
                                {item.food_group}
                              </td>
                              <td
                                scope="row"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"
                              >
                                {item.food_item}
                              </td>
                              <td
                                scope="row"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"
                              >
                                {item.variation}
                              </td>
                              <td
                                scope="row"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"
                              >
                                {item.quantity}
                              </td>
                              <td
                                scope="row"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"
                              >
                                {item.price}
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <tr>
                        <th
                          colspan="3"
                          className="lg-text font-weight-md text-capitalize align-middle text-center border-1 border-dark p-1"
                        >
                          Total
                        </th>
                        <th className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">{total_qty_print}</th>
                        <th className="table-text text-capitalize align-middle text-center border-1 border-dark p-1">{total_amount_print.toFixed(2)}</th>
                      </tr>
                     </tbody>
                    </table>
                   
                 </div>
               </div> 
              </div> 
            )
           }
         </div> 
       </div> 
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading <div className={`${dataPaginating && "loading"}`}></div> */}
                    
                    {/* spin loading ends */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                    {/* Loading effect */}
                    
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-6 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Work Period Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-6 d-flex justify-content-end p-0">
                            <div className="d-inline mr-3 d-flex align-items-center">
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="excel_report"
                                name="excel_report"
                                value="excel"
                                onChange={(e) => {
                                  handleOption(e);
                                }}
                                checked={
                                  option === "excel"
                                    ? 1
                                    : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                htmlFor="excel_report"
                                style={{fontSize:"16px",borderRadius:"5px"}}
                              >
                                <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                              </label>
                            </div>

                            <div className="d-inline mr-2 d-flex align-items-center">
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="print_report"
                                name="print_report"
                                value="print"
                                onChange={(e) => {
                                  handleOption(e);
                                }}
                                checked={
                                  option === "print"
                                    ? 1
                                    : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                htmlFor="print_report"
                                style={{fontSize:"16px",borderRadius:"5px"}}
                              >
                                <i class="fa fa-print" aria-hidden="true"></i> {_t(t("Print"))}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-2">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          {authUserInfo.details !== null &&
                          (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager") ? (
                            <>
                              <div className="col-3 t-mb-15 mb-md-0">
                                <Select
                                  options={branchForSearch && branchForSearch}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  isClearable
                                  styles={customStyle}
                                  value={newWorkPeriodReport?.branch || null}
                                  classNamePrefix="select"
                                  className="sm-text "
                                  onChange={handleBranch}
                                  maxMenuHeight="200px"
                                  placeholder={_t(t("Select a branch")) + ".."}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="col-1"></div>
                          <div className="col-3 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            {/* Action Button */}
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Print Work Period Report
                            </button>
                          </div>
                          <div className="col-3 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            {/* Action Button */}
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseStock(e)}
                            >
                              Day End Stock Report
                            </button>
                          </div>
                        </div>
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default WorkPeriodReport;
