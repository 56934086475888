import React, {useState,useEffect} from 'react'
import { BASE_URL } from '../../../BaseUrl';
import { useHistory } from "react-router-dom";
import { getCookie } from '../../../functions/Functions';

const SalesListing = () => {
    const [data, setData] = useState(null);
    const history = useHistory();
    useEffect(() => {
        let key = window.location.search;
        const value = new URLSearchParams(key).get('key');
        
        fetch(BASE_URL+`/sales-listing?API-Key=${value}`, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => {
            setData(data);         
        })
        .catch(error => {
            console.error('Error fetching data:', error);            
        });
        // if(getCookie()){
        // } else {
        //     history.push('/')
        // }
        }, []); 
        
    return (
        <>
            {data ? (
                <pre style={{ maxHeight: '100vh', overflowY: 'auto' }}>{JSON.stringify(data, null, 2)}</pre>
            ) : (
                <p>No data available</p>
            )}
        </>
    )
}

export default SalesListing;