import React from 'react'

const MultiAddress = ({
    showCustomer,
    addressList,
    setCustomerApi,
    setCustomer,
    customer,
    setShowCustomer,

}) => {
    // multi addres pop up
    const addressChange =(e)=>{
        setCustomer({
          ...customer,
          address: e.target.value
        })
        setCustomerApi(0);
      }

  return (
    <>
    {/*multi address Modal*/}
    <div
    className={`modal fade ${
      showCustomer !== false ? "showing" : ""
    }`}
    id="customerAddress1"
    aria-hidden="true"
    style={{
      zIndex:14999
    }}
  >
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header align-items-center">
          <div className="fk-sm-card__content"></div>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              setShowCustomer(false);
            }}
          ></button>
        </div>
        <div className="modal-body">
          {/* show form or show saving loading */}
          <div key="fragment-food-group-1">
              <label htmlFor="name" className="form-label w-100 p-0" style={{fontSize:"1.2rem",fontWeight:"600"}}>
                 Multiple Address
              </label>
             <div className="modal-scroll">
                {
                  addressList && addressList?.map((adress,index)=>{
                    return(
                      <label key={index} className="d-flex align-items-center add-container cursor-pointer">
                      <input
                      type="radio"
                      id={adress.address}
                      name="multiAddress"
                      value={adress.address}
                      defaultChecked={index === 0}
                      onChange={addressChange}
                      />
                      <span className="ml-2 d-flex align-items-center">
                      {adress.address}
                      </span>
                      </label>
                    )
                  })
                }
                
              </div>
              
              <div className="mt-4">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-4">
                    <button
                      type="submit"
                      className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                      data-dismiss="modal"
                      onClick={(e) => {
                      setShowCustomer(false);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default MultiAddress
