import React from "react";
import { NavLink } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../src/BaseUrl";

//3rd party packages
import ReactPaginate from "react-paginate";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Cookies from "universal-cookie";
//importing ends
const cookies = new Cookies();

// functions starts here
//console functions, clear and dummy text like facebook
const consolee = () => {
  var cssStop = "color: Red; font-size: 50px; font-weight: bold;";
  var cssText = "color: Black; font-size: 18px; font-weight: bold;";
  console.clear();
  console.log("%cStop!", cssStop);
  console.log(
    "%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or hack someone's account, it is a scam.",
    cssText
  );
};

 //select style
 const customStyle = {

  control: (provided) => ({
    ...provided,
    minHeight: "2rem",
    marginTop: "0",
    marginBottom: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    alignSelf: "center",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.5rem",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 0.4rem",
    svg: {
      height: "2rem",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
   margin: "0.4rem 0",// or any other custom styling
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999, // Ensure the menu is above other elements
  }),
  clearIndicator:(provided) => ({
    ...provided,
   padding: "0 0.4rem",// or any other custom styling
  }),  

};

//translation functions
let _t = (text) => {
  // let localLang = localStorage.getItem("i18nextLng");
  // if (localLang.includes("en")) {
  //   const url = BASE_URL + `/settings/save-to-en`;
  //   let formData = {
  //     key: text,
  //   };
  //   axios.post(url, formData);
  // }
  return text;
};

//Get cookie of authentication
const getCookie = () => {
  if (cookies.get("_user") !== undefined) {
    let token =
      cookies.get("_user") +
      cookies.get("sbb") +
      cookies.get("frr") +
      cookies.get("xss");
    return token;
  }
};

 // install check internet
 const checkInstallOffline = async () => {
  const url = BASE_URL + "/check-net";
    try{
      let result = await axios.get(url,{
        timeout: 3000,
      });
      if(result.data){
        return false
      }
    }catch(err){
        return true
    }    
 };

//check permission
const checkPermission = (authUserPermissions, permission) => {
  if (authUserPermissions !== false)
    if (authUserPermissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
};

// show property price
const showPropertyPrice=(qty,propQty,price,taxType,branchTax)=>{
  let propPrice = 0;
  if (taxType === "inclusive") {
    propPrice = qty * propQty * price;
    let finalPrice = propPrice / (1 + branchTax / 100);
    return formatPrice(finalPrice);
  }else{
    propPrice = qty * propQty * price;
    return formatPrice(propPrice);
  }
}


 //show price of each item in print
 const showPriceOfEachOrderItemPrint = (thisItem, taxType, branchTax) => {
  let price = 0;
  let tempPropertyPrice = 0;
  if (thisItem.properties !== null && thisItem.properties !== "") {
    let propertyItems = JSON.parse(thisItem.properties);
    propertyItems.forEach((propertyItem, thisIndex) => {
      let temp =
        propertyItem.quantity *
        propertyItem.price_per_qty *
        thisItem.quantity;
      tempPropertyPrice = tempPropertyPrice + temp;
    });
  }
  price = thisItem.price - tempPropertyPrice;
  if (taxType === "inclusive") {
    let finalPrice = price / (1 + branchTax / 100);
    return formatPrice(finalPrice);
  }else{
    return formatPrice(price);
  }
};


//currency format price
const formatPrice = (price) => {
  let localCurrency = JSON.parse(localStorage.getItem("currency"));
  if (localCurrency !== null) {
    return (localCurrency.rate * price).toFixed(2);
  } else {
    return 0;
  }
};
const currencySymbolLeft = () => {
  let localCurrency = JSON.parse(localStorage.getItem("currency"));
  if (localCurrency !== null) {
    if (localCurrency.alignment === "left") {
      return localCurrency.symbol;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
const currencySymbolRight = () => {
  let localCurrency = JSON.parse(localStorage.getItem("currency"));
  if (localCurrency !== null) {
    if (localCurrency.alignment === "right") {
      return localCurrency.symbol;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

//general settings
const getSystemSettings = (settingsArray, checkType) => {
  let tempItem =
    settingsArray !== null &&
    settingsArray.find((item) => {
      return item.name === checkType;
    });
  if (tempItem !== undefined) {
    return tempItem.value;
  } else {
    return null;
  }
};


const roundOffPrice = (price , generalSettings) =>{
  if(parseInt(getSystemSettings(generalSettings, "order_round_off")) === 1){
   return Math.round(price)
  }else{
    return Number(price).toFixed(2)
  }
}

const getBeforeTaxPricePayment = (price, theTax) => {
  return (Number(price) - (Number(price) / (1 + (theTax / 100)))).toFixed(2);
};

// total cash card price acc to tax
const totalPriceWithTax = (total,subtotal,vat,discount,branch,key,generalSettings) =>{
  let totalPrice = parseFloat(total);
  const foundKey = branch && branch.payment_tax && Object.keys(branch.payment_tax).find(k => k.toLowerCase() === key);
  if(foundKey && branch.payment_tax[foundKey] && branch.payment_tax[foundKey] > 0){
    const calAmount = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after" ? (parseFloat(subtotal) - parseFloat(discount)) : parseFloat(subtotal);
    const inclusive_vat = calAmount * (parseFloat(branch.payment_tax[foundKey]) / 100);
    const previous_amount = parseFloat(total) - parseFloat(vat);
    totalPrice = previous_amount + inclusive_vat;
  }
  const isRoundOff = parseInt(getSystemSettings(generalSettings, "order_round_off")) === 1;
  const finalPrice = isRoundOff ? Math.round(totalPrice) : totalPrice.toFixed(2);
  return [finalPrice,foundKey && branch && branch.payment_tax[foundKey]]
}

// cal vat percent on print
const vatPercent = (total, vat)=>{
  if(parseFloat(vat) > 0){
    return ((parseFloat(vat)/parseFloat(total)) * 100).toFixed(0)
  }else{
    return 0;
  }
}

const taxPercent = (total,vat,discount,generalSettings) => {
  if(parseFloat(vat) > 0){
    if(getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after"){
     let totalAmount = parseFloat(total) - parseFloat(discount);
     let vatPercentage = ((parseFloat(vat) / totalAmount) * 100).toFixed(0);
     return vatPercentage;
    }else{
      return ((parseFloat(vat)/parseFloat(total)) * 100).toFixed(0) 
     }
  }else{
    return 0;
  }
}

//Delete cookie of authentication
const deleteCookie = () => {
  const url = BASE_URL + `/auth/logout`;
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then(() => {
      window.location.reload();
      if (cookies.get("_user") !== undefined) {
        cookies.remove("_user", { path: "/" });
      }
      if (cookies.get("sbb") !== undefined) {
        cookies.remove("sbb", { path: "/" });
      }
      if (cookies.get("frr") !== undefined) {
        cookies.remove("frr", { path: "/" });
      }
      if (cookies.get("xss") !== undefined) {
        cookies.remove("xss", { path: "/" });
      }
    })
    .catch(() => {
      window.location.reload();
      if (cookies.get("_user") !== undefined) {
        cookies.remove("_user", { path: "/" });
      }
      if (cookies.get("sbb") !== undefined) {
        cookies.remove("sbb", { path: "/" });
      }
      if (cookies.get("frr") !== undefined) {
        cookies.remove("frr", { path: "/" });
      }
      if (cookies.get("xss") !== undefined) {
        cookies.remove("xss", { path: "/" });
      }
    });
};

//restaurant dashboard menu links
const restaurantMenuLink = (
  img,
  imgAlt,
  icon,
  infoTextColor,
  info,
  title,
  redirectTo
) => {
  return (
    <div className="col-md-6 col-lg-4 t-mb-15">
      <NavLink to={redirectTo} className="t-link product-card t-bg-white w-100">
        <div className="product-card__head w-100 text-center">
          <img src={img} alt={imgAlt} className="img-fluid" />
        </div>
        <div className="product-card__body w-100">
          <div className="product-card__add">
            <span className="product-card__add-icon">
              <span className="las la-plus"></span>
            </span>
          </div>
          <span
            className={`product-card__sub-title ${infoTextColor} text-uppercase`}
          >
            <span className={icon}></span> {info}
          </span>
          <span className="product-card__title text-capitalize">{title}</span>
        </div>
      </NavLink>
    </div>
  );
};

//manage page mobile screen link
const managePageHrefLinkMobile = (redirectTo, menuName) => {
 // if(authUserInfo.permissions !== null && checkPermission(authUserInfo.permissions, menuName)) {
  if (window.location.pathname === redirectTo) {
    return (
      <li
        className={`fk-sm-nav__list  ${
          window.location.href.includes(redirectTo) && "active"
        }`}
      >
        <NavLink
          to={{ pathname: "/refresh", state: redirectTo }}
          exact
          className={`t-link fk-sm-nav__link`}
        >
          {menuName}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li
        className={`fk-sm-nav__list  ${
          window.location.href.includes(redirectTo) && "active"
        }`}
      >
        <NavLink to={redirectTo} exact className={`t-link fk-sm-nav__link`}>
          {menuName}
        </NavLink>
      </li>
    );
  } 
  // }else{
  //   ""

  // }

};

//manage page navlink
const managePageHrefLink = (redirectTo, menuName) => {
  //if(authUserInfo.permissions !== null && checkPermission(authUserInfo.permissions, menuName)) {
  if (window.location.pathname === redirectTo) {
    return (
      <li className="fk-pos-nav__sub-list border-bottom">
        <NavLink
          to={{ pathname: "/refresh", state: redirectTo }}
          exact
          className={`w-100 sm-text t-text-dark btn font-weight-bold text-capitalize rounded-0 text-left ${
            window.location.href.includes(redirectTo) && "active"
          }`}
        >
          - {menuName}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="fk-pos-nav__sub-list border-bottom">
        <NavLink
          to={redirectTo}
          exact
          className={`w-100 sm-text t-text-dark btn font-weight-bold text-capitalize rounded-0 text-left ${
            window.location.href.includes(redirectTo) && "active"
          }`}
        >
          - {menuName}
        </NavLink>
      </li>
    );
  }
// }else{
//   ""
// }
};

//pagination
const pagination = (data, customFunction) => {
  return (
    <ReactPaginate
      pageCount={data && data.last_page}
      initialPage={0}
      marginPagesDisplayed={5}
      pageRangeDisplayed={2}
      onPageChange={(page) => {
        customFunction(page.selected + 1);
      }}
      breakLabel={". . ."}
      breakClassName={"px-2"}
      containerClassName={"t-list d-flex"}
      pageClassName={"t-list__item mr-0"}
      previousLabel={<i className="las la-angle-double-left"></i>}
      nextLabel={<i className="las la-angle-double-right"></i>}
      pageLinkClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      previousClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      nextClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      activeClassName={"pagination-active"}
      activeLinkClassName={"text-white"}
    />
  );
};

// pagination loading
const paginationLoading = () => {
  return <Skeleton style={{ height: "40px" }} className="card bg-white" />;
};

// modal loading ff7675
const modalLoading = (count) => {
  return (
    <SkeletonTheme color="#0dd19d" highlightColor="#dfe4ea">
      <p>
        <Skeleton count={count} />
      </p>
    </SkeletonTheme>
  );
};

// manage page table loading
const tableLoading = () => {
  return (
    <SkeletonTheme color="#f1f2f6" highlightColor="#dfe4ea">
      <p>
        <Skeleton style={{ height: `calc(100vh - 222px)` }} />
      </p>
    </SkeletonTheme>
  );
};

// table loading
const pageLoading = () => {
  return (
    <SkeletonTheme color="#f1f2f6" highlightColor="#dfe4ea">
      <p>
        <Skeleton style={{ height: `calc(100vh - 187px)` }} />
      </p>
    </SkeletonTheme>
  );
};

// data count details of tables
const showingData = (data) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.from} - {data && data.to} of {data && data.total}
    </>
  );
};

// searched data count details of table
const searchedShowingData = (data, allData) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.list.length} of {allData && allData.length}
    </>
  );
};

//pagination of order history
const paginationOrderHistory = (data, customFunction) => {
  return (
    <ReactPaginate
      pageCount={data && data.meta.last_page}
      initialPage={0}
      marginPagesDisplayed={5}
      pageRangeDisplayed={2}
      onPageChange={(page) => {
        customFunction(page.selected + 1);
      }}
      breakLabel={". . ."}
      breakClassName={"px-2"}
      containerClassName={"t-list d-flex"}
      pageClassName={"t-list__item mr-0"}
      previousLabel={<i className="las la-angle-double-left"></i>}
      nextLabel={<i className="las la-angle-double-right"></i>}
      pageLinkClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      previousClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      nextClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      activeClassName={"pagination-active"}
      activeLinkClassName={"text-white"}
    />
  );
};

// data count details of tables of order history
const showingDataOrderHistory = (data) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.meta.from} - {data && data.meta.to} of{" "}
      {data && data.meta.total}
    </>
  );
};

// searched data count details of table of order history
const searchedShowingDataOrderHistory = (data, allData) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.list.length} of {allData && allData.data.length}
    </>
  );
};

// get date pattern for save api
const getDate = (date) => {
  let date_for_api =
  new Date(date).getFullYear() +
  "-" +
  (new Date(date).getMonth() + 1) +
  "-" +
  new Date(date).getDate();
  return date_for_api;
}

//export here
export {
  checkInstallOffline,
  // common & necessary
  _t,
  consolee,
  getCookie,
  deleteCookie,
  checkPermission,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
  getSystemSettings,
  // common & necessary

  //navLink
  restaurantMenuLink,
  managePageHrefLinkMobile,
  managePageHrefLink,
  //navLink

  //loading
  pageLoading,
  modalLoading,
  tableLoading,
  paginationLoading,
  //loading

  //pagination and datacounting
  pagination,
  paginationOrderHistory,
  showingData,
  searchedShowingData,
  showingDataOrderHistory,
  searchedShowingDataOrderHistory,
  //pagination and datacounting
  vatPercent,
  taxPercent,
  // print price
  showPropertyPrice,
  showPriceOfEachOrderItemPrint,
  totalPriceWithTax,
  roundOffPrice,
  getBeforeTaxPricePayment,

  //select style
  customStyle,

  //get api date
  getDate,
};
/*
----------------------------------------------
                      Ends
----------------------------------------------
*/
