import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
//axios and base url
import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../../../BaseUrl";
//functions
import {
  _t,
  getCookie,
  checkPermission,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings,
  showPriceOfEachOrderItemPrint,
  showPropertyPrice,
  totalPriceWithTax,
  roundOffPrice,
  getBeforeTaxPricePayment
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import SplitPayment from "./newpos/ItemModal/SplitPayment";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import CardModal from "./modal/CardModal";
import RiderModal from "./modal/RiderModal";
import ItemCancelModal from "./modal/ItemCancelModal";

const Submitted = () => {
  const { authUserInfo, getDeliveryUser, managerPasscode } = useContext(UserContext);
  //getting context values here
  const {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);

  const {
    //submitted orders
    submittedOrders,
    setPaginatedSubmittedOrders,
    submittedOrdersForSearch,

    //payment-type
    paymentTypeForSearch,
    orderTypeList,
    //pagination
    dataPaginating,
    workPeriodListForBranch
  } = useContext(RestaurantContext);

  const { t } = useTranslation();
  let history = useHistory();

  //print bills
  const componentRef = useRef();

  // States hook here

  const deliveryStatusOption = [
      {
          id: 1,
          name: "Assign a Rider",
      },
      {
          id: 2,
          name: "Pick Up",
      },
      {
          id: 3,
          name: "On the Way",
      },
  ];

  const [deliveryStatus, setDeliveryStatus] = useState(deliveryStatusOption[0]);

  // show settle
  const [showSettle, setShowSettle] = useState(false);

  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);

  // SETpaidMoney
  const [totalAmount, setTotalAmount] = useState(0);

  // SETpaidMoney
  const [taxAmount, setTaxAmount] = useState(0);

  // SETpaidMoney
  const [taxPercent, setTaxPercent] = useState(0);

  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //check tax
  const [checkStatus, setCheckStatus] = useState(false);

  // rider modal
  const [riderModal, setRiderModal] = useState(false);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
    card_number: ""
  });
 
  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });

  //split payment
  const [payModal, setPayModal] = useState(false);
  const [payment, setPayment] = useState("");
  const [paySelected,setPaySelected] = useState(null);

  //FBR Invoice Num
  const [fbrInvoiceNum, setFbrInvoiceNum] = useState(null);
  //FBR Invoice Num
  const [srbInvoiceNum, setSrbInvoiceNum] = useState(null);
  const isSettle = useRef(0);
  const [taxType, setTaxType] = useState("");
  const fbrNum = useRef(null);
  const srbNum = useRef(null);

  //paymentTypeAvailable
  const [paymentTypeAvailable, setPaymentTypeAvailable] = useState(paymentTypeForSearch);
  const [activebtn , setActivebtn] = useState("");

  // passcode stuff
  const [passcodeApplied, setPasscodeApplied] = useState(false);
  const [deleteReasonModalShowing, setDeleteReasonModalShowing] =
    useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [availableDeliveryTypes, setAvailableDeliveryTypes] = useState([]);
  const cardAdd = useRef();
  const [cardNumber,setCardNumber] = useState({
    first: "******",
    middle:"****",
    last:""
  });
  const inputRefs = {
    first: useRef(),
    middle: useRef(),
    last: useRef(),
    paid: useRef(),
  };
  //print payment
  const printPayment = (payment) =>{
    const paymentType = payment && payment.map((item,index) => item.name + (index !== payment.length ? "," : "")).join("");
    return paymentType
  }

  const handleCardSubmit = (e) =>{
    e.preventDefault();

    // if(cardNumber.first?.length < 6){
    //  toast.error("Please enter valid card number");
    //  return false
    // }else 
    if(cardNumber.last?.length < 6){
      toast.error("Please enter valid card number");
      return false
    }

    const cardNumberFinal = cardNumber.first + cardNumber.middle + cardNumber.last;
    setCheckOrderDetails({
      ...checkOrderDetails,
      card_number: cardNumberFinal,
    })
    
    cardAdd.current.classList.remove("showing");
  }

  useEffect(()=>{
    if(checkOrderDetails?.settle){
      inputRefs.paid.current.focus();
    }
  },[checkOrderDetails.settle]);

  //clear paid and 
  const handleClearAmount = () =>{
    setPaidMoney(0);
    setReturnMoneyUsd(0);
    setPaySelected(null); 
    setPayment("");
  }
  
  const handleCardChange = (part, value) => {
    setCardNumber((prev) => ({
      ...prev,
      [part]: value,
    }));
   
    // if(part === "first" && value?.length === 6){
    //   inputRefs.last.current.focus();
    //  }
  };

  // split pay on click
  const handleClick = () =>{
    setPaidMoney(0);
    setReturnMoneyUsd(0);
    setPayment("");
    setPaySelected(null);
    setCheckOrderDetails({
      ...checkOrderDetails,
      payment_type: null,
      vat: checkOrderDetails.item?.vat
    });
    setPayModal(false)
  }
  
  //payment type select
  const handleSetPayment = (pay) =>{
    setCardNumber({
      first: "******",
      middle:"****",
      last:""
    });
    if(pay?.view_card === 1){
      cardAdd.current.classList += " showing";
      inputRefs.last.current.focus();
    }
    setPaySelected(pay)
  }

   //debounce
   const handleDebounce = (value) =>{
    const total = parseFloat(totalAmount) - parseFloat(checkOrderDetails.item.paid_amount);
    const remaining = total - parseFloat(value);
    if(remaining > 0){
      setPayment((prevPayment) => ({ ...prevPayment, select_split: roundOffPrice(remaining, generalSettings) }));
    }else{
      setPayment((curr)=>{
        const newData = {
          ...curr
        }
        newData["select_split"] && delete newData["select_split"]
        return newData
      });
    }
  }
  //split payment on chnage
  const handleSplitPayment = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if(payment && (value == 0 || value === "")){

      setPayment((curr)=>{
        const newData = {
          ...curr
        }
        newData[id] && delete newData[id];
        if(!isNaN(Number(id))){
          newData["select_split"] && delete newData["select_split"]         
        }
        return newData
      });

    }else{
      setPayment((prevPayment) => ({ ...prevPayment, [id]: value }));
      if(!isNaN(Number(id))){
        handleDebounce(value);
      }
    }
  };

  //payment split type pos
  const handleSetpaymentTypeMuliple = (e) => {
    e.preventDefault();
    if(payment){
      let total = 0;
      
      const paymentMethod = payment && Object?.keys(payment).map((id)=>{
        const amount = parseFloat(payment[id]);
        const type = isNaN(Number(id)) ? paySelected : paymentTypeAvailable.find(payType => payType.id === Number(id));
        const taxAmount = (getBeforeTaxPricePayment(amount, checkOrderDetails.item.theBranch.branch_tax));
        total += amount;
        return {
          ...type,
          tax_percent: checkOrderDetails.item.theBranch.branch_tax,
          tax_amount: Math.round(taxAmount,2),
          bill_amount : payment[id]
        };
      });
      let localCurrency = JSON.parse(localStorage.getItem("currency"));
      let theUsdPaid = total / localCurrency.rate;

      if(Math.round(theUsdPaid, 2) > Math.round(totalAmount - parseFloat(checkOrderDetails.item.paid_amount), 2)){
        setPayModal(true)
        setPaidMoney(0);
        setReturnMoneyUsd(0);
        setCheckOrderDetails({
          ...checkOrderDetails,
          item: {
            ...checkOrderDetails.item,
            total_payable: parseFloat(roundOffPrice(totalAmount, generalSettings)) -
            parseFloat(roundOffPrice(checkOrderDetails.item.paid_amount, generalSettings)),
          },
        });

        toast.error(
          "Please enter paid amount exact equal to the total bill amount",
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
      if (Math.round(theUsdPaid, 2) < Math.round(totalAmount - parseFloat(checkOrderDetails.item.paid_amount), 2)) {
        setPayModal(true)
        setReturnMoneyUsd(0);
        setPaidMoney(0);
        setCheckOrderDetails({
          ...checkOrderDetails,
          item: {
            ...checkOrderDetails.item,
            total_payable: parseFloat(roundOffPrice(totalAmount, generalSettings)) -
            parseFloat(roundOffPrice(checkOrderDetails.item.paid_amount, generalSettings)),
          },
        });
        toast.error(
          "Please enter paid amount atleast equal to the total bill amount",
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        setTaxPercent(checkOrderDetails.item.theBranch?.branch_tax);
        setPaidMoney(parseFloat(roundOffPrice(total,generalSettings)));
        setPayModal(false);
        setCheckOrderDetails({
          ...checkOrderDetails,
          item: {
            ...checkOrderDetails.item,
            total_payable: parseFloat(roundOffPrice(totalAmount, generalSettings)) -
            parseFloat(roundOffPrice(checkOrderDetails.item.paid_amount, generalSettings)),
            vat: parseFloat(roundOffPrice(taxAmount, generalSettings)),
          },
          payment_type: paymentMethod,
        });
  
        let theReturnMoney = theUsdPaid - (Math.round(totalAmount, 2) - parseFloat(checkOrderDetails.item.paid_amount));
        setReturnMoneyUsd(roundOffPrice(theReturnMoney, generalSettings));
      }

    }else{
      toast.error(
        "Please select the payment type to split payment",
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  useEffect(()=>{
    const filteredDeliveryTypes = orderTypeList && orderTypeList?.data.filter(
      (order) => order.status === 'On'
    );
    if (filteredDeliveryTypes.length > 0) {
      setAvailableDeliveryTypes(filteredDeliveryTypes);
    }
  },[orderTypeList]);
  
  useEffect(() => {
    if (!authUserInfo) return;
    setPasscodeApplied(
       authUserInfo.details.user_type === "staff" &&
        (authUserInfo.details.user_passcode || managerPasscode)
        ? true
        : false
    );
    getDeliveryUser();
  }, [authUserInfo]);


  //useEffect == componentDidMount
  useEffect(() => {
    if (checkOrderDetails?.item?.associate_with_payment_type !== null) {
      var tempAvailablePaymentType = paymentTypeForSearch?.filter((item) => {
        return checkOrderDetails?.item?.associate_with_payment_type?.includes(
          item.id
        );
      });
    }
    setPaymentTypeAvailable(tempAvailablePaymentType);
  }, [checkOrderDetails.item]);

  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      handleSubmitSuccess();
    },
  });

  useEffect(() => {
    if (!generalSettings || !generalSettings?.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);

  //cal net bill cash card
  const renderNetBill = (total, cartSubTotal, theVat, key, label) => {
    const [netBill, percent] = totalPriceWithTax(
      total,
      cartSubTotal,
      theVat,
      checkOrderDetails.item?.discount_amount,
      checkOrderDetails.item,
      key,
      generalSettings
    );
  
    return (
      <div className="d-flex align-items-center justify-content-between my-1 mx-1">
        <div className="fk-print-text genAcc-select text-capitalize">
          {_t(t(label))}{percent > 0 && ` (${percent}%)`}
        </div>
        <div className="fk-print-text genAcc-select text-capitalize">
          {netBill}
        </div>
      </div>
    );
  };

  //payment type
  const handleSetpaymentType = (payment_type) => {
    setCheckOrderDetails({
      ...checkOrderDetails,
      payment_type,
      card_number: ""
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(checkOrderDetails.payment_amount, payment_type);
  };

  //payment type for screen 2
  const handleSetpaymentTypeSingle = (payment_type) => {
    let b_tax = checkOrderDetails.item?.payment_tax[payment_type.name];
    if(payment_type?.view_card === 1){
      cardAdd.current.classList += " showing";
      inputRefs.last.current.focus();
    }
    setCardNumber({
      first: "******",
      middle:"****",
      last:""
    });
    setPaySelected(null); 
    setPayment("");

    let inclusive_vat = 0;
    let previous_amount = 0;
    let new_amount = 0;
    let check = 0;
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    
    if (b_tax && b_tax >= 0) {
      let vatCalculate = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after" ? (parseFloat(checkOrderDetails.item.order_bill) - parseFloat(checkOrderDetails.item.discount_amount)) : parseFloat(checkOrderDetails.item.order_bill);
      inclusive_vat = vatCalculate * (parseFloat(b_tax) / 100);
      previous_amount = parseFloat(totalAmount) - parseFloat(taxAmount);
      new_amount = previous_amount + inclusive_vat;
     
      setTaxPercent(b_tax);
      check = 1;
      setCheckStatus(true);
      setCheckOrderDetails({
        ...checkOrderDetails,
        item: {
          ...checkOrderDetails.item,
          total_payable: parseFloat(roundOffPrice(new_amount, generalSettings)),
          vat: parseFloat(roundOffPrice(inclusive_vat, generalSettings)),
        },
        payment_type: [{...payment_type, bill_amount: parseFloat(roundOffPrice(new_amount, generalSettings)), tax_percent: b_tax , tax_amount: parseFloat(roundOffPrice(inclusive_vat, generalSettings))}],
        card_number: "",
      });
      setPaidMoney(parseFloat(roundOffPrice(new_amount, generalSettings)))
      //setPaidMoney(parseFloat(new_amount.toFixed(2)));
      setReturnMoneyUsd(0);
      // let theReturnMoney = new_amount;

      // setReturnMoneyUsd(theReturnMoney);

      // let theReturnMoney = 0;
      // setReturnMoneyUsd(theReturnMoney);
      console.log("payment", checkOrderDetails);
    } else {
      let theUsdPaid = paidMoney / localCurrency.rate;
      
      if (checkOrderDetails && checkOrderDetails.item !== null) {
        if (
          parseFloat(theUsdPaid.toFixed(2)) <
          parseFloat(totalAmount.toFixed(2)) -
          parseFloat(checkOrderDetails.item.paid_amount)
        ) {
          setReturnMoneyUsd(0);
          toast.error(
            `${_t(
              t(
                "Please enter paid amount atleast equal to the total bill amount"
              )
            )}`,
            {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
          
        } else {

          let theReturnMoney =
          parseFloat(theUsdPaid.toFixed(2)) -
          parseFloat(totalAmount.toFixed(2)) -
          parseFloat(checkOrderDetails.item.paid_amount);
          setReturnMoneyUsd(roundOffPrice(theReturnMoney, generalSettings));
        }
       
        setCheckOrderDetails({
          ...checkOrderDetails,
          item: {
            vat: parseFloat(taxAmount.toFixed(2)),
            ...checkOrderDetails.item,
            total_payable: parseFloat(totalAmount.toFixed(2)),
          },
          payment_type: [{...payment_type, bill_amount: parseFloat(totalAmount.toFixed(2)), tax_percent: checkOrderDetails.item.theBranch.branch_tax , tax_amount: parseFloat(totalAmount.toFixed(2))}],
          card_number: "",
        });
      }
    }
  };

  //payment type amount
  const handlePaymentTypeAmount = (e) => {
    let tempPaymentAmount = {
      ...checkOrderDetails.payment_amount,
      [e.target.name]: e.target.value,
    };

    setCheckOrderDetails({
      ...checkOrderDetails,
      payment_amount: tempPaymentAmount,
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(tempPaymentAmount, checkOrderDetails.payment_type);
  };

  //calculate paid amount
  const handleCalculatePaid = (paymentAmount, paymentType) => {
    let paidAmount = 0;
    if (paymentAmount !== null && paymentType !== null) {
      let thePaymentArray = [];
      if (paymentAmount) {
        thePaymentArray = Object.entries(paymentAmount);
      }
      thePaymentArray.map((eachPaymentItem) => {
        let thePaymentType = paymentType.find((paymentTypeItem) => {
          return paymentTypeItem.id === parseInt(eachPaymentItem[0]);
        });
        if (eachPaymentItem[1] !== "") {
          if (
            thePaymentType &&
            thePaymentType.id === parseInt(eachPaymentItem[0])
          ) {
            paidAmount = paidAmount + parseFloat(eachPaymentItem[1]);
          }
        }
      });
      let localCurrency = JSON.parse(localStorage.getItem("currency"));
      paidAmount = paidAmount / localCurrency.rate;
      let theReturnMoney = 0;
      if (checkOrderDetails.item) {
        theReturnMoney = paidAmount - parseFloat(checkOrderDetails.item.total_payable);
      }
      setReturnMoneyUsd(roundOffPrice(theReturnMoney, generalSettings));
    } else {
      setReturnMoneyUsd(0);
    }
    setPaidMoney(parseFloat(paidAmount.toFixed(2)));
  };

  const handleSubmitSuccess = () =>{
    setCheckOrderDetails({
      ...checkOrderDetails,
      item: null,
      payment_type: null,
      payment_amount: null,
      settle: false,
      card_number: ""
    });
    fbrNum.current = null;
    srbNum.current = null;
    setSrbInvoiceNum(null);
    setFbrInvoiceNum(null);
    setCardNumber({
      first:"******",
      middle:"****",
      last:""
    })
    isSettle.current = 0;
    setPaidMoney(0);
    setLoading(false);
    setShowSettle(false);
  }
  
  const formDataReturn = () => {
    const returnAmount = (checkOrderDetails.settle ? paidMoney : checkOrderDetails.item.paid_amount) -
    parseFloat(checkOrderDetails.item.total_payable) >
    0 ? formatPrice(
        (checkOrderDetails.settle
          ? paidMoney
          : checkOrderDetails.item.paid_amount) -
          parseFloat(
            checkOrderDetails.item.total_payable
          )
      )
    : formatPrice(0);
    const workPeriod = workPeriodListForBranch &&
    workPeriodListForBranch.find((tempWorkPeriod) => {
      return (checkOrderDetails.item.branch_id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null;
    });
    //cash card tax wise total 
    const [cardAmount, cardPercent] = totalPriceWithTax(checkOrderDetails.item.total_payable,checkOrderDetails.item.order_bill, checkOrderDetails.item.vat,checkOrderDetails.item.discount_amount, checkOrderDetails.item,"card",generalSettings);
    const [cashAmount, cashPercent] = totalPriceWithTax(checkOrderDetails.item.total_payable,checkOrderDetails.item.order_bill, checkOrderDetails.item.vat,checkOrderDetails.item.discount_amount, checkOrderDetails.item,"cash",generalSettings);

    let formatTime = moment(checkOrderDetails.item.created_at).format('DD-MMM-YYYY hh:mm A');
    const ref_no = checkOrderDetails.item.party_name +  " - ref no :"  + checkOrderDetails.item?.ref_no ? checkOrderDetails.item.ref_no : "";

    return {
      site_name: getSystemSettings(generalSettings, "siteName"),
      branch: checkOrderDetails.item.theBranch,
      type_print_heading: getSystemSettings(
        generalSettings,
        "type_print_heading"
      ),
      payment_type: checkOrderDetails.payment_type[0].name,
      serviceCharge: checkOrderDetails.item.service_charge,
      service_amount: checkOrderDetails.item.service_amount,
      discount: checkOrderDetails.item.discount,
      party_name:
      checkOrderDetails.item.order_type_name +
      "-" +
      checkOrderDetails.item.party_name,
      subTotal: checkOrderDetails.item.order_bill,
      totalPayable: checkOrderDetails.item.total_payable,
      view_cash_card: parseInt(getSystemSettings(generalSettings,"view_cash_card")),
      cash: cashAmount,
      card: cardAmount,
      cash_percent: cashPercent,
      card_percent: cardPercent,
      theVat: checkOrderDetails.item.vat,
      tax_percent: taxPercent > 0 ? taxPercent : checkOrderDetails.item.theBranch?.branch_tax,
      print_tax : checkOrderDetails.item.theBranch?.print_tax,
      RefNo: ref_no,
      print_footer: getSystemSettings(generalSettings, "type_print_footer"),
      user_name: authUserInfo && authUserInfo.details && authUserInfo.details.name,
      tax_type: taxType,
      created_by : checkOrderDetails.item?.user_name,
      rider_name: checkOrderDetails.item?.delivery_boy_name ? checkOrderDetails.item.delivery_boy_name : "",
      rider_notes: checkOrderDetails.item?.note_to_rider ? checkOrderDetails.item.note_to_rider : "",
      //customer print
      customerIp: checkOrderDetails.item.theBranch.kitchen_printer_ip != null && JSON.parse(checkOrderDetails.item.theBranch.kitchen_printer_ip)?.[authUserInfo.details.id] ? JSON.parse(checkOrderDetails.item.theBranch.kitchen_printer_ip)[authUserInfo.details.id] : null,
      deliveryIp: checkOrderDetails.item.theBranch.customer_counter_ip != null && checkOrderDetails.item.theBranch.customer_counter_ip !== "" ? checkOrderDetails.item.theBranch.customer_counter_ip : null,
      logo: getSystemSettings(generalSettings, "type_logo"),
      customerOrder: checkOrderDetails.item.orderedItems,
      dept_tag: {
        id: checkOrderDetails.item?.dept_tag_id,
        name: checkOrderDetails.item.dept_tag_name,
      },
      orderTypeDetails: {
        orderTypeId: checkOrderDetails.item?.order_type_id,
        orderTypeName: checkOrderDetails.item.order_type_name,
      },
      partyDetails: {
        partyId: checkOrderDetails.item?.party_id,
        partyName: checkOrderDetails.item.party_name,
      },
      is_online: 1,
      guest: checkOrderDetails.item.total_guest,
      is_reservation: false,
      isSettle: 1,
      table: null,
      waiter: checkOrderDetails.item.waiter_name,
      payment_amount: checkOrderDetails.item.payment_amount,
      card_number: checkOrderDetails.item?.card_number,
      newCustomer: 0,
      newCustomerInfo: {
        name: checkOrderDetails.item.customer_name,
        email:"",
        address: checkOrderDetails.item.delivery_address,
        ref_no: checkOrderDetails.item.ref_no,
        number: checkOrderDetails.item.delivery_phn_no,
      },
      token: {
        id: parseInt(getSystemSettings(generalSettings, "order_no")) === 1 ? checkOrderDetails.item.order_no : checkOrderDetails.item.token.id,
        time: formatTime
      },
      total_guest: checkOrderDetails.item.total_guest,
      table_id: checkOrderDetails.item.table_id,
      table_name: checkOrderDetails.item.table_name,
      discount_voucher: "",
      scheme: {
        id: checkOrderDetails.item.scheme_id,
        discount: checkOrderDetails.item.discount,
        how_much_discount: checkOrderDetails.item?.discount_amount,
      },
      paidMoney: paidMoney,
      dept_commission: "",
      workPeriod: workPeriod ? workPeriod : {
        id: checkOrderDetails.item.work_period_id,
        token: checkOrderDetails.item.token
      },
      order_id: null,
      orderFoodGroups: null,
      selectedPropertyGroup: [],
      return_amount: returnAmount,
      fbr_num: fbrNum.current,
      srb_num: srbNum.current,
    
    };
  };

  // local server print
  const handleLocalPrint = () => {
    let formData = formDataReturn();
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("http://localhost:8081/example-app/api/test", formData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        handleSubmitSuccess();
      })
      .catch((err) => {
        handleOnlyPrint();
      });
  };
  
  // handleSettleOrder
  const handleSettleOrder = (e) => {
    e.preventDefault();
    if (checkOrderDetails && checkOrderDetails.payment_type !== null) {
      if(checkOrderDetails?.payment_type && checkOrderDetails.payment_type[0]?.view_card === 1 && (checkOrderDetails.card_number === null || checkOrderDetails.card_number ==="")){
        toast.error(`${_t(t("Please Enter Card Number"))}`,  {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        cardAdd.current.classList += " showing";
        return false;
       }
      setLoading(true);
      if (
        paidMoney <
        parseFloat(
          checkOrderDetails.item.total_payable -
            checkOrderDetails.item.paid_amount
        )
      ) {
        setLoading(false);
        toast.error(
          `${_t(
            t("Please enter paid amount atleast equal to the total due amount")
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        handleSettleOrderAxiosReq();
      }
    } else {
      toast.error(`${_t(t("Please select a payment method"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //settle order server request
  const handleSettleOrderAxiosReq = () => {
    let url = BASE_URL + "/settings/settle-submitted-order";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let formData = {
      order_group_id: checkOrderDetails.item.id,
      payment_type: checkOrderDetails.payment_type,
      payment_amount: checkOrderDetails.payment_amount,
      vat: checkOrderDetails.item.vat,
      total_payable: checkOrderDetails.item.total_payable,
      payment_card_number: checkOrderDetails.card_number,
      status: checkStatus,
      tax_percent:
        taxPercent > 0
          ? taxPercent
          : checkOrderDetails.item.theBranch?.branch_tax,
      paidMoney:
        getSystemSettings(generalSettings, "pos_screen") === "0"
          ? paidMoney
          : paidMoney / localCurrency.rate,
      localCurrency: localCurrency,
    };
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "paymentIssue") {
          setSrbInvoiceNum(res.data[2].srb_pos_invoice_no);
          setFbrInvoiceNum(res.data[2].fbr_pos_invoice_no);
          isSettle.current = res.data[2].is_settled;
          fbrNum.current = res.data[2].fbr_pos_invoice_no
          srbNum.current = res.data[2].srb_pos_invoice_no;
          if(parseInt(getSystemSettings(generalSettings, "direct_ip_print")) === 1){
            handleLocalPrint();
          }else{
            handleOnlyPrint();
          }
          setSearchedOrder({
            ...searchedOrder,
            searched: false,
          });
          
        } else {
          setLoading(false);
          toast.error(
            `${_t(
              t(
                "Please enter paid amount atleast equal to the total due amount"
              )
            )}`,
            {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please contact support"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //search submitted orders here

  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if(submittedOrders){
      if (searchInput.length === 0 || searchInput === "all") {
        setSearchedOrder({ ...searchedOrder, searched: false });
      } else {
        let searchedList = submittedOrdersForSearch && submittedOrdersForSearch?.filter((item) => {
          //token
          let lowerCaseItemToken =
            item.token?.id && JSON.stringify(item.token?.id)?.toLowerCase();
          let orderNo = item?.order_no && item.order_no.toString();
          //customer
          let lowerCaseItemCustomer =
            item.customer_name && item.customer_name.toLowerCase();
          let customerPhone = item.delivery_phn_no && item.delivery_phn_no;
          //table
          let lowerCaseItemTable =
            item.table_name && item.table_name.toLowerCase();

          //branch
          let lowerCaseItemBranch =
            item.branch_name && item.branch_name.toLowerCase();
          //order type
          let lowerCaseItemOrderType =
            item.order_type_name && item.order_type_name.toLowerCase();

          return (
            (lowerCaseItemToken && lowerCaseItemToken.includes(searchInput)) ||
            (lowerCaseItemCustomer &&
              lowerCaseItemCustomer.includes(searchInput)) ||
            (lowerCaseItemTable && lowerCaseItemTable.includes(searchInput)) ||
            (lowerCaseItemOrderType &&
              lowerCaseItemOrderType.includes(searchInput)) ||
            (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput)) || 
            (orderNo && orderNo.includes(searchInput)) ||
            (customerPhone && customerPhone.includes(searchInput))
          );
        });
        setSearchedOrder({
          ...searchedOrder,
          list: searchedList,
          searched: true,
        });
      }
    }
  };

    //free of cost order confirmation modal
    const handleFreeCostOrderConfirmation = (orderGroup) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card card-body">
              <h1>{_t(t("Are you sure?"))}</h1>
              <p className="text-center">
                {_t(t("You want free of cost this order?"))}
              </p>
              <form 
              onSubmit={(e) => {
                e.preventDefault();
                handleFreeCostItem(e,orderGroup,onClose);
                // onClose();
              }}
              autoComplete="off"
              >
              {passcodeApplied ? (
                <div className="form-group mb-4">
                  <label
                    htmlFor="passcode"
                    className="font-weight-bold mr-4 lg-text"
                  >
                    Enter passcode
                  </label>
                  <input
                    type="text"
                    className="form-control secure-password-field w-50 d-inline-block"
                    name="passcode"
                    id="passcode"
                    placeholder="Password"
                    required
                  />
                </div>
              ) : null}
              <div className="form-group mb-4">
               <label
                htmlFor="passcode"
                className="mb-2 lg-text"
               >
                Enter Reason
               </label>
               <input
                type="text"
                className="form-control"
                name="reason"
                id="reason"
                placeholder="reason"
                required
               />
               </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  type="submit"
                >
                  {_t(t("Yes, free of cost!"))}
                </button>
                <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                  {_t(t("No"))}
                </button>
              </div>
              </form>
              </div>
          );
        },
      });
    };

    
    const handleFreeCostItem = (e,orderGroup,onClose) =>{
      let url = BASE_URL + "/settings/foc-submitted-order";
      let formData = {
        id: orderGroup.id,
        refund_reason: e.target["reason"].value,
        item: orderGroup.orderedItems
      };
      if (passcodeApplied) {
        const enteredPasscode = e.target["passcode"].value;
        if (authUserInfo.details.user_passcode ? (enteredPasscode !== authUserInfo.details.user_passcode) : !managerPasscode.hasOwnProperty(enteredPasscode)) {
          toast.error("Incorrect password! Try again.", {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          return;
        }
      }
        if (authUserInfo.details.user_passcode || managerPasscode) {
          if( e.target["passcode"]){
            e.target["passcode"].value = "";
          }
        }
        if(e.target["reason"].value === "" || e.target["reason"].value === null){
          toast.error(`${_t(t("Please add reason"))}`, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }else{
          setLoading(true);
          axios
            .post(url, formData, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setLoading(false);
              onClose()
            })
            .catch(() => {
              onClose()
              setLoading(false);
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                closeButton: false,
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            });
      }
    }

  //cancel item pop up empty  
  const handleCancelEmpty = () =>{
    setItemToDelete(null);
    setDeleteReasonModalShowing(false);
  }

  //cancel order confirmation modal
  const handleCancelOrderConfirmation = (orderGroup) => {
    setItemToDelete(null);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want to cancel this order?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setItemToDelete(orderGroup);
                  setDeleteReasonModalShowing(true);
                  // handleCancelOrder(orderGroup);
                  onClose();
                }}
              >
                {_t(t("Yes, cancel it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  // assign rider api 
  const handleRider = (e) =>{
    e.preventDefault();
    let url = BASE_URL + "/settings/assign-rider";
    let formData = {
      order_id: checkOrderDetails.item?.id,
      delivery_man_id : checkOrderDetails?.delivery_man_id,
      time_to_deliver: checkOrderDetails?.time_to_deliver,
      delivery_status: deliveryStatus.id,
      delivery_status_name: deliveryStatus.name,
    };
    if(checkOrderDetails?.delivery_man_id){
      setLoading(true);
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setRiderModal(false);
          setSearchedOrder({
            ...searchedOrder,
            searched: false,
          });
          setCheckOrderDetails({
            item: null,
            payment_amount: null,
            payment_type : null,
            settle: false,
            uploading: false,
            card_number: ""
          })
          if(res.data !== "added"){
            window.open(res.data, '_blank');
          }
          setLoading(false);
          toast.success(`${_t(t("Rider has been assigned sucessfully"))}`, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
         
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    }
  }

  const handleItemChange = (e,items) =>{
   
    if(e.target.value === "unmade"){
      items && items.orderedItems.forEach((item) => {
        const element = document.getElementById(`item-${item.id}-made-0`);
        if (element) {
          element.click();
        }
      });
    }else{
      items && items.orderedItems.forEach((item) => {
        const element = document.getElementById(`item-${item.id}-made-1`);
        if (element) {
          element.click();
        }
      });
    }
  
  }

  const handleCancelOrderReasonSubmit = (e, item) => {
    if (passcodeApplied) {
      const enteredPasscode = e.target["passcode"].value;
      if (authUserInfo.details.user_passcode ? (enteredPasscode !== authUserInfo.details.user_passcode) : !managerPasscode.hasOwnProperty(enteredPasscode)) {
        toast.error("Incorrect password! Try again.", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        return;
      }
    }
    const reason = e.target["deleteReason"].value;
    const items = item.orderedItems.map((item) => ({
      ...item,
      reason: reason,
      status: e.target[`item-${item.id}-made`].value,
    }));
    setDeleteReasonModalShowing(false);
    handleCancelOrder(item, items, e);
    e.target["deleteReason"].value = "";
    if (authUserInfo.details.user_passcode) {
      if (e.target["passcode"]) {
        e.target["passcode"].value = "";
      }
    }
  };

  //cancel order here
  const handleCancelOrder = (orderGroup, items) => {
    // if (parseInt(orderGroup.is_accepted) === 0) {
    let url = BASE_URL + "/settings/cancel-submitted-order";
    let formData = {
      id: orderGroup.id,
      items: items,
    };
    setLoading(true);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        // if (res.data === "accepted") {
        //   toast.error(
        //     `${_t(t("Can not cancel this order, this is being cooked"))}`,
        //     {
        //       position: "bottom-center",
        //       closeButton: false,
        //       autoClose: 10000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       className: "text-center toast-notification",
        //     }
        //   );
        // }
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
    // } else {
    //   toast.error(
    //     `${_t(t("Can not cancel this order, this is being cooked"))}`,
    //     {
    //       position: "bottom-center",
    //       closeButton: false,
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     }
    //   );
    // }
  };

  //settle row and print btn
  const itemListRowStatus = (item) =>{
    return(
      <>
    {parseInt(
      item.is_cancelled
    ) === 0 ? (
      [
        parseInt(
          item.is_ready
        ) === 0 ? (
          <span
            className="btn btn-transparent btn-secondary xsm-text text-capitalize px-2"
            onClick={(e) => {
              e.stopPropagation();
              setCheckOrderDetails(
                {
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                }
              );
              setReturnMoneyUsd(0);
              setPaidMoney(0);
            }}
            data-toggle="modal"
            data-target="#orderDetails"
          >
          {
            parseInt(
              item.is_hold
            ) === 1 ?
            <>
            {_t(
              t("hold")
            )}
            </>
            :
             <>
             {_t(
               t("processing")
             )}
             </>
            }
          </span>
        ) : (
          <span
            className="btn btn-transparent btn-success xsm-text text-capitalize px-3"
            onClick={(e) => {
              e.stopPropagation();
              setCheckOrderDetails(
                {
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                }
              );
              setReturnMoneyUsd(0);
              setPaidMoney(0);
            }}
            data-toggle="modal"
            data-target="#orderDetails"
          >
            {_t(t("Ready"))}
          </span>
        ),
      ]
    ) : (
      <>
        <span
        className="btn btn-transparent btn-light-danger xsm-text text-capitalize px-2"
        onClick={(e) => {
          e.stopPropagation();
          setCheckOrderDetails({
            ...checkOrderDetails,
            item: item,
            settle: false,
          });
          setReturnMoneyUsd(0);
          setPaidMoney(0);
        }}
        data-toggle="modal"
        data-target="#orderDetails"
        >
          {_t(t("Cancelled"))}
        </span>
        {authUserInfo.permissions !== null &&
          checkPermission(authUserInfo.permissions, "Submit Order Print") ? 
          <button
        className="btn btn-secondary btn-sm ml-2 rounded-sm"
        onClick={(e) => {
          e.stopPropagation();
          console.log(
            "checkOrderDetails2",
            checkOrderDetails
          );
          setCheckOrderDetails({
            ...checkOrderDetails,
            item: item,
            settle: false,
          });
          setTimeout(() => {
            handleOnlyPrint();
          }, 1000);
        }}
        >
          <i className="fa fa-print"></i>
        </button>:null}
      </>
     
    )}
    
    {parseInt(
      item.is_cancelled
    ) === 0 && parseInt(item.is_hold) === 0 ? (
      <>
        {getSystemSettings(
          generalSettings,
          "pos_screen"
        ) === "0" && authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "List Settle" ) ? (
          <button
            title="Settle Order"
            // send state- order group id
            className=" btn btn-primary btn-sm ml-2"
            style={{
              borderRadius:
                "3px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (item.theBranch.sbr_integration === 1 || item.theBranch.fbr_integration === 1 || item.theBranch?.pbr_integration === 1)){
                setTotalAmount(parseFloat(item.total_payable)+1);
                setCheckOrderDetails({
                    ...checkOrderDetails,
                    item: {
                      ...item,
                      total_payable: parseFloat(item.total_payable) + 1
                    },
                    settle: true,
                    payment_amount:
                      null,
                    payment_type:
                      null,
                  });
              }else{
                setCheckOrderDetails({
                    ...checkOrderDetails,
                    item: item,
                    settle: true,
                    payment_amount:
                      null,
                    payment_type:
                      null,
                  });
                setTotalAmount(parseFloat(item.total_payable));
              }
             
              setReturnMoneyUsd(0);
              
              setTaxAmount(parseFloat(item.vat));
              setPaidMoney(0);
            }}
            data-toggle="modal"
            data-target="#orderDetails"
          >
          Settle
          </button>
        ) : (
              authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "List Settle"
                  ) ? (
                <button
                title="Settle Order"
                // send state- order group id
                className=" btn btn-success btn-sm ml-2"
                style={{
                  borderRadius:
                    "3px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (item.theBranch.sbr_integration === 1 || item.theBranch.fbr_integration === 1 || item.theBranch?.pbr_integration === 1)){
                    setTotalAmount(parseFloat(item.total_payable) + 1);
                    setCheckOrderDetails({
                        ...checkOrderDetails,
                        item: {
                          ...item,
                          total_payable: parseFloat(item.total_payable) + 1
                        },
                        settle: true,
                        payment_amount:
                          null,
                        payment_type:
                          null,
                      });
                    setPaidMoney(parseFloat(item.total_payable)+1);
                  }else{
                    setCheckOrderDetails({
                        ...checkOrderDetails,
                        item: item,
                        settle: true,
                        payment_amount:
                          null,
                        payment_type:
                          null,
                      });
                    setTotalAmount(parseFloat(item.total_payable));
                    setPaidMoney(parseFloat(item.total_payable));
                  }
    
                  setReturnMoneyUsd(0);
                  
                  setShowSettle(true);
                  
                  setTaxAmount(parseFloat(item.vat));
                }}
                >
                  Settle
                </button>
            ): (
              <></>
            )
         )}
        {authUserInfo.permissions !== null &&
          checkPermission(authUserInfo.permissions, "Submit Order Print") ? 
          <button
        className="btn btn-secondary btn-sm ml-2 rounded-sm"
        onClick={(e) => {
        e.stopPropagation();
        console.log(
          "checkOrderDetails2",
          checkOrderDetails
        );
        setCheckOrderDetails({
          ...checkOrderDetails,
          item: item,
          settle: false,
        });
        setTimeout(() => {
          handleOnlyPrint();
        }, 1000);
      }}
    >
      <i className="fa fa-print"></i>
    </button>:null}
      </>
    ) : (
      _t(t(""))
    )}
    </>
   )
  }

  const orderEdit = (item) =>{
    if (
      checkOrderDetails.item
        ?.id === item?.id
    ) {
      setCheckOrderDetails({
        ...checkOrderDetails,
        item: null,
        settle: false,
      });
    } else {
      setCheckOrderDetails({
        ...checkOrderDetails,
        item: item,
        settle: false,
      });
    }
  }

  //double click order edit
  const doubleClickOrderEdit = (item) =>{
    if(checkPermission(authUserInfo.permissions,"Order edit")){
      if (item && parseInt(item.is_cancelled) === 0) {
        history.push(
            `/dashboard/epos/${item?.id}`
        );
      } else {
        toast.error(
          "Can't edit cancelled order"
        );
      }
    }else{
      toast.error(
        "You can't edit order check permission"
      );
    }
  }

  const itemListContent = (item,index) => {
    return (
      <>
          <tr
            className={`align-middle cursor-pointer ${
              checkOrderDetails.item
                ?.id === item?.id
                ? "submitActiveOrder"
                : ""
            }`}
            key={index}
            
            onDoubleClick = {()=>doubleClickOrderEdit(item)}

            onClick={() => orderEdit(item)}
          >
            <th
              scope="row"
              className="table-text text-capitalize align-middle text-center"
            >
              {index +
                1 +
                (submittedOrders.current_page -
                  1) *
                  submittedOrders.per_page}
            </th>

            <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
              {parseInt(getSystemSettings(generalSettings, "order_no")) === 1 ? item.order_no : "#"+item.token?.id}
            </td>

            {item.order_type_name ===
            "Delivery" ? (
              <td className="table-text align-middle text-center bg-success">
                {item.order_type_name +
                  " - " +
                  item.party_name}
              </td>
            ) : item.order_type_name ===
              "Takeaway" ? (
              <td className="table-text align-middle text-center bg-secondary">
                {item.order_type_name +
                  " - " +
                  item.party_name}
              </td>
            ) : (
              <td className="table-text align-middle text-center table_child_ordertype">
                {item.order_type_name +
                  " - " +
                  item.party_name}
              </td>
            )}
            <td className="table-text align-middle text-center table_child_ordertype">
              {item.customer_name}
            </td>
            <td className="table-text align-middle text-center table_child_ordertype">
              {item.delivery_phn_no ? item.delivery_phn_no : "-"}
            </td>
            
            <td className="table-text align-middle text-center table_child_branch">
              {item.branch_name || "-"}
            </td>

            <td className="table-text align-middle text-center">
              {item.waiter_name}
            </td>

            <td className="table-text align-middle text-center">
              {item.table_name}
            </td>

            <td className="table-text text-capitalize align-middle text-center table_child">
             {itemListRowStatus(item)}
            </td>
            <td className="table-text text-capitalize align-middle text-center table_child_token">
              <Moment format="LT">
                {item.token?.time}
              </Moment>
            </td>
            
            <td className="table-text text-capitalize align-middle text-center table_child_token">
              <Moment format="LT">
               {item?.created_at}
              </Moment>
            </td>

            <td className="table-text text-capitalize align-middle text-center table_child_token">
              {item?.delivery_boy_name ? item.delivery_boy_name : "-" }
            </td>

            <td className="table-text text-capitalize align-middle text-center table_child_token">
              {item?.delivery_boy_id &&
                item?.rider_status ? (
                <span
                className="btn btn-transparent btn-secondary xsm-text text-capitalize px-2 mr-2">
                  {item.rider_status}
                </span>
                ) : "-"
              }
            </td>

            <td className="table-text text-capitalize align-middle text-center">
             {item?.ref_no ? item.ref_no : "-"}
            </td>

            <td className="table-text text-capitalize align-middle text-center">
              {item.user_name}
            </td>
          </tr>
        </>
    );
  };


  let printText = "";
  let taxTaken = "";
  let taxImage = "";
  const qrPrint = (taxTaken,printText,InvoiceNum,taxImage) =>{
    return(
      <div className="mb-1">
        <p className="print-text fk-print-text text-capitalize text-center">
        {taxTaken} Invoice Number# {InvoiceNum}
        </p>
        <div className="mb-1">
          <div className="float-left">
            <img
              src={taxImage}
              height="80"
              alt={`${taxTaken} Invoice`}
            />
          </div>
          <div className="float-right">
            {InvoiceNum && (
              <QRCode value={InvoiceNum} size="80" />
            )}
          </div>
          <div style={{ clear: "both" }}></div>
          </div>
          <p className="print-text fk-print-text text-center">
            {printText} <br></br> and win exciting prizes in
            draw.
          </p>
      </div>
    )
  }
  console.log("check print data==>",checkOrderDetails.item);
  return (
    <div className="submitted-main">
      <Helmet>
        <title>{_t(t("Submitted orders"))}</title>
      </Helmet>

      {/* Print bill */}
      <div className="d-none">
        <div ref={componentRef}>
          {checkOrderDetails && checkOrderDetails.item && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                  {
                    parseInt(getSystemSettings(generalSettings, "print_logo")) === 1 && (
                      <div className="mx-auto t-w-120">
                        <img
                          src={`${getSystemSettings(
                            generalSettings,
                            "type_logo"
                          )}`}
                          className="w-100"
                          //height={100}
                        />
                      </div>
                    ) 
                  }

                    {
                      parseInt(getSystemSettings(generalSettings, "print_siteName")) === 1 && (
                        <div className="fk-print-text font-weight-bold text-center sm-text mt-2">
                          {getSystemSettings(generalSettings, "siteName")}
                        </div>
                      ) 
                   }
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchName")) === 1 && (
                      <div className="fk-print-text font-weight-bold text-uppercase text-center sm-text mt-2">
                        {checkOrderDetails.item.branch_name}
                      </div>
                    ) 
                   }  
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchAddress")) === 1 && (
                      <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {checkOrderDetails.item.theBranch !== null &&
                      checkOrderDetails.item.theBranch.address
                        ? checkOrderDetails.item.theBranch.address
                        : ""}
                      </p>
                    )
                  }
                  {
                    parseInt(getSystemSettings(generalSettings, "print_branchPhone")) === 1 &&(
                      <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {_t(t("call"))}:{" "}
                      {checkOrderDetails.item.theBranch !== null &&
                      checkOrderDetails.item.theBranch.phn_no
                        ? checkOrderDetails.item.theBranch.phn_no
                        : ""}
                     </p>
                    )
                  }
                   
                    {checkOrderDetails.item?.table_name && checkOrderDetails.item?.table_name !== "-" ? (
                      <p className="mb-0 sm-text fk-print-text text-capitalize text-center">
                        {_t(t("Table # "))}: {checkOrderDetails.item?.table_name}
                      </p>
                    ) : (
                      ""
                    )}
                    {
                      parseInt(getSystemSettings(generalSettings, "print_heading")) === 1 && (
                        <p className="mb-0 sm-text fk-print-text text-center">
                         {getSystemSettings(generalSettings, "type_print_heading")}
                        </p>
                      )
                    }
                     
                    {
                      getSystemSettings(generalSettings, "duplicate_print_text") && getSystemSettings(generalSettings, "duplicate_print_text") !== "" && (
                        <p className="mb-0 print-text fk-print-text">
                          {getSystemSettings(generalSettings, "duplicate_print_text")}
                        </p>
                      )
                    }
                    <div className = "d-flex align-items-center justify-content-between myBorderTopCustomer pt-1"> 
                      <span className="fk-print-text text-uppercase text-center print-text mr-1">
                       {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "} {checkOrderDetails.item.token?.id}
                      </span>

                      {
                        parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
                          <span className="fk-print-text text-uppercase text-right print-text ml-1">
                            Invoice No - {" "}
                            {checkOrderDetails.item?.order_no && checkOrderDetails.item.order_no}
                          </span>
                        )
                      }
                    </div>  
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "both" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {checkOrderDetails.item.order_type_name +
                          "-" +
                          checkOrderDetails.item?.party_name}
                        </p>
                      )
                    }
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "order_type" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {checkOrderDetails.item.order_type_name}
                        </p>
                      )
                    }
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "party" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {
                          checkOrderDetails.item?.party_name}
                        </p>
                      )
                    }
                    
                   
                    <p className="mb-0 mt-0 print-text fk-print-text text-capitalize text-center">
                      {_t(t("Customer Copy"))}
                    </p>

                    <div className="myBorder mb-2"></div>
                    
                      {
                        checkOrderDetails.item.customer_name &&(
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("Customer Name"))}:{" "}
                          {checkOrderDetails.item.customer_name}
                        </p>
                        )
                      }
                      {
                        checkOrderDetails.item.delivery_phn_no && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("Phone #"))}:{" "}
                          {checkOrderDetails.item.delivery_phn_no}
                        </p>
                        )
                      }
                      {
                        checkOrderDetails.item.delivery_address && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Address "))}:{" "}
                            {checkOrderDetails.item.delivery_address}
                          </p>
                        )
                      }
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {
                            "ref no :" +
                            (checkOrderDetails.item?.ref_no
                              ? checkOrderDetails.item?.ref_no
                              : "")}
                        </p>

                        {
                          checkOrderDetails.item.delivery_boy_name && (
                            <p className="mb-1 print-text fk-print-text text-capitalize">
                              {_t(t("Rider name "))}:{" "}
                              {checkOrderDetails.item.delivery_boy_name}
                            </p>
                          )
                        }
                   
                    { parseInt(getSystemSettings(generalSettings, "print_paymentType")) === 1 && (
                      !checkOrderDetails.settle ? (
                      parseInt(checkOrderDetails.item.is_cancelled) === 0 ? (
                        parseInt(checkOrderDetails.item.is_paid) === 0 ? (
                          <span>
                            <p className="mb-0 md-text fk-print-text text-capitalize">
                              Unpaid
                            </p>
                          </span>
                        ) : (
                          <span>
                            <p className="mb-0 md-text fk-print-text text-capitalize">
                              PAID -{" "}
                              {checkOrderDetails.item.bill_distribution
                                ? printPayment(checkOrderDetails.item.bill_distribution)
                                : ""}
                            </p>
                          </span>
                        )
                      ) : (
                        <span>
                          <p className="mb-0 md-text fk-print-text text-capitalize">
                            Cancelled
                          </p>
                        </span>
                      )
                    ) : (
                      ""
                    ))}
                    <p className="mb-1 print-text fk-print-text text-capitalize">
                      {_t(t("date"))}:{" "}
                      <Moment format="MMMM D, YYYY, h:mm A">
                        {checkOrderDetails.item.created_at}
                      </Moment>
                      {/* {", "}
                      <Moment format="LT">
                        {checkOrderDetails.item.token?.time}
                      </Moment> */}
                    </p>
                    
                    {checkOrderDetails.item.waiter_name !== "-" && (
                      <p className="mb-1 print-text fk-print-text text-capitalize">
                      {_t(t("waiter name"))}:{" "}
                      {checkOrderDetails.item.waiter_name}
                      </p>
                      )}
                      { 
                        checkOrderDetails.item.order_type_name === "Dine-In" && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("guest"))}:{" "}
                            {checkOrderDetails.item.total_guest}
                          </p>
                        )
                      }
                    {parseInt(getSystemSettings(generalSettings, "print_paymentType")) === 1 && checkOrderDetails.payment_type !== null && (
                      <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                        PAID - {printPayment(checkOrderDetails.payment_type)}
                      </p>
                    )}

                    <table className="table mb-0 table-borderless akash-table-for-print-padding">
                      <thead>
                        <tr>
                          <th
                          scope="col"
                          className="fk-print-text print-text text-capitalize "
                          >
                         {/* <div className="d-flex flex-wrap align-items-center">
                            <span style={{width:"10.5%"}} className="d-inline-block">
                            {_t(t("qty"))}
                            </span>
                            <span style={{width:"82%"}} className="d-inline-block">
                            {_t(t("item"))}
                            </span>
                            </div>*/}
                           {_t(t("qty"))}  {_t(t("item"))}
                          </th>
                          <th
                          scope="col"
                          className="fk-print-text print-text text-capitalize text-right"
                          >      
                          {_t(t("T"))}.{_t(t("price"))} 
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.orderedItems.map(
                            (thisItem, indexThisItem) => {
                              return (
                                <>
                                <tr>
                                    <td className="fk-print-text print-text text-capitalize">
                                      <div className="d-flex flex-wrap align-items-center">
                                      <span style={{width:"9%"}} className="fk-print-text d-inline-block">
                                      {thisItem.quantity}{" "}
                                      </span>
                                      <span style={{width:"83%"}} className="fk-print-text d-inline-block ml-2">
                                        {thisItem.food_item}
                                        {thisItem.variation !== null &&
                                          "(" + thisItem.variation + ")"}
                                      </span>
                                      </div>
                                    </td>
                                    <td className="fk-print-text print-text text-capitalize text-right">
                                        {
                                          //thisItem.after_tax_price
                                          // ? formatPrice(
                                          //     parseFloat(thisItem.after_tax_price)
                                          //   )
                                          showPriceOfEachOrderItemPrint(thisItem,taxType,checkOrderDetails.item.theBranch.branch_tax)
                                        }
                                      </td>
                                    </tr>
                                    {/* properties */}
                                    {thisItem.properties !== null && 
                                      JSON.parse(thisItem.properties).map(
                                        (propertyItem, thisIndex) => {
                                          return (
                                            <tr>
                                            <td className="fk-print-text print-text text-capitalize">
                                              <span className="fk-print-text text-capitalize print-text d-inline-block mr-1 " style={{marginLeft:"14%"}}>
                                                -{thisItem.quantity}
                                                {propertyItem.quantity > 1
                                                  ? "*" +
                                                    propertyItem.quantity
                                                  : ""}{" "}
                                                {propertyItem.property}
                                              </span>
                                              </td>
                                              <td className="fk-print-text print-text text-capitalize text-right">
                                                {showPropertyPrice(
                                                  thisItem.quantity,
                                                  propertyItem.quantity,
                                                  propertyItem.price_per_qty,
                                                  taxType,
                                                  checkOrderDetails.item.theBranch.branch_tax
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                </>
                              );
                            }
                          )}
                      </tbody>
                    </table>

                    <div className="myBorder mb-1"></div>
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            {_t(t("total"))}
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(checkOrderDetails.item.order_bill)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {parseFloat(checkOrderDetails.item.vat) > 0 && (
                      <table className="table mb-0 table-borderless">
                        <tbody>
                          {checkOrderDetails.item.vat_system === "igst" ? (
                            <tr>
                              <th className="py-0 pb-1 fk-print-text print-text text-uppercase">
                                {checkOrderDetails.item.theBranch?.print_tax}
                                  {checkOrderDetails.settle && taxPercent > 0
                                    ? "(" + taxPercent + `%)`
                                   : "(" + checkOrderDetails.item.theBranch?.branch_tax + `%)`}
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                  checkOrderDetails.settle && taxPercent > 0
                                    ? Math.ceil(
                                        (parseFloat(taxPercent) / 100) *
                                          parseFloat(
                                            checkOrderDetails.item.order_bill
                                          )
                                      )
                                    : checkOrderDetails.item.vat
                                )}
                              </td>
                            </tr>
                          ) : (
                            <span>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                   CGST
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    parseFloat(checkOrderDetails.item.cgst)
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  SGST
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    parseFloat(checkOrderDetails.item.sgst)
                                  )}
                                </td>
                              </tr>
                            </span>
                          )}
                        </tbody>
                      </table>
                    )}
                    {getSystemSettings(generalSettings, "sDiscount") ===
                      "flat" && (
                      <>
                        {parseFloat(checkOrderDetails.item.service_charge) >
                          0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("D.Charge"))}
                                </th>

                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.service_charge
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                           {checkOrderDetails.item.service_amount &&
                        parseFloat(checkOrderDetails.item.service_amount) >
                          0 ? (
                        <table className="table mb-0 table-borderless">
                         <tbody>
                          <tr>
                            <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                {_t(t("S.Charge"))}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                              {checkOrderDetails.item.service_amount
                                ? formatPrice(
                                    parseFloat(
                                      checkOrderDetails.item.service_amount
                                    )
                                  )
                                : 0}
                            </td>
                          </tr>
                          </tbody>
                         </table>
                        ) : null}
                        {parseFloat(checkOrderDetails.item.discount) > 0 ||
                        parseFloat(checkOrderDetails.item.discount_amount) >
                          0 ? (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("discount"))}
                                    {checkOrderDetails &&
                                      checkOrderDetails.item.scheme_id !==
                                        null && parseFloat(checkOrderDetails.item.discount) > 0 &&
                                      `(${parseFloat(
                                        checkOrderDetails.item.discount
                                      )}%)`}
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.discount_amount
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </>
                    )}

                    {getSystemSettings(generalSettings, "sDiscount") ===
                      "percentage" && (
                      <>
                        {parseFloat(checkOrderDetails.item.service_charge) >
                          0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("D.Charge"))}
                                    {checkOrderDetails.item &&
                                      "(" +
                                        checkOrderDetails.item.service_charge +
                                        "%)"}
                                </th>

                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.order_bill *
                                      (checkOrderDetails.item.service_charge /
                                        100)
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                        {checkOrderDetails.item.service_amount &&
                          parseFloat(checkOrderDetails.item.service_amount) >
                            0 ? (
                            <table className="table mb-0 table-borderless">
                             <tbody> 
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("S.Charge"))}
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {checkOrderDetails.item.service_amount
                                    ? formatPrice(
                                        parseFloat(
                                          checkOrderDetails.item.service_amount
                                        )
                                      )
                                    : 0}
                                </td>
                              </tr>
                             </tbody>
                            </table>
                          ) : null}

                        {parseFloat(checkOrderDetails.item.discount) > 0 ||
                        parseFloat(checkOrderDetails.item.discount_amount) >
                          0 ? (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("discount"))}
                                    {checkOrderDetails.item && parseFloat(checkOrderDetails.item.discount) > 0 &&
                                      "(" +
                                        checkOrderDetails.item.discount +
                                        "%)"}
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.discount_amount
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </>
                    )}
                  
                    {checkOrderDetails?.payment_type && checkOrderDetails.payment_type?.length > 1 && (
                      <>
                        <div className="myBorder mb-1"></div>
                        <table className="table mb-0 table-borderless">
                          <tbody>
                              {checkOrderDetails?.payment_type.map((item)=>{
                                return (
                                <tr>
                                  <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t(item.name))}
                                  </th>
                                  <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {_t(t(item.bill_amount))}
                                  </td>
                                </tr>
                                )
                              }) 
                            }
                          </tbody>
                        </table>
                      </>
                    )}
                    <div className="myBorder mb-1"></div>
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        {
                           (checkOrderDetails.item.theBranch.fbr_integration ===
                          1  || checkOrderDetails.item.theBranch.pbr_integration === 1 || checkOrderDetails.item?.theBranch?.sbr_integration === 1) &&
                        isSettle.current &&
                        (fbrInvoiceNum || srbInvoiceNum) ? (
                          <tr>
                            <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                {_t(t("POS Invoice Fee"))}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                              {_t(t("1.00"))}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                              {_t(t("grand total"))}
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {
                              formatPrice(
                                  checkOrderDetails.item.total_payable
                                )}
                          </td>
                        </tr>
                        <tr>
                          {checkOrderDetails.item.is_refund === 1 ? (
                            <>
                              <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  {_t(t("Refund Amount"))}
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                  checkOrderDetails.item.paid_amount
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  {_t(t("Paid Amount"))}
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                  checkOrderDetails.settle
                                    ? paidMoney
                                    : checkOrderDetails.item.paid_amount
                                )}
                              </td>
                            </>
                          )}
                        </tr>

                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                              {_t(t("Return Amount"))}
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {(checkOrderDetails.settle
                              ? paidMoney
                              : checkOrderDetails.item.paid_amount) -
                              parseFloat(checkOrderDetails.item.total_payable) >
                            0
                              ? formatPrice(
                                  (checkOrderDetails.settle
                                    ? paidMoney
                                    : checkOrderDetails.item.paid_amount) -
                                    parseFloat(
                                      checkOrderDetails.item.total_payable
                                    )
                                )
                              : formatPrice(0)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {parseInt(getSystemSettings(generalSettings,"view_cash_card")) === 1 && (
                      <React.Fragment>
                        <div className="myBorder mt-1"></div>
                        {renderNetBill(checkOrderDetails.item.total_payable,checkOrderDetails.item.order_bill, checkOrderDetails.item.vat, "cash", "Cash Net Bill")}
                        
                        {renderNetBill(checkOrderDetails.item.total_payable, checkOrderDetails.item.order_bill, checkOrderDetails.item.vat, "card", "Card Net Bill")}
                     </React.Fragment>  
                     )
                    }
                    {(checkOrderDetails.item.theBranch.fbr_integration === 1 || checkOrderDetails.item.theBranch.pbr_integration === 1) &&
                      (fbrInvoiceNum) && (
                        printText = `Verify this invoice through ${checkOrderDetails.item.theBranch.fbr_integration ? "FBR" : "PRA"} TaxAsaan MobileApp or SMS at 9966`,
                        taxTaken = checkOrderDetails.item.theBranch.fbr_integration ? "FBR" : "PBR",
                        taxImage = checkOrderDetails.item.theBranch.fbr_integration ? "/assets/img/pos-invoice-system.png" : "/assets/img/PRA.png",
                        qrPrint(taxTaken,printText,fbrInvoiceNum,taxImage)  
                      )}
                    
                    {
                      //getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : checkOrderDetails.item.theBranch.sbr_integration === 1 &&
                      srbInvoiceNum && (
                        printText = "Verify this invoice through eSRB MobileApp or SMS at 9697",
                        taxTaken = "SBR",
                        taxImage = "/assets/img/srb_pos_image.png",
                        qrPrint(taxTaken,printText,srbInvoiceNum,taxImage)   
                      )}
                   
                    <div className="myBorder"></div>
                    {checkOrderDetails.item?.note_to_rider && checkOrderDetails.item?.note_to_rider !== "" && 
                      <p className="mb-1 print-text fk-print-text text-capitalize mx-1">
                      {"Rider Note : " +
                          (checkOrderDetails.item.note_to_rider && 
                            checkOrderDetails.item.note_to_rider !== "" &&
                            checkOrderDetails.item.note_to_rider
                        )}
                      </p>    
                     }
                    <p className="white-space my-0 sm-text fk-print-text text-center text-capitalize">
                      {getSystemSettings(generalSettings, "type_print_footer")}
                    </p>
                    <p className="my-0 sm-text fk-print-text text-capitalize text-center">
                      {_t(t("bill prepared by"))}:{" "}
                      {checkOrderDetails.item &&
                        checkOrderDetails.item.user_name}
                    </p>
                    {
                       parseInt(getSystemSettings(generalSettings, "print_bill_footer")) === 1 && (
                        <p className="my-0 sm-text fk-print-text text-capitalize text-center">
                          {_t(t(""))}
                        </p>
                       )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={showSettle && "d-none"}>
        {/* Settle modal */}
        <div className="modal fade" id="orderDetails" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <div className="fk-sm-card__content">
                  <h5 className="text-capitalize fk-sm-card__title">
                    {/* show order token on modal header */}
                    {_t(t("Order details, Token"))}: #
                    {checkOrderDetails.item && checkOrderDetails.item.token?.id}
                  </h5>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {/* if loading true show loading effect */}
              {loading ? (
                <div className="modal-body">{modalLoading(5)}</div>
              ) : (
                <div className="modal-body">
                  {checkOrderDetails.item &&
                  parseInt(checkOrderDetails.item.is_settled) === 0 ? (
                    // if this item is not settled then show settle-cancel button
                    <>
                      {checkOrderDetails.item &&
                        parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                          <div className="text-right">
                            {checkOrderDetails.settle &&
                              paidMoney >
                                parseFloat(
                                  checkOrderDetails.item.total_payable
                                ) && (
                                <span className="mr-2 text-secondary font-weight-bold">
                                  Return: {currencySymbolLeft()}
                                  {formatPrice(returnMoneyUsd)}
                                  {currencySymbolRight()}{" "}
                                </span>
                              )}
                            {checkOrderDetails.settle ? (
                              <button
                                className="btn btn-primary px-3 rounded-md text-uppercase"
                                onClick={() => {
                                  setCheckOrderDetails({
                                    ...checkOrderDetails,
                                    settle: false,
                                    payment_amount: null,
                                    payment_type: null,
                                  });
                                  setReturnMoneyUsd(0);
                                  setPaidMoney(0);
                                  setTotalAmount(parseFloat(checkOrderDetails.item.total_payable));
                                }}
                              >
                                {_t(t("Cancel"))}
                              </button>
                            ) : (
                              <>
                                {getSystemSettings(
                                  generalSettings,
                                  "pos_screen"
                                ) === "0" ? (
                                  <button
                                    className="btn btn-success px-3 rounded-md text-uppercase"
                                    onClick={() => {
                                      setCheckOrderDetails({
                                        ...checkOrderDetails,
                                        settle: true,
                                        payment_amount: null,
                                        payment_type: null,
                                      });
                                      setReturnMoneyUsd(0);
                                      setPaidMoney(0);
                                    }}
                                  >
                                    {_t(t("Settle order"))}
                                  </button>
                                ) : (
                                  <
                                    // className="btn btn-danger px-3 rounded-md text-uppercase"
                                    // data-dismiss="modal"
                                    // aria-label="Close"
                                    // onClick={() => {
                                    //   setCheckOrderDetails({
                                    //     ...checkOrderDetails,
                                    //     settle: false,
                                    //     payment_amount: null,
                                    //     payment_type: null,
                                    //   });
                                    //   setReturnMoneyUsd(0);
                                    //   setPaidMoney(0);
                                    //   setShowSettle(true);
                                    // }}
                                  >
                                    {/* {_t(t("Settle order"))} */}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                    </>
                  ) : (
                    // if this item is not settled then show settle-cancel button else, show this notification
                    <div className="text-center bg-success text-white py-2">
                      {_t(t("Order has been settled, you can close this now"))}
                    </div>
                  )}
                  {checkOrderDetails.item &&
                    //show this if order is cancelled
                    parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                      <div className="text-center bg-secondary text-white py-2">
                        {_t(t("This order has been cancelled"))}
                      </div>
                    )}
                  {/* show this if order settle is not true, if true show payment input field */}
                  {!checkOrderDetails.settle ? (
                    <div className="col-12 filtr-item">
                      <div className="fk-order-token t-bg-white">
                        <div className="fk-order-token__body">
                          <div className="fk-addons-table">
                          <div className="d-flex justify-content-between px-3 fk-addons-table__head">
                            <div className="mr-3">
                              {_t(t("order token"))}: #
                              {checkOrderDetails.item &&
                                checkOrderDetails.item.token?.id}
                              </div>
                              <div>
                              {
                              `${checkOrderDetails?.item && checkOrderDetails?.item?.order_type_name} - ${checkOrderDetails?.item && checkOrderDetails?.item?.party_name}`
                                }
                              </div>
                            </div>
                            <div className="fk-addons-table__info">
                              <div className="row g-0">
                                <div className="col-2 text-center border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("S/L"))}
                                  </span>
                                </div>
                                <div className="col-3 text-center border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("food"))}
                                  </span>
                                </div>
                                <div className="col-4 text-left pl-2 border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("Additional Info"))}
                                  </span>
                                </div>
                                <div className="col-2 text-center border-right">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("QTY"))}
                                  </span>
                                </div>
                                <div className="col-1 text-center">
                                  <span className="fk-addons-table__info-text text-capitalize">
                                    {_t(t("Status"))}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {checkOrderDetails.item &&
                              checkOrderDetails.item.orderedItems.map(
                                (thisItem, indexThisItem) => {
                                  return (
                                    <div className="fk-addons-table__body-row">
                                      <div className="row g-0">
                                        <div className="col-2 text-center border-right d-flex">
                                          <span className="fk-addons-table__info-text text-capitalize m-auto">
                                            {indexThisItem + 1}
                                          </span>
                                        </div>
                                        <div className="col-3 text-center border-right d-flex">
                                          <span className="fk-addons-table__info-text text-capitalize m-auto">
                                            {thisItem.food_item} (
                                            {thisItem.food_group})
                                          </span>
                                        </div>
                                        <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                          {thisItem.variation !== null && (
                                            <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                              <span className="font-weight-bold mr-1">
                                                {_t(t("variation"))}:
                                              </span>
                                              {thisItem.variation}
                                            </span>
                                          )}

                                          {thisItem.properties !== null && (
                                            <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                              <span className="font-weight-bold mr-1">
                                                {_t(t("properties"))}:
                                              </span>
                                              {JSON.parse(
                                                thisItem.properties
                                              ).map(
                                                (propertyItem, thisIndex) => {
                                                  if (
                                                    thisIndex !==
                                                    JSON.parse(
                                                      thisItem.properties
                                                    ).length -
                                                      1
                                                  ) {
                                                    return (
                                                      propertyItem.property +
                                                      `${
                                                        propertyItem.quantity >
                                                        1
                                                          ? "(" +
                                                            propertyItem.quantity +
                                                            ")"
                                                          : ""
                                                      }` +
                                                      ", "
                                                    );
                                                  } else {
                                                    return (
                                                      propertyItem.property +
                                                      `${
                                                        propertyItem.quantity >
                                                        1
                                                          ? "(" +
                                                            propertyItem.quantity +
                                                            ")"
                                                          : ""
                                                      }`
                                                    );
                                                  }
                                                }
                                              )}
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-2 text-center border-right d-flex">
                                          <span className="fk-addons-table__info-text text-capitalize m-auto">
                                            {thisItem.quantity}
                                          </span>
                                        </div>

                                        <div className="col-1 text-center d-flex">
                                          <label className="mx-checkbox mx-checkbox--empty m-auto">
                                            <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                              {parseInt(thisItem.is_cooking) ===
                                              1 ? (
                                                [
                                                  parseInt(
                                                    thisItem.is_ready
                                                  ) === 1 ? (
                                                    <i
                                                      className="fa fa-check text-success"
                                                      title={_t(t("Ready"))}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      className="fa fa-cutlery text-secondary"
                                                      title={_t(t("Cooking"))}
                                                    ></i>
                                                  ),
                                                ]
                                              ) : (
                                                <i
                                                  className="fa fa-times text-primary"
                                                  title={_t(t("Pending"))}
                                                ></i>
                                              )}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="my-2 payment-type-parent">
                      <Select
                        options={paymentTypeForSearch && paymentTypeForSearch}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        className="xsm-text"
                        backspaceRemovesValue={false}
                        onChange={handleSetpaymentType}
                        maxMenuHeight="200px"
                        isMulti
                        clearIndicator={null}
                        placeholder={_t(t("Select payment methods")) + ".."}
                      />
                      {checkOrderDetails.payment_type !== null && (
                        <form
                          className="border my-2 change-background rounded-lg"
                          onSubmit={handleSettleOrder}
                          autoComplete="off"
                        >
                          <div className="sm-text text-center text-white py-2">
                            {_t(t("Amount"))}
                          </div>
                          {checkOrderDetails.payment_type.map(
                            (eachPaymentType, paymentTypeIndex) => {
                              return (
                                <div className="addons-list__item mx-2 mb-1">
                                  <input
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    name={eachPaymentType.id}
                                    autoComplete="off"
                                    className="form-control xsm-text pl-2"
                                    onChange={handlePaymentTypeAmount}
                                    placeholder={eachPaymentType.name}
                                    value={
                                      checkOrderDetails.payment_amount &&
                                      checkOrderDetails.payment_amount[
                                        eachPaymentType.id
                                      ]
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          <div className="pb-2 pl-2 my-2">
                            <button
                              className="btn btn-sm btn-warning text-dark px-3 text-uppercase"
                              type="submit"
                            >
                              {_t(t("Settle order"))}
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  )}
                  <table className="table table-striped table-sm text-center mt-3">
                    <thead className="bg-info text-white text-uppercase">
                      <tr>
                        <th scope="col" colSpan="2">
                          {_t(t("Order details"))}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Received by"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.user_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Customer"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.customer_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">{_t(t("Contact"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.delivery_phn_no}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Delivery Address"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.delivery_address}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-capitalized">{_t(t("Branch"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.branch_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Department"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.dept_tag_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">{_t(t("Table"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.table_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">{_t(t("Waiter"))}</td>
                        <td>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.waiter_name}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Punch Time"))}
                        </td>
                        <td>
                          <Moment format="LLL">
                            {checkOrderDetails.item && checkOrderDetails.item.created_at}
                          </Moment>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Time To Deliver"))}
                        </td>
                        <td>
                          {checkOrderDetails.item &&
                          checkOrderDetails.item?.time_to_deliver !== null
                            ? checkOrderDetails.item.time_to_deliver + " min(s)"
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Subtotal"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.order_bill)}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                      {checkOrderDetails.item &&
                      checkOrderDetails.item.vat_system === "igst" ? (
                        <tr>
                          <td className="text-capitalized">{checkOrderDetails.item.theBranch?.print_tax}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(checkOrderDetails.item.vat)}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("CGST"))}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    parseFloat(checkOrderDetails.item.cgst)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("SGST"))}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    parseFloat(checkOrderDetails.item.sgst)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      )}

                      {/* sdiscount */}
                      {getSystemSettings(generalSettings, "sDiscount") ===
                        "flat" && (
                        <>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Service charge"))}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    checkOrderDetails.item.service_charge
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Discount"))}
                            </td>
                            <td>
                              {checkOrderDetails.item &&
                                (parseFloat(
                                  checkOrderDetails.item.discount_amount
                                ) > 0 ? (
                                  <>
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      parseFloat(
                                        checkOrderDetails.item.discount_amount
                                      )
                                    )}
                                    {currencySymbolRight()}
                                  </>
                                ) : (
                                  <>
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      checkOrderDetails.item.discount
                                    )}
                                    {currencySymbolRight()}
                                  </>
                                ))}
                            </td>
                          </tr>
                        </>
                      )}

                      {getSystemSettings(generalSettings, "sDiscount") ===
                        "percentage" && (
                        <>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Service charge"))}
                              {checkOrderDetails.item &&
                                "(" +
                                  checkOrderDetails.item.service_charge +
                                  "%)"}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    checkOrderDetails.item.order_bill *
                                      (checkOrderDetails.item.service_charge /
                                        100)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-capitalized">
                              {_t(t("Discount"))}
                              {checkOrderDetails.item &&
                                "(" + checkOrderDetails.item.discount + "%)"}
                            </td>
                            <td>
                              {checkOrderDetails.item && (
                                <>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    checkOrderDetails.item.order_bill *
                                      (checkOrderDetails.item.discount / 100)
                                  )}
                                  {currencySymbolRight()}
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                      {/* sDiscount */}
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Department Commission"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                checkOrderDetails.item.dept_commission
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Total bill"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                checkOrderDetails.item.total_payable
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Paid amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.paid_amount)}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>

                      {checkOrderDetails.item &&
                      parseFloat(
                        checkOrderDetails.item.total_payable -
                          checkOrderDetails.item.paid_amount
                      ) >= 0 ? (
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Due amount"))}
                          </td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  parseFloat(
                                    checkOrderDetails.item.total_payable -
                                      checkOrderDetails.item.paid_amount
                                  )
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Return amount"))}
                          </td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  parseFloat(
                                    checkOrderDetails.item.paid_amount -
                                      checkOrderDetails.item.total_payable
                                  )
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Settle modal Ends*/}

        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              <div className="col-12 t-mb-30 mb-lg-0">
                {checkOrderDetails.uploading === true || loading === true ? (
                  pageLoading()
                ) : (
                  <div className="t-bg-white ">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}
                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                     {/* <div className="col-5 t-mb-15">
                        <ul className="t-list fk-breadcrumb">
                          <li className="fk-breadcrumb__list">
                            <span className="t-link fk-breadcrumb__link text-capitalize">
                              {!searchedOrder.searched
                                ? _t(t("Submitted orders"))
                                : _t(t("Search Result"))}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-5 t-mb-15">
                        <span className="px-4 t-link fk-breadcrumb__link">
                          Filter By
                        </span>
                      </div> */}
                      <div
                        className="col-md-5 col-lg-5 t-mb-15 mb-md-0"
                        style={{ borderRight: "1px solid #6c757da6" }}
                      >
                        <ul className="t-list fk-sort align-items-center mr-3">
                         <li className="fk-breadcrumb__list mr-3">
                         <span className="t-link fk-breadcrumb__link text-capitalize">
                         {!searchedOrder.searched
                           ? _t(t("Submitted orders"))
                           : _t(t("Search Result"))}
                         </span>
                         </li>
                          <div className="input-group col">
                            <div className="form-file">
                              <input
                                type="text"
                                className="form-control form-control--light-1 rounded-left"
                                placeholder={
                                  _t(
                                    t(
                                      "Search by token, customer, branch, order type"
                                    )
                                  ) + ".."
                                }
                                onChange={handleSearch}
                              />
                            </div>
                            <button
                              className="btn btn-primary rounded-right"
                              type="button"
                            >
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </ul>
                      </div>
                      <div className="col-md-5 col-lg-5">
                        <div className="d-flex px-4 align-items-center gx-2">
                        {
                          availableDeliveryTypes && availableDeliveryTypes?.map((orderType)=>{
                            return(
                              <div className="mr-3">
                              <input
                              className={`submit-filter ${activebtn === orderType.name ? "active-btn" : ""} `}
                              id = {orderType.name}
                              name = {orderType.name}
                              value= {orderType.name}
                              type = "submit"
                              onClick={(e) => {
                                setActivebtn(e.target.name);
                                handleSearch(e)
                              }}
                              />
                            </div>
                            )
                          })
                        }
                          <div className="mr-3">
                            <input
                              className={`submit-filter ${activebtn === "all" ? "active-btn" : ""} `}
                              id="all"
                              name="all"
                              value="All"
                              type="submit"
                              onClick={(e) =>{ 
                                setActivebtn(e.target.name)
                                handleSearch(e)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="row align-items-center gx-2">
                          <div className="col-6">
                            <NavLink
                              to="/dashboard/pos"
                              className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-secondary xsm-text text-uppercase text-center w-100 rounded"
                            >
                              {_t(t("POS"))}
                            </NavLink>
                          </div>
                          <div className="col-6 ">
                            <NavLink
                              to="/dashboard/pos/settled"
                              className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-success xsm-text text-uppercase text-center w-100 rounded"
                            >
                              {_t(t("Settled"))}
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fk-scroll--order-submitted" data-simplebar>
                      <div className="t-pl-15 t-pr-15">
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height mt-4">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                                >
                                  {_t(t("Token"))}
                                </th>

                                <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Order Type"))}
                                </th>
                                <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border table_child_ordertype"
                                >
                                  {_t(t("Customer"))}
                                </th>

                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_ordertype"
                               >
                                {_t(t("Customer Phone"))}
                               </th>

                               <th
                               scope="col"
                               className="table-text text-capitalize align-middle text-center border-1 border"
                               >
                                {_t(t("Branch"))}
                               </th>

                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                {_t(t("Waiter"))}
                              </th>

                                <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Table"))}
                                </th>

                                <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Status"))}
                                </th>
                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                               >
                                {_t(t("Time"))}
                               </th>

                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Dispatch Time"))}
                                </th>

                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Rider Name"))}
                                </th>

                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Rider Status"))}
                                </th>

                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Ref No"))}
                                </th>
                                <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("User Name"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {
                                /* loop here, logic === !search && haveData && haveDataLegnth > 0*/ console.log(
                                  "searchedOrder",
                                  searchedOrder,
                                  submittedOrders
                                )
                              }
                              {!searchedOrder.searched
                                ? [
                                    submittedOrders && [
                                      submittedOrders.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="8"
                                            className="table-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        submittedOrders.data.map(
                                          (item, index) => {
                                           return itemListContent(item,index)
                                          }
                                        )
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedOrder && [
                                      searchedOrder.list?.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="8"
                                            className="table-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedOrder.list.map(
                                          (item, index) => {
                                            return itemListContent(item,index)
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* settle buttons */}
                <div className="d-flex px-3 t-bg-white my-1 t-pt-5 t-pb-5">
                {parseInt(
                  checkOrderDetails?.item &&
                  checkOrderDetails.item?.is_cancelled
                  ) === 0 || checkOrderDetails?.item === null ? (
                    <>
                    {
                        authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Order edit"
                          ) ? (
                            <>
                            <button
                              className="submit-actionButton mr-2"
                              disabled={checkOrderDetails?.item && !checkOrderDetails.item?.order_type_name?.match(/dine(.*)in/i)}
                              onClick={() => {
                                if (checkOrderDetails?.item) {
                                  history.push(
                                    `/dashboard/epos/${checkOrderDetails.item?.id}?table=edit_transfer`
                                  );
                                } else {
                                  toast.error(
                                    "Please select the order first before action"
                                  );
                                }
                              }}
                            >
                              Table Transfer
                            </button>
                            <button
                            className="submit-actionButton mr-2"
                            disabled={checkOrderDetails?.item && !checkOrderDetails.item?.order_type_name?.match(/dine(.*)in/i)}
                            onClick={() => {
                              if (checkOrderDetails?.item) {
                                history.push(
                                  `/dashboard/epos/${checkOrderDetails.item?.id}?table=edit_merge`
                                );
                              } else {
                                toast.error(
                                  "Please select the order first before action"
                                );
                              }
                            }}
                          >
                            Table Merge
                          </button>
                          <button
                          className="submit-actionButton mr-2"
                          disabled={checkOrderDetails?.item && !checkOrderDetails.item?.order_type_name?.match(/dine(.*)in/i)}
                          onClick={() => {
                            if (checkOrderDetails?.item) {
                              history.push(
                                `/dashboard/epos/${checkOrderDetails.item?.id}?waiter=edit`
                              );
                            } else {
                              toast.error(
                                "Please select the order first before action"
                              );
                            }
                          }}
                        >
                          Change Waiter
                        </button>
                        </>
                          ) : (
                            ""
                          )
                    }
                     
                      {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "Order void"
                      ) ? (
                          <button
                            className="submit-actionButton mr-2"
                            onClick={() => {
                              if (checkOrderDetails?.item) {
                                handleCancelOrderConfirmation(
                                  checkOrderDetails?.item
                                );
                              } else {
                                toast.error(
                                  "Please select the order first before action"
                                );
                              }
                            }}
                          >
                            Void Order
                          </button>
                      ) : (
                        ""
                      )}
                      {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "Order edit"
                      ) ? (
                        <button
                          className="submit-actionButton mr-2"
                          onClick={() => {
                            if (checkOrderDetails?.item) {
                              if(checkOrderDetails?.item?.party_name==="Website"){
                                toast.error(
                                  "Invalid Party"
                                );
                              }else{
                                history.push(
                                  `/dashboard/epos/${checkOrderDetails.item?.id}`
                                );
                              }
                            } else {
                              toast.error(
                                "Please select the order first before action"
                              );
                            }
                          }}
                        >
                          Recall/Edit
                        </button>
                      ) : (
                        ""
                      )}
                      {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "Order edit"
                      ) ? (
                        <button
                          className="submit-actionButton mr-2"
                          onClick={() => {
                            if (checkOrderDetails?.item) {
                              history.push(
                                `/dashboard/epos/${checkOrderDetails.item?.id}`
                              );
                            } else {
                              toast.error(
                                "Please select the order first before action"
                              );
                            }
                          }}
                        >
                          Change Order Type
                        </button>
                      ) : (
                        ""
                      )}
                      {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "Free Of Cost"
                      ) && checkOrderDetails?.item && checkOrderDetails.item?.order_type_name &&
                       parseInt(checkOrderDetails.item?.is_hold) === 0 && !checkOrderDetails.item.order_type_name?.match(/delivery/i) ? (
                        <div className="mr-2">
                          <button
                            className="submit-actionButton"
                            onClick={() => {
                              if (checkOrderDetails?.item) {
                                handleFreeCostOrderConfirmation(
                                  checkOrderDetails?.item
                                );
                              } else {
                                toast.error(
                                  "Please select the order first before action"
                                );
                              }
                            }}
                          >
                            Free Of Cost
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Rider assign"
                        ) && checkOrderDetails?.item && checkOrderDetails.item?.order_type_name && checkOrderDetails.item.order_type_name?.match(/delivery/i) ? (
                          !checkOrderDetails.item.is_hold && (
                          <div className="mr-2">
                            <button
                              title = "assign rider"
                              className="submit-actionButton"
                              onClick={() => {
                                if (checkOrderDetails?.item) {
                                  if(checkOrderDetails.item.delivery_boy_id && checkOrderDetails.item?.rider_status === "Pick Up"){
                                    toast.error("Rider already assigned");
                                  }else{
                                    setRiderModal(true);
                                    setDeliveryStatus(deliveryStatusOption[0]);
                                    setCheckOrderDetails({
                                      ...checkOrderDetails,
                                      delivery_man_id: checkOrderDetails.item.delivery_boy_id,
                                      time_to_deliver: checkOrderDetails.item?.theBranch?.delivery_time ? checkOrderDetails.item?.theBranch?.delivery_time : "",
                                    });
                                  }
                                } else {
                                  toast.error("Please select the order first before action");
                                }
                              }}
                            >
                             <img src="/assets/img/rider.png" style={{height:"1.7rem"}}/>
                            </button>
                          </div>
                           )
                  
                        ) : (
                          ""
                        )}
                    </>
                  ) : (
                    <div className="mr-2 d-flex align-items-center border border-1">
                     <h6 className="py-1 px-3 lg-text my-0"> Not Allowed </h6>
                    </div>
                  )}
                </div>
                {/* pagination loading effect */}
                {checkOrderDetails.uploading === true || loading === true
                  ? paginationLoading()
                  : [
                      // logic === !searched
                      !searchedOrder.searched ? (
                        <div key="fragment4">
                          <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                            <div className="row align-items-center t-pl-15 t-pr-15">
                              <div className="col-md-7 t-mb-15 mb-md-0">
                                {/* pagination function */}
                                {pagination(
                                  submittedOrders,
                                  setPaginatedSubmittedOrders
                                )}
                              </div>
                              <div className="col-md-5">
                                <ul className="t-list d-flex justify-content-md-end align-items-center">
                                  <li className="t-list__item">
                                    <span className="d-inline-block sm-text">
                                      {showingData(submittedOrders)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // if searched
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              <ul className="t-list d-flex">
                                <li className="t-list__item no-pagination-style">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      setSearchedOrder({
                                        ...searchedOrder,
                                        searched: false,
                                      })
                                    }
                                  >
                                    {_t(t("Clear Search"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {searchedShowingData(
                                      searchedOrder,
                                      submittedOrdersForSearch
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ),
                    ]}
              </div>
            </div>
          </div>
        </main>       
      </div>

      {/* show settle  */}
      <div
        className={`fk-settle-container d-flex flex-column justify-content-center ${
          showSettle ? "" : "d-none"
        }`}
      >
        <div className="fk-settle">
          <div className="container-fluid">
            <div className="row gx-3">
              <div className="col-lg-6 d-none d-lg-block">
              <div className="d-flex justify-content-between px-2">
                <div className="sm-text d-block text-capitalize font-weight-bold py-3">
                  {_t(t("Settle order"))}
                </div>
                <div className="sm-text d-block text-capitalize font-weight-bold py-3">
                  {checkOrderDetails?.item && checkOrderDetails?.item?.branch_name}
                </div>
                </div>
                <div
                  className="fk-settle__products d-flex flex-column"
                  data-simplebar
                >
                  <div className="container-fluid">
                    <div className="row gx-3">
                      <div className="col-12">
                        {/* POS Product list will be here  */}
                        <div className="fk-price-table__body t-mt-10">
                          <div className="fk-price-table__body-top">
                            <div className="fk-table">
                              <div className="t-pb-30">
                                <div className="col-12 filtr-item">
                                  <div className="fk-order-token t-bg-white">
                                    <div className="fk-order-token__body">
                                      <div className="fk-addons-table">
                                      <div className="d-flex justify-content-between px-3 fk-addons-table__head">
                                       <div className="mr-3">
                                          {_t(t("order token"))} -
                                          {checkOrderDetails.item &&
                                            checkOrderDetails.item.token?.id}
                                        </div>
                                        <div>
                                        {
                                          `${checkOrderDetails?.item && checkOrderDetails?.item?.order_type_name} - ${checkOrderDetails?.item && checkOrderDetails?.item?.party_name}`
                                          }
                                       </div>
                                        </div>
                                        <div className="fk-addons-table__info">
                                          <div className="row g-0">
                                            <div className="col-2 text-center border-right">
                                              <span className="fk-addons-table__info-text text-capitalize">
                                                {_t(t("S/L"))}
                                              </span>
                                            </div>
                                            <div className="col-3 text-center border-right">
                                              <span className="fk-addons-table__info-text text-capitalize">
                                                {_t(t("food"))}
                                              </span>
                                            </div>
                                            <div className="col-4 text-left pl-2 border-right">
                                              <span className="fk-addons-table__info-text text-capitalize">
                                                {_t(t("Additional Info"))}
                                              </span>
                                            </div>
                                            <div className="col-2 text-center">
                                              <span className="fk-addons-table__info-text text-capitalize">
                                                {_t(t("QTY"))}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        {checkOrderDetails.item &&
                                          checkOrderDetails.item.orderedItems.map(
                                            (thisItem, indexThisItem) => {
                                              return (
                                                <div className="fk-addons-table__body-row">
                                                  <div className="row g-0">
                                                    <div className="col-2 text-center border-right d-flex">
                                                      <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                        {indexThisItem + 1}
                                                      </span>
                                                    </div>
                                                    <div className="col-3 text-center border-right d-flex">
                                                      <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                        {thisItem.food_item} (
                                                        {thisItem.food_group})
                                                      </span>
                                                    </div>
                                                    <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                                      {thisItem.variation !==
                                                        null && (
                                                        <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                          <span className="font-weight-bold mr-1">
                                                            {_t(t("variation"))}
                                                            :
                                                          </span>
                                                          {thisItem.variation}
                                                        </span>
                                                      )}

                                                      {thisItem.properties !==
                                                        null && (
                                                        <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                          <span className="font-weight-bold mr-1">
                                                            {_t(
                                                              t("properties")
                                                            )}
                                                            :
                                                          </span>
                                                          {JSON.parse(
                                                            thisItem.properties
                                                          ).map(
                                                            (
                                                              propertyItem,
                                                              thisIndex
                                                            ) => {
                                                              if (
                                                                thisIndex !==
                                                                JSON.parse(
                                                                  thisItem.properties
                                                                ).length -
                                                                  1
                                                              ) {
                                                                return (
                                                                  propertyItem.property +
                                                                  `${
                                                                    propertyItem.quantity >
                                                                    1
                                                                      ? "(" +
                                                                        propertyItem.quantity +
                                                                        ")"
                                                                      : ""
                                                                  }` +
                                                                  ", "
                                                                );
                                                              } else {
                                                                return (
                                                                  propertyItem.property +
                                                                  `${
                                                                    propertyItem.quantity >
                                                                    1
                                                                      ? "(" +
                                                                        propertyItem.quantity +
                                                                        ")"
                                                                      : ""
                                                                  }`
                                                                );
                                                              }
                                                            }
                                                          )}
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="col-2 text-center d-flex">
                                                      <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                        {thisItem.quantity}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row gx-3">
                  <div className="col-md-9">
                    {returnMoneyUsd > 0 ? (
                      <div
                        className="alert alert-danger text-center"
                        style={{ height: "56px", marginTop: "16px" }}
                      >
                        Return Amount: {formatPrice(returnMoneyUsd)}
                      </div>
                    ) : (
                      <>
                        <div className="fk-settle-group d-flex t-mt-15">
                          <label
                            htmlFor="settle-total"
                            className="text-capitalize w-50 fk-settle-group__label font-weight-bold"
                          >
                            Total:
                          </label>
                          <div
                            id="settle-total"
                            className="w-50 fk-settle-group__input text-right pr-2 font-weight-bold"
                          >
                            {checkOrderDetails &&
                              checkOrderDetails.item &&
                                parseFloat(checkOrderDetails.item.total_payable) -
                                parseFloat(checkOrderDetails.item.paid_amount)
                            }
                          </div>
                        </div>
                        <div className="fk-settle-group d-flex t-mt-10 t-mb-15">
                          <label
                            htmlFor="settle-paid-amount"
                            className="text-capitalize w-50 fk-settle-group__label font-weight-bold"
                          >
                            Paid Amount:
                          </label>
                          {/*
                          <div
                            id="settle-total"
                            className="w-50 fk-settle-group__input text-right pr-2 font-weight-bold"
                          >
                            {paidMoney}
                          </div>*/}
                          <input
                          ref={inputRefs.paid}
                          type="number"
                          onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                          onWheel={(e) => e.target.blur()}  
                          id="settle-total"
                          style={{minHeight:"1rem",borderRadius:"0",color:"#121053"}}
                          className="form-control w-50 fk-settle-group__input text-right p-0 pr-2 font-weight-bold"
                          value={paidMoney > 0 ? paidMoney : ""}
                          onChange={(e) => setPaidMoney(e.target.value ? parseFloat(e.target.value) : 0)}
                         />
                        </div>
                      </>
                    )}
                    <div className="fk-settle-cal container-fluid">
                      <div className="row h-100 g-2 mt-1">
                        <div className="col-2">
                          <div className="row g-2 h-100">
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0) {
                                    setPaidMoney(paidMoney + 10);
                                  }
                                }}
                              >
                                10
                              </button>
                            </div>
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0) {
                                    setPaidMoney(paidMoney + 20);
                                  }
                                }}
                              >
                                20
                              </button>
                            </div>
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0) {
                                    setPaidMoney(paidMoney + 50);
                                  }
                                }}
                              >
                                50
                              </button>
                            </div>
                            <div className="col-12 mb-2">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!returnMoneyUsd > 0) {
                                    setPaidMoney(paidMoney + 100);
                                  }
                                }}
                              >
                                100
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="row g-2 h-100">
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 1);
                                      }
                                    }}
                                  >
                                    1
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 4);
                                      }
                                    }}
                                  >
                                    4
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 7);
                                      }
                                    }}
                                  >
                                    7
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-p t-text-white"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 500);
                                      }
                                    }}
                                  >
                                    500
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 2);
                                      }
                                    }}
                                  >
                                    2
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 5);
                                      }
                                    }}
                                  >
                                    5
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 8);
                                      }
                                    }}
                                  >
                                    8
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-p t-text-white"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 1000);
                                      }
                                    }}
                                  >
                                    1000
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 3);
                                      }
                                    }}
                                  >
                                    3
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 6);
                                      }
                                    }}
                                  >
                                    6
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        setPaidMoney(paidMoney + 9);
                                      }
                                    }}
                                  >
                                    9
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn  t-bg-d t-text-white"
                                    onClick={() => {
                                      handleClearAmount()
                                    }}
                                  >
                                    C
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      if (
                                        checkOrderDetails &&
                                        checkOrderDetails.item
                                      ) {
                                        let theP = parseFloat(
                                          formatPrice(
                                            checkOrderDetails.item
                                              .total_payable -
                                              checkOrderDetails.item.paid_amount
                                          )
                                        );
                                        setPaidMoney(theP);
                                      }
                                    }}
                                  >
                                    all
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        if (
                                          checkOrderDetails &&
                                          checkOrderDetails.item
                                        ) {
                                          let theP = formatPrice(
                                            parseFloat(
                                              (checkOrderDetails.item
                                                .total_payable -
                                                checkOrderDetails.item
                                                  .paid_amount) /
                                                2
                                            )
                                          );
                                          setPaidMoney(parseFloat(theP));
                                        }
                                      }
                                    }}
                                  >
                                    1/2
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      if (!returnMoneyUsd > 0) {
                                        if (
                                          checkOrderDetails &&
                                          checkOrderDetails.item
                                        ) {
                                          let theP = formatPrice(
                                            parseFloat(
                                              (checkOrderDetails.item
                                                .total_payable -
                                                checkOrderDetails.item
                                                  .paid_amount) /
                                                3
                                            )
                                          );
                                          setPaidMoney(parseFloat(theP));
                                        }
                                      }
                                    }}
                                  >
                                    1/3
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn bg-primary t-text-white t-bg-r text-capitalize"
                                    onClick={!loading && handleSettleOrder}
                                  >
                                    {!loading ? _t(t("settle")) : _t(t("wait"))}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex flex-column justify-content-center t-mt-15">
                      <div className="fk-settle__pay" data-simplebar>
                        <div className="row gx-3">
                          <div className="col-12">
                            {/* POS Navigation will ber here */}
                            <ul className="t-list fk-pos-nav list-group">
                              <li className="fk-pos-nav__list">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowSettle(false);
                                    setPaySelected(null); 
                                    setPayment("");
                                    setCardNumber({
                                      first: "******",
                                      middle:"****",
                                      last:""
                                    });
                                    setCheckOrderDetails({
                                      ...checkOrderDetails,
                                      card_number: ""
                                    });
                                  }}
                                  className="w-100 t-text-dark t-heading-font btn alert alert-danger font-weight-bold text-uppercase py-3 mb-3"
                                >
                                  Go back
                                </button>
                              </li>
                              {/* paymentTypes */}
                              {paymentTypeAvailable &&
                                paymentTypeAvailable.map(
                                  (groupItem, groupIndex) => {
                                    return (
                                      <li
                                        className="fk-pos-nav__list"
                                        key={groupIndex}
                                      >
                                        <button
                                          type="button"
                                          //set active or !
                                          className={`w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase py-3 ${
                                            checkOrderDetails &&
                                            checkOrderDetails.payment_type !==
                                              null &&
                                            checkOrderDetails.payment_type[0]
                                              .id === groupItem.id
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            handleSetpaymentTypeSingle(
                                              groupItem
                                            );
                                          }}
                                        >
                                          {groupItem.name}
                                        </button>
                                      </li>
                                    );
                                  }
                              )}
                              {/*split paymentTypes */}
                              {paymentTypeAvailable &&
                                 paymentTypeAvailable?.length > 1 && (
                                  <li className="mt-2" title="Clear Paid Amount for Split Payment">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setPayModal(true);
                                        setCheckOrderDetails({
                                          ...checkOrderDetails,
                                          item: {
                                            ...checkOrderDetails.item,
                                            total_payable: parseFloat(roundOffPrice(totalAmount, generalSettings)) -
                                            parseFloat(roundOffPrice(checkOrderDetails.item.paid_amount, generalSettings)),
                                          },
                                        });
                                        handleClearAmount();
                                      }}
                                      className="w-100 t-text-dark t-heading-font btn alert alert-danger font-weight-bold text-uppercase py-3 mb-3"
                                    >
                                      Split Payment
                                    </button>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*made unmade item cancel order*/}
      <ItemCancelModal
       deleteReasonModalShowing={deleteReasonModalShowing}
       handleCancelEmpty={handleCancelEmpty} 
       itemToDelete={itemToDelete}
       passcodeApplied={passcodeApplied}
       handleCancelOrderReasonSubmit={handleCancelOrderReasonSubmit}
      />

      {/* rider assign modal */}
      <RiderModal
       riderModal={riderModal}
       setRiderModal={setRiderModal}
       setCheckOrderDetails={setCheckOrderDetails}
       handleRider={handleRider}
       setDeliveryStatus={setDeliveryStatus} 
       deliveryStatus={deliveryStatus}
       deliveryStatusOption={deliveryStatusOption}
       checkOrderDetails={checkOrderDetails}
      />

      {/*split pay modal */}
      <SplitPayment
          handleClick={handleClick}
          totalsettle={checkOrderDetails.item && parseFloat(checkOrderDetails.item.total_payable) - parseFloat(checkOrderDetails.item.paid_amount)}
          payModal={payModal}
          handleSetPayment={handleSetPayment}
          paySelected={paySelected}
          paymentTypeAvailable={paymentTypeAvailable}
          payment={payment}
          handleSetpaymentTypeMuliple={handleSetpaymentTypeMuliple}
          handleSplitPayment={handleSplitPayment} 
      />

      {/* Card Modal */}
      <CardModal
        cardAdd={cardAdd}
        handleCardSubmit={handleCardSubmit}
        inputRefs={inputRefs}
        cardNumber={cardNumber}
        handleCardChange={handleCardChange}
      />
  </div>  
  );
};

export default Submitted;
