import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import { _t, getCookie ,formatPrice,getSystemSettings,vatPercent, } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
//3rd party packages
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";

//importing context consumer here
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import {SettingsContext} from "../../../../contexts/Settings";


const Kitchen = () => {
  const { t } = useTranslation();
    //getting context values here
    const {
      generalSettings,
    } = useContext(SettingsContext);

  const {
    //kitchen dashboard
    getKitchenNewOrders,
    branchForSearch,
    kithcenNewOrders,
    setKithcenNewOrders,
    kdsListForSearch,
    loading,
    setLoading,
  } = useContext(RestaurantContext);

  const {
    //food group
    foodGroupForSearch,
  } = useContext(FoodContext);

  //state hooks
  const [filterOrder, setFilterOrder] = useState({
    isFiltered: false,
    filterKey: "",
    groups: null,
  });
  //print bills
  const componentRef = useRef();
  
  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });
  const [readyOrderCounter, setReadyOrderCounter] = useState(0);
  const [checkOrderDetails,setCheckOrderDetails] = useState(null);
  const [taxType, setTaxType] = useState("");
  const orderReady = useRef();
  const refresh = useRef();

  useEffect(() => {
    //get all orders when coming to kithcen
    getKitchenNewOrders("Kitchen");

    //add "All" option to group filter
    let tempFoodGroups = [];
    foodGroupForSearch &&
      foodGroupForSearch.map((item) => {
        if (item.name !== "All") {
          tempFoodGroups.push(item);
        }
      });
    //new option added to food group
    tempFoodGroups.unshift({ name: "All" });
    setFilterOrder({
      ...filterOrder,
      groups: tempFoodGroups,
    });
  }, [foodGroupForSearch]);

  useEffect(() => {
    if (!generalSettings || !generalSettings.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);

  const showPropertyPrice = (qty, propQty, price) => {
    let propPrice = 0;
    if (taxType === "inclusive") {
      propPrice = qty * propQty * price;
      let finalPrice =
        propPrice / (1 + parseFloat(checkOrderDetails.theBranch.branch_tax) / 100);
      return formatPrice(finalPrice);
    } else {
      propPrice = qty * propQty * price;
      return formatPrice(propPrice);
    }
  };

  //show price of each item in print
  const showPriceOfEachOrderItemPrint = (thisItem) => {
    let price = 0;

    // {
    //   /* fahad comment */
    // }
    let tempPropertyPrice = 0;
    console.log(" thisItem.properties", thisItem.properties, thisItem);
    if (thisItem.properties) {
      //let propertyItems = JSON.parse(thisItem.properties);
      //console.log(" Json Properties", propertyItems);
      // propertyItems.forEach((propertyItem, thisIndex) => {
      //   let temp =
      //     propertyItem.quantity *
      //     propertyItem.price_per_qty *
      //     thisItem.quantity;
      //   tempPropertyPrice = tempPropertyPrice + temp;
      // });

      JSON.parse(thisItem.properties).map((propertyItem, thisIndex) => {
        if (thisIndex !== JSON.parse(thisItem.properties).length - 1) {
          let temp =
            propertyItem.quantity *
            propertyItem.price_per_qty *
            thisItem.quantity;
          tempPropertyPrice = tempPropertyPrice + temp;
        }
      });
    }
    price = thisItem.price - tempPropertyPrice;
    if (taxType === "inclusive") {
      let finalPrice =
        price / (1 + parseFloat(checkOrderDetails.theBranch.branch_tax) / 100);
      return formatPrice(finalPrice);
    } else {
      return formatPrice(price);
    }
  };

    //for customer print
    const handleCustomerPrint = useReactToPrint({
      content: () => componentRef.current,
    }); 

  //filter ordered items
  const handleFilter = (foodGrp) => {
    setFilterOrder({
      ...filterOrder,
      isFiltered: foodGrp.name == "All" ? false : true,
      filterKey: foodGrp.name,
    });
  };

  //search orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedOrder({ ...searchedOrder, searched: false });
    } else {
      let searchedList =
        kithcenNewOrders &&
        kithcenNewOrders.filter((item) => {
          //token
          let lowerCaseItemToken = item.token?.id.toLowerCase();

          return lowerCaseItemToken.includes(searchInput);
        });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedList,
        searched: true,
      });
    }
  };

  // kitchen bell ring
  useEffect(()=>{
    setTimeout(() => {    
      kithcenNewOrders && setReadyOrderCounter(kithcenNewOrders?.length);
    }, 200);
  },[kithcenNewOrders]);
  
  orderReady.current = readyOrderCounter;
  refresh.current = kithcenNewOrders?.length;
 
  useEffect(() => {
    const interval = setInterval(() => {
          const url = BASE_URL + "/settings/get-new-orders/Kitchen";
          setLoading(true)
          return axios
              .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
              })
              .then((res) => {
                setKithcenNewOrders(res.data[0]);
                localStorage.setItem(url, JSON.stringify(res));
                let newOrder = orderReady.current;
                if(res.data[1] === 1){
                  let beep = document.getElementById("myAudioPhoneRing");
                  beep.play();
                }
                setReadyOrderCounter(res.data[0]?.length);
                setLoading(false)
              })
           .catch((err) => {
            setLoading(false)
           })
      
      }, 97000);
      return () => {
        clearInterval(interval);
      };
    
  }, []);
  
  //accept or reject
  const handleAcceptOrReject = (id) => {
    //front end accept-reject view update
    let newState = kithcenNewOrders.map((orderItem) =>
      orderItem.id === id
        ? {
            ...orderItem,
            is_accepted: parseInt(orderItem.is_accepted) === 0 ? 1 : 0,
          }
        : orderItem
    );
    setKithcenNewOrders(newState);
    let order = kithcenNewOrders.find((orderItem)=>{
      return orderItem.id === id
    });
    let branch = branchForSearch.find((branch)=>{
      return order.branch_id === branch.id
    });
    setCheckOrderDetails({
      theBranch: {
        ...branch
      },
      ...order,
    });
 
    //front end accept-reject view update for searched
    if (searchedOrder.searched) {
      let newStateSearched = searchedOrder.list.map((orderItemSearched) =>
        orderItemSearched.id === id
          ? {
              ...orderItemSearched,
              is_accepted:
                parseInt(orderItemSearched.is_accepted) === 0 ? 1 : 0,
            }
          : orderItemSearched
      );
      setSearchedOrder({
        ...searchedOrder,
        list: newStateSearched,
      });
    }

    //set on server
    const url = BASE_URL + "/settings/kitchen-order-status";
    let formData = {
      id,
      status: "KA"
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {
        // handleCustomerPrint();
        setTimeout(() => {
          setCheckOrderDetails(null)
        }, 200);
      })
      .catch(() => {
        //undo if any error happened
        newState = newState.map((orderItem) =>
          orderItem.id === id
            ? {
                ...orderItem,
                is_accepted: parseInt(orderItem.is_accepted) === 0 ? 1 : 0,
              }
            : orderItem
        );
        setKithcenNewOrders(newState);
        setCheckOrderDetails(null)
        //undo if any error happened for searched
        if (searchedOrder.searched) {
          let newStateSearched = searchedOrder.list.map((orderItemSearched) =>
            orderItemSearched.id === id
              ? {
                  ...orderItemSearched,
                  is_accepted:
                    parseInt(orderItemSearched.is_accepted) === 0 ? 1 : 0,
                }
              : orderItemSearched
          );
          setSearchedOrder({
            ...searchedOrder,
            list: newStateSearched,
          });
        }
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //delete confirmation modal of waiter
  const handleReadyConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("All items are cooked?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleReady(id);
                  onClose();
                }}
              >
                {_t(t("YES, COOKED!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("NO"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  //make the order group ready here
  const handleReady = (id) => {
    setLoading(true);
    // const url = BASE_URL + "/settings/mark-all-items-ready";
    const url = BASE_URL + "/settings/kitchen-order-status";
    let formData = {
      id,
      status:"KR"
    };
  
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {
        //remove ready item from order list
        getKitchenNewOrders("Kitchen"); 
        let newState = kithcenNewOrders.filter((orderItem) => {
          return orderItem.id !== id;
        });
        setReadyOrderCounter(newState?.length);
        //remove ready item from search list
        if (searchedOrder.searched) {
          setSearchedOrder({ ...searchedOrder,list: newState, searched: false })
        }
        setLoading(false);
        setTimeout(() => {       
          setCheckOrderDetails(null)
        }, 200);
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setCheckOrderDetails(null)

      });
  };

  //make the order group ready here
  const handleEachItemReady = (orderGroupId, itemId) => {
    //to redo the action
    let oldState = kithcenNewOrders;
    let oldSearchedState = searchedOrder.list;

    //new state
    let orderGroup = kithcenNewOrders.find((orderItem) => {
      return orderItem.id === orderGroupId;
    });

    //updating the item's cooking status
    let newItems = orderGroup.orderedItems.map((eachItem) =>
      eachItem.id === itemId
        ? {
            ...eachItem,
            is_cooking: parseInt(eachItem.is_cooking) === 0 ? 1 : 0,
          }
        : eachItem
    );

    //set updated order list with item's status change
    let newState = kithcenNewOrders.map((orderItem) =>
      orderItem.id === orderGroupId
        ? { ...orderItem, is_accepted: 1, orderedItems: newItems }
        : orderItem
    );
    setKithcenNewOrders(newState);

    //searched list update
    if (searchedOrder.searched) {
      //new searched state
      let orderGroup = searchedOrder.list.find((orderItem) => {
        return orderItem.id === orderGroupId;
      });

      let newItemsSearched = orderGroup.orderedItems.map((eachItem) =>
        eachItem.id === itemId
          ? {
              ...eachItem,
              is_cooking: parseInt(eachItem.is_cooking) === 0 ? 1 : 0,
            }
          : eachItem
      );

      let newStateSearched = searchedOrder.list.map((orderItem) =>
        orderItem.id === orderGroupId
          ? { ...orderItem, is_accepted: 1, orderedItems: newItemsSearched }
          : orderItem
      );
      setSearchedOrder({
        ...searchedOrder,
        list: newStateSearched,
      });
    }

    //set server's item status
    const url = BASE_URL + "/settings/mark-order-item-ready";
    let formData = {
      orderGroupId: orderGroupId,
      id: itemId,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {})
      .catch(() => {
        //undo if any error occured
        setKithcenNewOrders(oldState);
        setSearchedOrder({
          ...searchedOrder,
          list: oldSearchedState,
        });
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Kitchen"))}</title>
      </Helmet>
      <main id="main" data-simplebar>
        <div className="fk-scroll--index t-mt-15 t-mb-15" data-simplebar>
          <div className="container-fluid">
            <div className="t-bg-white t-pt-10 t-pb-10 t-pl-15 t-pr-15">
              {/* next page data spin loading */}
              <div className={`${(loading) && "loadingBlur"}`}></div>
                  <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              {/* spin loading ends */}
              <div className="row gx-2 align-items-center">
                <div className="col-md-3 t-mb-15 mb-md-0">
                  <ul className="t-list fk-breadcrumb">
                    <li className="fk-breadcrumb__list">
                      <span className="t-link fk-breadcrumb__link text-uppercase">
                        <span className="mr-2">
                          <img
                            src="/assets/img/cooking.png"
                            alt="cooking"
                            className="img-fluid"
                            style={{ height: "40px", width: "40px" }}
                          />
                        </span>
                        <span className="mr-1">{_t(t("kitchen"))}</span>{" "}
                        {_t(t("dashboard"))}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 rounded">
                  <NavLink
                    to="/dashboard/kitchen/online"
                    onClick={() => {
                      getKitchenNewOrders("Kitchen");
                      setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                      });
                    }}
                    className="btn btn-secondary btn-block sm-text text-uppercase mb-2 mb-md-0 text-truncate rounded"
                  >
                    {_t(t("Online Orders"))}
                  </NavLink>
                </div>
                <div className="col-md-2 rounded">
                  <button
                    type="button"
                    onClick={() => {
                      const promiseThen = new Promise((resolve, reject) => {
                        setTimeout(() => {
                          resolve(getKitchenNewOrders("Kitchen"));
                        }, 100);
                    });
                    
                    promiseThen
                        .then(() => {
                          let newOrder = orderReady.current ;
                          let refreshOrder = refresh.current;
                            if(newOrder < refreshOrder){
                              let beep = document.getElementById("myAudioPhoneRing");
                              beep.play();
                            }
                            setReadyOrderCounter(refreshOrder);
                        })
                        .catch((err) => console.log(err));
                     
                      setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                      });
                     
                    }}
                    className="btn btn-primary btn-block sm-text text-uppercase mb-2 mb-md-0 text-truncate rounded"
                  >
                    {_t(t("Refresh"))}
                  </button>
                </div>
                <div className="col-md-2">
                  <Select
                    options={filterOrder.groups && filterOrder.groups}
                    components={makeAnimated()}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    classNamePrefix="select"
                    className="xsm-text mb-2 mb-md-0 "
                    onChange={handleFilter}
                    maxMenuHeight="200px"
                    placeholder={_t(t("Filter by group")) + ".."}
                  />
                </div>

                <div className="col-md-3">
                  <div className="input-group">
                    <div className="form-file">
                      <input
                        type="text"
                        className="form-control border-0 form-control--light-1 rounded-left"
                        placeholder={_t(t("Search by token")) + ".."}
                        onChange={handleSearch}
                      />
                    </div>
                    <button className="btn btn-primary rounded-right" type="button">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {!searchedOrder.searched ? (
              <div className="row no-gutters g-4 mt-1">
                {kithcenNewOrders ? (
                  [
                    kithcenNewOrders.length > 0 ? (
                      kithcenNewOrders.map((item, index) => {
                        const kdsTimeColor = kdsListForSearch.find((item)=> item.name === "Kitchen");
                        let bgColor = "";   
                        const dateTimeAgo = item.token?.time;
                        // Calculate the Unix timestamp for time1 minutes ago
                        const fiveMinutesAgoTimestamp = moment().subtract(kdsTimeColor?.time_1, 'minutes').valueOf();
                        // Calculate the Unix timestamp for time2 minutes ago
                        const tenMinutesAgoTimestamp = moment().subtract(kdsTimeColor?.time_2, 'minutes').valueOf();
                        if (dateTimeAgo > fiveMinutesAgoTimestamp) {
                            bgColor = kdsTimeColor.time_color_1
                          } else if (dateTimeAgo > tenMinutesAgoTimestamp) {
                            bgColor = kdsTimeColor.time_color_2
                         }else if(kdsTimeColor?.time_1 && kdsTimeColor?.time_2){
                            bgColor="#fa4851"
                         }
                        return (
                          <div
                            className="col-md-6 col-xl-4"
                            data-category={index + 1}
                          >
                            <div className="fk-order-token kitchen_order t-bg-white p-3 h-100">
                              <div className="fk-order-token__footer d-flex align-items-center justify-content-between">
                                { item.order_type_name ?
                                  <div className="text-capitalize">{item.order_type_name}</div>
                                  : ""
                                }
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-success xsm-text text-uppercase btn-lg mr-2 rounded"
                                    onClick={() =>
                                      handleReadyConfirmation(item.id)
                                    }
                                  >
                                    {_t(t("Order ready"))}
                                  </button>
                                  {parseInt(item.is_accepted) === 0 ? (
                                    <button
                                      type="button"
                                      className="btn btn-secondary xsm-text text-uppercase btn-lg rounded"
                                      onClick={() =>
                                        handleAcceptOrReject(item.id)
                                      }
                                    >
                                      {_t(t("Accept order"))}
                                    </button>
                                  ) : (
                                    // <button
                                    //   type="button"
                                    //   className="btn btn-primary xsm-text text-uppercase btn-lg"
                                    //   onClick={() =>
                                    //     handleAcceptOrReject(item.id)
                                    //   }
                                    // >
                                    //   {_t(t("Make order pending"))}
                                    // </button> 
                                    null
                                  )}
                                </div>  
                              </div>
                              <div className="fk-order-token__body">
                                <div className="fk-addons-table">
                                  <div className="fk-addons-table__head d-flex justify-content-between px-3" style={{backgroundColor: bgColor}}>
                                    <span>
                                      {_t(t("order token"))}: #{item.token?.id}
                                    </span>
                                    <div className="d-flex flex-column justify-content-end align-items-end">
                                      <div>
                                        {_t(t("ordered at"))}:{" "}
                                        <Moment format="LT">
                                          {item.token?.time}
                                        </Moment>
                                      </div>
                                      <div style={{fontSize:"12px"}}>
                                        <Moment fromNow>
                                            {item.token?.time}
                                        </Moment>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__info">
                                    <div className="row g-0">
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("S/L"))}
                                        </span>
                                      </div>
                                      <div className="col-3 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("food"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("Additional Info"))}
                                        </span>
                                      </div>
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("QTY"))}
                                        </span>
                                      </div>
                                      <div className="col-1 text-center py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          <i className="fa fa-check"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__body">
                                    {item.orderedItems.map(
                                      (thisItem, indexThisItem) => {
                                        if (filterOrder.isFiltered) {
                                          if (
                                            thisItem.food_group ===
                                            filterOrder.filterKey
                                          ) {
                                            return (
                                              <div class="fk-addons-table__body-row">
                                                <div class="row g-0">
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {indexThisItem + 1}
                                                    </span>
                                                  </div>
                                                  <div class="col-3 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.food_item} (
                                                      {thisItem.food_group})
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right px-2 py-2">
                                                    {thisItem.variation !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("variation"))}:
                                                        </span>
                                                        {thisItem.variation}
                                                      </span>
                                                    )}

                                                    {thisItem.properties !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("properties"))}:
                                                        </span>
                                                        {JSON.parse(
                                                          thisItem.properties
                                                        ).map(
                                                          (
                                                            propertyItem,
                                                            thisIndex
                                                          ) => {
                                                            if (
                                                              thisIndex !==
                                                              JSON.parse(
                                                                thisItem.properties
                                                              ).length -
                                                                1
                                                            ) {
                                                              return (
                                                                propertyItem.property +
                                                                ", "
                                                              );
                                                            } else {
                                                              return propertyItem.property;
                                                            }
                                                          }
                                                        )}
                                                      </span>
                                                    )}
                                                    {thisItem.comments !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("instruction"))}:
                                                        </span>
                                                        {thisItem?.comments}
                                                      </span>
                                                    )}
                                                    
                                                  </div>
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.quantity}
                                                    </span>
                                                  </div>

                                                  <div className="col-1 text-center d-flex py-2">
                                                    <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                      <input
                                                        type="checkbox"
                                                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                        checked={
                                                          parseInt(
                                                            thisItem.is_cooking
                                                          ) === 1
                                                        }
                                                        onChange={() => {
                                                          handleEachItemReady(
                                                            item.id,
                                                            thisItem.id
                                                          );
                                                        }}
                                                      />
                                                      <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        } else {
                                          return (
                                            <div class="fk-addons-table__body-row">
                                              <div class="row g-0">
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {indexThisItem + 1}
                                                  </span>
                                                </div>
                                                <div class="col-3 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.food_item} (
                                                    {thisItem.food_group})
                                                  </span>
                                                </div>
                                                <div class="col-4 text-center border-right px-2 py-2">
                                                  {thisItem.variation !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("variation"))}:
                                                      </span>
                                                      {thisItem.variation}
                                                    </span>
                                                  )}

                                                  {thisItem.properties !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("properties"))}:
                                                      </span>
                                                      {JSON.parse(
                                                        thisItem.properties
                                                      ).map(
                                                        (
                                                          propertyItem,
                                                          thisIndex
                                                        ) => {
                                                          if (
                                                            thisIndex !==
                                                            JSON.parse(
                                                              thisItem.properties
                                                            ).length -
                                                              1
                                                          ) {
                                                            return (
                                                              propertyItem.property +
                                                              ", "
                                                            );
                                                          } else {
                                                            return propertyItem.property;
                                                          }
                                                        }
                                                      )}
                                                    </span>
                                                  )}
                                                  {thisItem.comments !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("instruction"))}:
                                                      </span>
                                                      {thisItem?.comments}
                                                    </span>
                                                  )}
                                                </div>
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.quantity}
                                                  </span>
                                                </div>

                                                <div className="col-1 text-center d-flex py-2">
                                                  <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                    <input
                                                      type="checkbox"
                                                      className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                      checked={
                                                        parseInt(
                                                          thisItem.is_cooking
                                                        ) === 1
                                                      }
                                                      onChange={() => {
                                                        handleEachItemReady(
                                                          item.id,
                                                          thisItem.id
                                                        );
                                                      }}
                                                    />
                                                    <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-8 offset-2" data-category="1">
                        <div className="fk-order-token t-bg-white p-5 text-center text-uppercase text-primary no-order">
                          {/* No order in the kitchen */}
                          <img
                            src="/assets/img/no-order.jpg"
                            alt="no order found"
                            className="img-fluid h-100"
                          />
                        </div>
                      </div>
                    ),
                  ]
                ) : (
                  <div className="col-12" data-category="1">
                    <Skeleton
                      className="fk-order-token t-bg-white p-3 border border-2"
                      style={{ minHeight: "560px" }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="row no-gutters g-4 mt-1">
                {searchedOrder ? (
                  [
                    searchedOrder.list.length > 0 ? (
                      searchedOrder.list.map((item, index) => {
                        return (
                          <div
                            className="col-md-6 col-xl-4"
                            data-category={index + 1}
                          >
                            <div className="fk-order-token kitchen_order t-bg-white p-3 h-100">
                              <div className="fk-order-token__footer d-flex align-items-center justify-content-between">
                                { item.order_type_name ?
                                  <div className="text-capitalize">{item.order_type_name}</div>
                                  : ""
                                }
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-success xsm-text text-uppercase btn-lg mr-2 rounded"
                                    onClick={() =>
                                      handleReadyConfirmation(item.id)
                                    }
                                  >
                                    {_t(t("Order ready"))}
                                  </button>
                                  {parseInt(item.is_accepted) === 0 ? (
                                    <button
                                      type="button"
                                      className="btn btn-secondary xsm-text text-uppercase btn-lg rounded"
                                      onClick={() =>
                                        handleAcceptOrReject(item.id)
                                      }
                                    >
                                      {_t(t("Accept order"))}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-primary xsm-text text-uppercase btn-lg"
                                      onClick={() =>
                                        handleAcceptOrReject(item.id)
                                      }
                                    >
                                      {_t(t("Make order pending"))}
                                    </button>
                                  )}
                                </div> 
                              </div>
                              <div className="fk-order-token__body">
                                <div className="fk-addons-table">
                                  <div className="fk-addons-table__head d-flex justify-content-between px-3">
                                    <span>
                                      {_t(t("order token"))}: #{item.token?.id}
                                    </span>
                                    <span>
                                      {_t(t("ordered at"))}:{" "}
                                      <Moment format="LT">
                                        {item.token?.time}
                                      </Moment>
                                    </span>
                                  </div>
                                  <div className="fk-addons-table__info">
                                    <div className="row g-0">
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("S/L"))}
                                        </span>
                                      </div>
                                      <div className="col-3 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("food"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("Additional Info"))}
                                        </span>
                                      </div>
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("QTY"))}
                                        </span>
                                      </div>
                                      <div className="col-1 text-center py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          <i className="fa fa-check"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__body">
                                    {item.orderedItems.map(
                                      (thisItem, indexThisItem) => {
                                        if (filterOrder.isFiltered) {
                                          if (
                                            thisItem.food_group ===
                                            filterOrder.filterKey
                                          ) {
                                            return (
                                              <div class="fk-addons-table__body-row">
                                                <div class="row g-0">
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {indexThisItem + 1}
                                                    </span>
                                                  </div>
                                                  <div class="col-3 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.food_item} (
                                                      {thisItem.food_group})
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right px-2 py-2">
                                                    {thisItem.variation !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("variation"))}:
                                                        </span>
                                                        {thisItem.variation}
                                                      </span>
                                                    )}

                                                    {thisItem.properties !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("properties"))}:
                                                        </span>
                                                        {JSON.parse(
                                                          thisItem.properties
                                                        ).map(
                                                          (
                                                            propertyItem,
                                                            thisIndex
                                                          ) => {
                                                            if (
                                                              thisIndex !==
                                                              JSON.parse(
                                                                thisItem.properties
                                                              ).length -
                                                                1
                                                            ) {
                                                              return (
                                                                propertyItem.property +
                                                                ", "
                                                              );
                                                            } else {
                                                              return propertyItem.property;
                                                            }
                                                          }
                                                        )}
                                                      </span>
                                                    )}
                                                    {thisItem.comments !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("instruction"))}:
                                                        </span>
                                                        {thisItem.comments}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.quantity}
                                                    </span>
                                                  </div>

                                                  <div className="col-1 text-center d-flex py-2">
                                                    <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                      <input
                                                        type="checkbox"
                                                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                        checked={
                                                          parseInt(
                                                            thisItem.is_cooking
                                                          ) === 1
                                                        }
                                                        onChange={() => {
                                                          handleEachItemReady(
                                                            item.id,
                                                            thisItem.id
                                                          );
                                                        }}
                                                      />
                                                      <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        } else {
                                          return (
                                            <div class="fk-addons-table__body-row">
                                              <div class="row g-0">
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {indexThisItem + 1}
                                                  </span>
                                                </div>
                                                <div class="col-3 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.food_item} (
                                                    {thisItem.food_group})
                                                  </span>
                                                </div>
                                                <div class="col-4 text-center border-right px-2 py-2">
                                                  {thisItem.variation !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("variation"))}:
                                                      </span>
                                                      {thisItem.variation}
                                                    </span>
                                                  )}

                                                  {thisItem.properties !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("properties"))}:
                                                      </span>
                                                      {JSON.parse(
                                                        thisItem.properties
                                                      ).map(
                                                        (
                                                          propertyItem,
                                                          thisIndex
                                                        ) => {
                                                          if (
                                                            thisIndex !==
                                                            JSON.parse(
                                                              thisItem.properties
                                                            ).length -
                                                              1
                                                          ) {
                                                            return (
                                                              propertyItem.property +
                                                              ", "
                                                            );
                                                          } else {
                                                            return propertyItem.property;
                                                          }
                                                        }
                                                      )}
                                                    </span>
                                                  )}
                                                  {thisItem.comments !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("instruction"))}:
                                                      </span>
                                                      {thisItem.comments}
                                                    </span>
                                                  )}
                                                </div>
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.quantity}
                                                  </span>
                                                </div>

                                                <div className="col-1 text-center d-flex py-2">
                                                  <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                    <input
                                                      type="checkbox"
                                                      className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                      checked={
                                                        parseInt(
                                                          thisItem.is_cooking
                                                        ) === 1
                                                      }
                                                      onChange={() => {
                                                        handleEachItemReady(
                                                          item.id,
                                                          thisItem.id
                                                        );
                                                      }}
                                                    />
                                                    <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-8 offset-2" data-category="1">
                        <div className="fk-order-token t-bg-white p-5 text-center text-uppercase text-primary no-order">
                          {/* No order in the kitchen */}
                          <img
                            src="/assets/img/no-order.jpg"
                            alt="no order found"
                            className="img-fluid h-100"
                          />
                        </div>
                      </div>
                    ),
                  ]
                ) : (
                  <div className="col-12" data-category="1">
                    <Skeleton
                      className="fk-order-token t-bg-white p-3 border border-2"
                      style={{ minHeight: "560px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

         {/* Print bill */}
      <div className="d-none">
      <div ref={componentRef}>
        {checkOrderDetails && (
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mx-auto" style={{ width: "120px" }}>
                    <img
                      src={`${getSystemSettings(
                        generalSettings,
                        "type_logo"
                      )}`}
                      style={{ width: "100%" }}
                      // height={100}
                    />
                  </div>
                  <span className="d-block fk-print-text font-weight-bold text-uppercase text-center sm-text">
                    {getSystemSettings(generalSettings, "siteName")}
                    {","}
                    {checkOrderDetails.branch_name}
                  </span>
                  <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                    {checkOrderDetails?.theBranch !== null &&
                    checkOrderDetails.theBranch.address
                      }
                  </p>
                  <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                    {_t(t("call"))}:{" "}
                    {checkOrderDetails?.theBranch !== null &&
                       checkOrderDetails.theBranch.phn_no
                    }
                  </p>
                  <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                    {getSystemSettings(generalSettings, "type_print_heading")}
                  </p>
                  <span className="d-block fk-print-text text-uppercase text-center lg-text myBorderTopCustomer">
                    {_t(t("Token No"))}-{checkOrderDetails?.token?.id}
                  </span>

                  <p className="mb-0 mt-0 sm-text fk-print-text text-capitalize text-center">
                  {_t(t("Customer Copy"))}
                 </p>
                 
                 <br></br>
                 <p className="mb-0 md-text fk-print-text text-capitalize">
                   {getSystemSettings(
                     generalSettings,
                     "pre_settled_billed_heading"
                   )}
                 </p>
                  <p className="mb-0 sm-text fk-print-text text-capitalize">
                    {_t(t("date"))}:{" "}
                    <Moment format="LL">
                      {checkOrderDetails?.token.time}
                    </Moment>
                  </p>

                  <p className="mb-0 sm-text fk-print-text text-capitalize">
                  {_t(t("Total guests"))}:{" "}
                  {checkOrderDetails && checkOrderDetails?.total_guest}
                </p>

                {checkOrderDetails && checkOrderDetails.waiter_name !== null ? (
                  <p className="mb-0 xsm-text fk-print-text text-capitalize">
                    {_t(t("waiter name"))}: {checkOrderDetails?.waiter_name}
                  </p>
                ) : (
                  ""
                )}
                <br></br>
                {checkOrderDetails.is_settled === 1 ? (
                  <p className="mb-0 sm-text fk-print-text text-capitalize lg-text">
                    {checkOrderDetails.bill_distribution?.payment_type &&
                      checkOrderDetails.bill_distribution?.payment_type}{" "}
                    - PAID
                  </p>
                ) : (
                  <p className="mb-0 sm-text fk-print-text text-capitalize lg-text">
                    Un PAID
                  </p>
                )}

                  <table className="table mb-0 table-borderless akash-table-for-print-padding">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="fk-print-text sm-text text-capitalize"
                        >
                          {_t(t("qty"))} {_t(t("item"))}
                        </th>
                        <th
                          scope="col"
                          className="fk-print-text sm-text text-capitalize text-right"
                        >
                          {_t(t("T"))}.{_t(t("price"))}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkOrderDetails &&
                        checkOrderDetails.orderedItems.map(
                          (thisItem, indexThisItem) => {
                            return (
                              <tr>
                                <td className="fk-print-text xsm-text text-capitalize">
                                  <div className="d-flex flex-wrap">
                                    <span className="d-inline-block sm-text fk-print-text--bold">
                                      -{thisItem.quantity}{" "}
                                      {thisItem.food_item}
                                      {thisItem.variation !== null &&
                                        "(" + thisItem.variation + ")"}
                                    </span>
                                  </div>

                                  {thisItem.properties !== "" && (
                                    <div className="d-block">
                                      {JSON.parse(thisItem.properties).map(
                                        (propertyItem, thisIndex) => {
                                          if (
                                            thisIndex !==
                                            JSON.parse(thisItem.properties)
                                              .length -
                                              1
                                          ) {
                                            return (
                                              <div
                                                className="d-block text-capitalize sm-text"
                                              >
                                                <span className="text-capitalize sm-text d-inline-block fk-print-text--bold mr-1">
                                                  -{thisItem.quantity}
                                                  {propertyItem.quantity > 1
                                                    ? "*" +
                                                      propertyItem.quantity
                                                    : ""}{" "}
                                                  {propertyItem.property}
                                                </span>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="d-block text-capitalize sm-text"
                                              >
                                                <span className="text-capitalize sm-text d-inline-block mr-1 fk-print-text--bold">
                                                  -{thisItem.quantity}
                                                  {propertyItem.quantity > 1
                                                    ? "*" +
                                                      propertyItem.quantity
                                                    : ""}{" "}
                                                  {propertyItem.property}
                                                </span>{" "}
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  )}

                                  {/* properties */}
                                  {/* fahad comment */}
                                  {/* {thisItem.properties !== null && (
                                    <div className="d-block">
                                      {JSON.parse(thisItem.properties).map(
                                        (propertyItem, thisIndex) => {
                                          if (
                                            thisIndex !==
                                            JSON.parse(thisItem.properties)
                                              .length -
                                              1
                                          ) {
                                            return (
                                              <span className="text-capitalize xsm-text d-inline-block mr-1">
                                                -{thisItem.quantity}
                                                {propertyItem.quantity > 1
                                                  ? "*" +
                                                    propertyItem.quantity
                                                  : ""}{" "}
                                                {propertyItem.property}
                                              </span>
                                            );
                                          } else {

                                          }
                                        }
                                      )}
                                    </div>
                                  )} */}
                                </td>
                                <td className="fk-print-text sm-text text-capitalize text-right fk-print-text--bold">
                                  <div className="d-block xsm-text">
                                    {showPriceOfEachOrderItemPrint(thisItem)}
                                  </div>
                                  {thisItem.properties !== "" && (
                                    <div className="d-block">
                                      {JSON.parse(thisItem.properties).map(
                                        (propertyItem, thisIndex) => {
                                            return (
                                              <div
                                                className="d-block text-capitalize sm-text"
                                              >
                                                <span>
                                                  {showPropertyPrice(
                                                    thisItem.quantity,
                                                    propertyItem.quantity,
                                                    propertyItem.price_per_qty
                                                  )}
                                                  <br />
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    </div>
                                  )}

                                  {/* properties */}
                                  {/* fahad comment */}
                                  {/* {thisItem.properties !== null && (
                                    <div className="d-block">
                                      {JSON.parse(thisItem.properties).map(
                                        (propertyItem, thisIndex) => {
                                          return (
                                            <div
                                              className="d-block"
                                              className={`text-capitalize xsm-text`}
                                            >
                                              <span>
                                                {formatPrice(
                                                  thisItem.quantity *
                                                    propertyItem.quantity *
                                                    propertyItem.price_per_qty
                                                )}
                                                <br />
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )} */}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>

                  <div className="myBorder"></div>
                  <table className="table mb-0 table-borderless">
                    <tbody>
                      <tr>
                        <th className="fk-print-text xsm-text text-capitalize fk-print-text--bold">
                          <span className="d-block">{_t(t("total"))}</span>
                        </th>
                        <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                          {formatPrice(checkOrderDetails.order_bill)}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {parseFloat(checkOrderDetails.vat) > 0 && (
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        {checkOrderDetails.vat_system === "igst" ? (
                          <tr>
                            <th className="fk-print-text xsm-text">
                              {/* <span className="d-block xsm-text">IGST</span> */}
                              <span className="d-block xsm-text fk-print-text--bold">
                              VAT
                              {checkOrderDetails && `(${vatPercent(checkOrderDetails.order_bill,checkOrderDetails.vat)}%)`}
                              </span>
                            </th>
                            <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                              {formatPrice(checkOrderDetails.vat)}
                            </td>
                          </tr>
                        ) : (
                          <>
                            <tr>
                              <th className="fk-print-text xsm-text">
                                <span className="d-block xsm-text">CGST</span>
                              </th>
                              <td className="fk-print-text xsm-text text-capitalize text-right">
                                {formatPrice(parseFloat(0))}
                              </td>
                            </tr>
                            <tr>
                              <th className="fk-print-text xsm-text">
                                <span className="d-block xsm-text">SGST</span>
                              </th>
                              <td className="fk-print-text xsm-text text-capitalize text-right">
                                {formatPrice(parseFloat(0))}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  )}
                  
                  {parseFloat(checkOrderDetails.service_amount) > 0 ? (
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="fk-print-text xsm-text">
                            <span className="d-block xsm-text">
                              Service Charges
                            </span>
                          </th>
                          <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                            {formatPrice(
                              parseFloat(
                                checkOrderDetails.service_amount
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : null}
                  {parseFloat(checkOrderDetails.service_charge) > 0 ? (
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="fk-print-text xsm-text">
                            <span className="d-block xsm-text">
                              Delivery Charges
                            </span>
                          </th>
                          <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                            {formatPrice(
                              parseFloat(
                                checkOrderDetails.service_charge
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : null}
                  {parseFloat(checkOrderDetails.discount) >
                    0 ||
                  parseFloat(checkOrderDetails.discount_amount) > 0 ? (
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="fk-print-text xsm-text text-capitalize">
                            <span className="d-block fk-print-text--bold">
                              {_t(t("discount"))}
                              {checkOrderDetails &&
                                checkOrderDetails.discount !== null &&
                                `(${parseFloat(
                                  checkOrderDetails.discount
                                )}%)`}
                            </span>
                          </th>
                          <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                            {formatPrice(
                              checkOrderDetails.discount_amount
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : null}

                  <div className="myBorder"></div>
                  <table className="table mb-0 table-borderless">
                    <tbody>
                    {checkOrderDetails?.theBranch?.fbr_integration === 1 &&
                      parseFloat(checkOrderDetails.paid_amount) > 0 && checkOrderDetails.fbr_pos_invoice_no && (
                        <tr>
                          <th className="fk-print-text xsm-text text-capitalize fk-print-text--bold">
                            <span className="d-block">
                              {_t(t("PoS Service Fee"))}
                            </span>
                          </th>
                          <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                            {_t(t("1.00"))}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th className="fk-print-text xsm-text text-capitalize fk-print-text--bold">
                          <span className="d-block">
                            {_t(t("grand total"))}
                          </span>
                        </th>
                        <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                          {
                           parseFloat(checkOrderDetails.paid_amount) > 0 && checkOrderDetails?.theBranch?.fbr_integration && checkOrderDetails.fbr_pos_invoice_no
                            ? formatPrice(checkOrderDetails.total_payable + 1)
                            :
                            formatPrice(checkOrderDetails.total_payable)
                          }
                        </td>
                      </tr>
                      <tr>
                      <th className="fk-print-text xsm-text text-capitalize fk-print-text--bold">
                        <span className="d-block">
                          {_t(t("Paid Amount"))}
                        </span>
                      </th>
                      <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                        {formatPrice(checkOrderDetails.paid_amount)}
                      </td>
                    </tr>
                    <tr>
                      <th className="fk-print-text xsm-text text-capitalize fk-print-text--bold">
                        <span className="d-block">
                          {_t(t("Return Amount"))}
                        </span>
                      </th>
                      <td className="fk-print-text xsm-text text-capitalize text-right fk-print-text--bold">
                        {formatPrice(checkOrderDetails.return_amount)}
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  {checkOrderDetails.theBranch.fbr_integration === 1 &&
                    (checkOrderDetails.fbr_pos_invoice_no !== "" && checkOrderDetails.fbr_pos_invoice_no !== null) &&
                    parseFloat(checkOrderDetails.paid_amount) > 0 && (
                      <div className="mb-1">
                        <p className="xsm-text fk-print-text text-capitalize text-center">
                          FBR Invoice Number {checkOrderDetails.fbr_pos_invoice_no}
                        </p>
                        <div className="mb-1">
                          <div className="float-left">
                            <img
                              src="/assets/img/pos-invoice-system.png"
                              height="60"
                              alt="FBR Invoice"
                            />
                          </div>
                          <div className="float-right">
                            {checkOrderDetails.fbr_pos_invoice_no && (
                              <QRCode value={checkOrderDetails.fbr_pos_invoice_no} size="60" />
                            )}
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                        <p className="xsm-text fk-print-text text-capitalize text-center">
                          Verify this invoice through FBR TaxAsaan MobileApp
                          or SMS at 9966 <br></br> and win exciting prizes in
                          draw.
                        </p>
                      </div>
                    )}
                  <div className="myBorder"></div>
                  <p></p>
                  {checkOrderDetails?.order_type_name !== "Dine-In" ? (
                    <>
                      <p className="mb-1 xsm-text fk-print-text text-capitalize">
                        {_t(t("Customer Name"))}:{" "}
                        {checkOrderDetails && checkOrderDetails.customer_name !== null
                          && checkOrderDetails.customer_name}
                      </p>
                      <p className="mb-1 xsm-text fk-print-text text-capitalize">
                        {_t(t("Phone #"))}:{" "}
                        {checkOrderDetails && checkOrderDetails.delivery_phn_no !== null
                          && checkOrderDetails?.delivery_phn_no
                          }
                      </p>
                     
                      <p className="mb-0 xsm-text fk-print-text text-capitalize">
                        {_t(t("Address "))}:{" "}
                        {checkOrderDetails && checkOrderDetails.delivery_address !== null
                          && checkOrderDetails?.delivery_address
                          }
                      </p>
                      <p></p>
                      <p className="mb-0 xsm-text fk-print-text text-capitalize">
                        {checkOrderDetails &&
                          checkOrderDetails?.party_name +
                            " - ref no : " +
                            (checkOrderDetails && 
                             checkOrderDetails.ref_no ? checkOrderDetails?.ref_no : "")}
                      </p>
                      <p></p>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="myBorder"></div>
                  {checkOrderDetails?.theBranch?.sbr_integration === 1 &&
                    (checkOrderDetails.srb_pos_invoice_no !== "" && checkOrderDetails.srb_pos_invoice_no !== null) &&
                     parseFloat(checkOrderDetails.paid_amount) > 0 && (
                      <div className="mb-1">
                        <p className="xsm-text fk-print-text text-capitalize text-center">
                          Invoice Number {checkOrderDetails.srb_pos_invoice_no}
                        </p>
                        <div className="mb-1">
                          <div className="float-left">
                            <img
                              src="/assets/img/srb_pos_image.png"
                              height="100"
                              alt="SRB Invoice"
                            />
                          </div>
                          <div className="float-right">
                            {
                              checkOrderDetails.srb_pos_invoice_no && (
                                  <QRCode value={checkOrderDetails.srb_pos_invoice_no} size="100" />
                                )
                            }
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                        <p className="xsm-text fk-print-text text-capitalize text-center">
                          Verify this invoice through MobileApp or SMS at 9966{" "}
                          <br></br> and win exciting prizes in draw.
                        </p>
                      </div>
                    )}

                  <div className="myBorder"></div>
                  <p className="mb-0 xsm-text fk-print-text text-center text-capitalize">
                    {getSystemSettings(generalSettings, "type_print_footer")}
                  </p>
                  <br></br>
                  <p className="mb-0 xsm-text fk-print-text text-capitalize text-center">
                    {_t(t(""))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
          {/* beep sound */}
       <audio id="myAudioPhoneRing">
          <source src="/assets/beep/notify.mp3" type="audio/mpeg" />
        </audio>

      <audio id="myAudioNew">
        <source src="/assets/beep/beep2.mp3" type="audio/mpeg" />
      </audio>

      </main>
    </>
  );
};

export default Kitchen;
