import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";

const ViewRecipe = () => {
  const { t } = useTranslation();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //recipes
    recipeList,
    setRecipeList,
    setPaginatedRecipeList,
    recipeForSearch,
    setRecipeForSearch,
    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);
  let {
    foodForPOS,
    propertyItemList,
  } = useContext(FoodContext);

  // States hook here
  //new customer
  //search result
  let [searchedRecipe, setSearchedRecipe] = useState({
    list: null,
    searched: false,
  });

  let [itemMapping,setItemMapping] = useState({
    id: null,
    item: null,
    property: null,
    uploading:false,
    modal: false,
  });

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    ///.....
  }
  
  //search purchase here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedRecipe({ ...searchedRecipe, searched: false });
    } else {
      let searchedList = recipeForSearch?.filter((item) => {
        //name
        let lowerCaseRecipeName = item.item_name.toLowerCase();

        return (
          lowerCaseRecipeName.includes(searchInput)
        );
      });
      setSearchedRecipe({
        ...searchedRecipe,
        list: searchedList,
        searched: true,
      });
    }
  };

  const handleItemChange = (item) => {
    setItemMapping({
      ...itemMapping,
      item: item,
    })
  }

  const handlePropertyChange = (property) =>{
    setItemMapping({
      ...itemMapping,
      property: property,
    })
  }

  // item mapping in recipe
  const handleSaveRecipe = (e) =>{
    e.preventDefault();
    if((itemMapping.item && itemMapping.item?.length > 0) || (itemMapping.property && itemMapping.property?.length > 0)){
     
    let itemId = [];
    let prpId = [];

    itemMapping.item && itemMapping.item.forEach((item)=>{
      itemId.push(item.id)
    });

    itemMapping.property && itemMapping.property.forEach((property)=>{
      prpId.push(property.id)
    });

    let formData = {
      recipe_id: itemMapping.id,
      item: JSON.stringify(itemId),
      property: JSON.stringify(prpId),
    }
    const url = BASE_URL + "/settings/recipeItem";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
         setItemMapping({
          id: null,
          item: null,
          property: null,
          uploading:false,
          modal: false,
         })
        toast.success(`${_t(t("Recipe has been linked"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false)
      })
      .catch((err)=>{
        setItemMapping({
          ...itemMapping,
          uploading:false,
         })
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false)
      })
    }else{
      toast.error("Please select atleast one item or property");
      return false
    }
    
  }

  //delete confirmation modal of waiter
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteRecipe(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  // mappping set states
  const handleMapping = (item) => {
    setLoading(true)
    const url = BASE_URL + `/settings/recipeItem/${item.id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        const foodItem = [];
        const property = [];
        res.data && res.data?.length && res.data.map(item =>{
           if(item.item_type === "FoodItem"){
            let food = foodForPOS.find(itm => itm.id === item.item_id);
            foodItem.push(food)
           }else{
            let propertyItem = propertyItemList.find(itm => itm.id === item.item_id);
            property.push(propertyItem)
           }
         })
         setItemMapping({
          ...itemMapping,
          id: item.id,
          item: foodItem?.length ? foodItem : null,
          property: property?.length ? property : null,
          modal: true,
         })
        setLoading(false)
      })
      .catch((err)=>{
        setItemMapping({
          ...itemMapping,
          id: item.id,
          item: null,
          property: null,
          modal: true,
         });
        setLoading(false)
      })

  }
  //delete purchase here
  const handleDeleteRecipe = async (id) => {
    setLoading(true);
    const url = BASE_URL + `/settings/recipe/${id}`;
    try {
          const res = await axios
              .delete(url, {
                  headers: { Authorization: `Bearer ${getCookie()}` },
              });
          setRecipeList(res.data[0]);
          setRecipeForSearch(res.data[1]);
          setSearchedRecipe({
              ...searchedRecipe,
              list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Recipe has been deleted successfully"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
          });
      } catch {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
          });
      }
  };
  return (
    <>
      <Helmet>
        <title>{_t(t("Recipes"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedRecipe.searched
                                    ? _t(t("Recipe List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <NavLink
                                  to="/dashboard/manage/stock/create-recipe"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                >
                                  {_t(t("add new"))}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Recipe Type"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Recipe Item"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Remarks"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedRecipe.searched
                                ? [
                                  recipeList && [
                                    recipeList.data.length ===
                                      0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="7"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      recipeList.data.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (recipeList.current_page -
                                                    1) *
                                                  recipeList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.type}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.item_name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.remarks || "--"}
                                              </td>
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/*<div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <NavLink
                                                      to={`/dashboard/manage/stock/recipe-edit/${item.id}`}
                                                      className="dropdown-item sm-text text-capitalize"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("View/Edit"))}
                                                    </NavLink>

                                                    <NavLink
                                                      to={`/dashboard/manage/stock/recipe-edit/${item.id}?duplicate=1`}
                                                      className="dropdown-item sm-text text-capitalize"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Duplicate Recipe"))}
                                                    </NavLink>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                    </div>*/}
                                               <NavLink
                                                 title="edit"
                                                  to={`/dashboard/manage/stock/recipe-edit/${item.id}`}
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </NavLink>
                                              
                                               <NavLink
                                                  title="duplicate recipe"
                                                  to={`/dashboard/manage/stock/recipe-edit/${item.id}?duplicate=1`}
                                                  className="btn btn-success ml-2 rounded-sm del-btn"
                                                >
                                                  <i className="fa fa-clone"></i>  
                                                </NavLink>
                                                {
                                                  item.type !== "Sub Recipe" && (
                                                  <button
                                                  title="item mapping"
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleMapping(item)}
                                                  >    
                                                   <i class="fa fa-link"></i>                                                
                                                 </button>)
                                                }
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]
                                : [
                                  /* searched data, logic === haveData*/
                                  searchedRecipe && [
                                    searchedRecipe.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="7"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedRecipe.list.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (recipeList.current_page -
                                                    1) *
                                                  recipeList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.type}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.item_name || "--"}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.remarks}
                                              </td>
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                 <div className="dropdown-menu">
                                                    <NavLink
                                                      to={`/dashboard/manage/stock/recipe-edit/${item.id}`}
                                                      className="dropdown-item sm-text text-capitalize"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("View/Edit"))}
                                                    </NavLink>

                                                    <NavLink
                                                      to={`/dashboard/manage/stock/recipe-edit/${item.id}?duplicate=1`}
                                                      className="dropdown-item sm-text text-capitalize"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Duplicate Recipe"))}
                                                    </NavLink>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                    </div>*/}
                                                <NavLink
                                                 title="edit"
                                                  to={`/dashboard/manage/stock/recipe-edit/${item.id}`}
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </NavLink>
                                              
                                               <NavLink
                                                  title="duplicate recipe"
                                                  to={`/dashboard/manage/stock/recipe-edit/${item.id}?duplicate=1`}
                                                  className="btn btn-success ml-2 rounded-sm del-btn"
                                                >
                                                  <i className="fa fa-clone"></i>  
                                                </NavLink>
                                                {
                                                  item.type !== "Sub Recipe" && (
                                                    <button
                                                    title="item mapping"
                                                    className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleMapping(item)}
                                                    >    
                                                     <i class="fa fa-link"></i>                                                
                                                   </button>
                                                  )
                                                }
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedRecipe.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              recipeList,
                              setPaginatedRecipeList
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(recipeList)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedRecipe({
                                    ...searchedRecipe,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedRecipe,
                                  recipeForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
        <div>
        {/* item map modal */}
        <div
        className={`modal fade ${
        itemMapping.modal === true ? "showing" : ""
        }`}
        aria-hidden="true">
        <div className="modal-dialog modal-lg itemMapping">
          <div className="modal-content">
            <div className="modal-header align-items-center">
                <div className="fk-sm-card__content">
                <h5 className="fk-sm-card__title">
                    Item Mapping
                </h5>
                </div>
                <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                    setItemMapping({
                    ...itemMapping,
                    modal: false
                    })
                }}
                ></button>
            </div>
            
            <div className="modal-body">

                {/* show form or show saving loading */}
                {itemMapping.uploading === false ? (
                <div key="fragment-food-group-1">
                    <form onSubmit={handleSaveRecipe} className="mx-2" autoComplete="off">
                    <div className= "pr-1 m-1 recipe_height">
                     <div className="row">
                      <div className="form-group mt-3 col-6">
                      <div className="mb-2">
                        <label
                          htmlFor="items"
                          className="control-label"
                        >
                          {_t(t("Items"))}
                        </label>
                      </div>
                        <Select
                        isMulti
                        menuPlacement={ itemMapping.item?.length > 5 ? "top" : "bottom" }
                        options={foodForPOS}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        onChange={handleItemChange}
                        value={itemMapping.item}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        maxMenuHeight="12rem"
                        menuPosition="fixed"
                        placeholder={
                          _t(t("Please Select Item")) + ".."
                        }
                      />
                      </div>

                      <div className="form-group mt-3 col-6">
                      <div className="mb-2">
                        <label
                          htmlFor="item"
                          className="control-label"
                        >
                          {_t(t("Properties"))}
                        </label>
                      </div>
                        <Select
                        isMulti
                        menuPlacement={ itemMapping.property?.length > 5 ? "top" : "bottom" }
                        options={propertyItemList}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        onChange={handlePropertyChange}
                        value={itemMapping.property}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        maxMenuHeight="12rem"
                        menuPosition="fixed"
                        placeholder={
                          _t(t("Please Select property")) + ".."
                        }
                      />
                      </div>
                     
                     </div>
                    </div>
                    <div className="row  my-4">
                      <div className="col-10"></div>
                      <div className="col-2 pr-4">
                        <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary col-12 table-text text-uppercase rounded"
                        >
                            {_t(t("Save"))}
                        </button>
                      </div>
                    </div>
                    </form>
                </div>
                ) : (
                <div key="fragment2">
                    <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                    </div>
                    {modalLoading(5)}
                </div>
                )}
            </div>
           </div>
         </div>
      </div>
    </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default ViewRecipe;
