import React, { useEffect, useContext, useRef, useState } from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

//importing context consumer here
import { UserContext } from "../../../contexts/User";
import { SettingsContext } from "../../../contexts/Settings";
import { FoodContext } from "../../../contexts/Food";
import { RestaurantContext } from "../../../contexts/Restaurant";
import { 
SAAS_APPLICATION,
BASE_URL,
saas_apiUrl,
saas_apiParams,
saas_form_data
} from "../../../BaseUrl";

//functions
import {
  _t,
  restaurantMenuLink,
  deleteCookie,
  getSystemSettings,
} from "../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import Skeleton from "react-loading-skeleton";

const RestaurantHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { generalSettings } = useContext(SettingsContext);
  const { authUserInfo  } = useContext(UserContext);
  const kdsModal = useRef("");
  const [kitchen,setKitchen] = useState(null);
  const [takeaway,setTakeaway] = useState(null);
  const [dinein,setDinein] = useState(null);
  const {
    //common
    loading,
    setLoading,
    // getFoodGroup,
  } = useContext(FoodContext);

  const {
    kdsList,
  } = useContext (RestaurantContext)

  //useEffect == componentDidMount()
  useEffect(() => {
    //getFoodGroup();
    if(kdsList !== null){
      kdsList && kdsList?.length && Array.isArray(kdsList) && kdsList.map((value)=>{
        if(value.name==='Kitchen'){
          setKitchen(value);
        }else if(value.name==='Takeaway'){
          setTakeaway(value)
        }else{
          setDinein(value);
        }
      })
    }
  }, [kdsList]);

  //logout
  const handleLogout = () => {
    deleteCookie();
  };

  let isStatus = kitchen?.status==1 || takeaway?.status==1 || dinein?.status==1;
  let isPermission = authUserInfo.permissions !== null && (authUserInfo.permissions.includes("Show Kitchen") || authUserInfo.permissions.includes("Show Takeaway") || authUserInfo.permissions.includes("Show Dine In"))

  
  return (
    <>
      <Helmet>
        <title>
          {generalSettings && getSystemSettings(generalSettings, "siteName")}
        </title>
      </Helmet>
      <main>  <div className={`${(loading || authUserInfo.permissions===null) && "loadingBlur"}`}></div>
       <div className={`${(loading || authUserInfo.permissions===null) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div className="fk-scroll--index t-mt-15 t-mb-15" data-simplebar>
          <div className="container">
            <div className="row gx-3">
             {/* Card Modal */}
        <div
        className="modal fade"
        ref={kdsModal}
        id="kdsModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content" style={{ borderRadius: "1rem" }}>
            <div className="modal-body py-3">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete="off">
                  <div>
                    <div className="addons-list__item mt-1 mx-1 row">
                    <label
                      htmlFor="name"
                      className="form-label d-flex justify-content-between font-weight-md xslg-text mb-3"
                    >
                      <span className="mt-2">{kdsList !== null && kdsList?.length > 0 && (isStatus) && (isPermission) ? _t(t("Kitchen Display Screen")) : _t(t("KDS Setting Empty / You don't have rights"))} </span>
                      <span
                        onClick={(e) => {
                          kdsModal.current.classList.remove("showing");
                        }}
                      >
                        <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                      </span>
                    </label>
                     {authUserInfo.permissions !== null && authUserInfo.permissions.includes("Show Kitchen") && 
                    kitchen?.status==1 && <NavLink to="/dashboard/kitchen" className="col-4 t-link" style={{color:" #121053"}}>
                      <img
                        src="/assets/img/kds.png"
                        alt={_t(t("kds"))}
                        className="img-fluid"
                      />
                      <div className="text-center font-weight-md mt-2">Kitchen</div>
                     </NavLink>}
                     {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Show Takeaway") &&takeaway?.status==1 && <NavLink to="/dashboard/kitchen/takeaway" className="col-4 t-link" style={{color:" #121053"}}>
                      <img
                          src="/assets/img/kds1.png"
                          alt={_t(t("kds"))}
                          className="img-fluid"
                        />
                       <div className="text-center font-weight-md mt-2">Takeaway Delivery</div>
                     </NavLink>}
                     {authUserInfo.permissions !== null && 
                     authUserInfo.permissions.includes("Show Dine In") && dinein?.status==1 && <NavLink to="/dashboard/kitchen/dinein" className="col-4 t-link" style={{color:" #121053"}}>
                      <img
                      src="/assets/img/kds2.png"
                      alt={_t(t("kds"))}
                      className="img-fluid"
                      />
                      <div className="text-center font-weight-md mt-2">Dine-In</div>
                     </NavLink>}
                    </div>
                  </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
            
              {/*!loading ? (*/}
                <>
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Work period")
                    ? restaurantMenuLink(
                      // "/assets/img/product-img-1.jpg",
                      "/assets/img/work-periods-2.jpg",
                      [_t(t("Work Periods"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Work Periods"))],
                      "/dashboard/work-periods"
                    )
                    : null}

                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("POS")
                    ? restaurantMenuLink(
                      // "/assets/img/product-img-2.jpg",
                      "/assets/img/POS1.jpg",
                      [_t(t("Point of Sale"))],
                      "fa fa-cart-plus",
                      "t-text-gamma",
                      [_t(t("Pos"))],
                      [_t(t("Point of Sale"))],
                      "/dashboard/pos"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Order history")
                    ? restaurantMenuLink(
                      // "/assets/img/product-img-3.jpg",
                      "/assets/img/order-histories1.jpg",
                      [_t(t("Order Histories"))],
                      "fa fa-pencil",
                      "t-text-delta",
                      [_t(t("Orders"))],
                      [_t(t("Order Histories"))],
                      "/dashboard/orders"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Customer")
                    ? restaurantMenuLink(
                      // "/assets/img/product-img-4.jpg",
                      "/assets/img/customers1.jpg",
                      [_t(t("Customers"))],
                      "fa fa-user-circle-o",
                      "t-text-primary",
                      [_t(t("Customers"))],
                      [_t(t("Customers"))],
                      "/dashboard/customers"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Kitchen")
                    ? (
                    <div className="col-md-6 col-lg-4 t-mb-15">
                      <div onClick={()=> kdsModal.current.classList += " showing"} className="t-link product-card t-bg-white w-100 cursor-pointer">
                        <div className="product-card__head w-100 text-center">
                          <img src="/assets/img/Kitchen2.jpg" alt={"Kitchen"} className="img-fluid" />
                        </div>
                        <div className="product-card__body w-100">
                          <div className="product-card__add">
                            <span className="product-card__add-icon">
                              <span className="las la-plus"></span>
                            </span>
                          </div>
                          <span
                            className={`product-card__sub-title t-text-epsilon text-uppercase`}
                          >
                            <span className="fa fa-coffee"></span> Kitchen
                          </span>
                          <span className="product-card__title text-capitalize">Kitchen</span>
                        </div>
                      </div>
                    </div>
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Report")
                    ? restaurantMenuLink(
                      // "/assets/img/product-img-7.jpg",
                      "/assets/img/report1.jpg",
                      [_t(t("Reports"))],
                      "fa fa-clock-o",
                      "t-text-kappa",
                      [_t(t("Reports"))],
                      [_t(t("Reports"))],
                      "/dashboard/reports"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Manage")
                    ? restaurantMenuLink(
                      // "/assets/img/product-img-8.png",
                      "/assets/img/manage1.jpg",
                      [_t(t("Manage"))],
                      "fa fa-clock-o",
                      "t-text-zeta",
                      [_t(t("Manage"))],
                      [_t(t("Manage"))],
                      "/dashboard/manage/food/add-new"
                    )
                    : null}

                    {/* accounts module*/}
                    {authUserInfo.permissions !== null &&
                      authUserInfo.permissions.includes("Accounts") &&
                      parseInt(getSystemSettings(generalSettings, "accounts_access")) === 1
                      ? restaurantMenuLink(
                        // "/assets/img/product-img-8.png",
                        "/assets/img/accountant.jpg",
                        [_t(t("Accounts"))],
                        "fa fa-clock-o",
                        "t-text-zeta",
                        [_t(t("Accounts"))],
                        [_t(t("Accounts"))],
                        "/dashboard/manage/accounts/gl-setting"
                      )
                      : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {/* {SAAS_APPLICATION == 'YES' ? } */}

                  {SAAS_APPLICATION == 'YES' ? [
                    authUserInfo.permissions !== null &&
                      authUserInfo.permissions.includes("Saas profile")
                      ? restaurantMenuLink(
                        // "/assets/img/product-img-subs.png",
                        "/assets/img/subscription1.jpg",
                        [_t(t("Saas profile"))],
                        "fa fa-cart-plus",
                        "t-text-gamma",
                        [_t(t("Subscription"))],
                        [_t(t("Subscription"))],
                        "/dashboard/saas-profile"
                      )
                      : null
                  ] : ''}

                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Delivery")
                    ? restaurantMenuLink(
                      // "/assets/img/product-img-2.jpg",
                      "/assets/img/POS1.jpg",
                      [_t(t("Assigned Orders"))],
                      "fa fa-cart-plus",
                      "t-text-gamma",
                      [_t(t("Assigned Orders"))],
                      [_t(t("Assigned Orders"))],
                      "/dashboard/delivery/assigned-orders"
                    )
                    : null}

                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Delivery")
                    ? restaurantMenuLink(
                      "/assets/img/order-histories1.jpg",
                      [_t(t("Delivery Histories"))],
                      "fa fa-pencil",
                      "t-text-delta",
                      [_t(t("Deliveries"))],
                      [_t(t("Delivery Histories"))],
                      "/dashboard/delivery/delivered-orders"
                    )
                    : null}
                  {authUserInfo.permissions !== null ?
                  <div className="col-md-6 col-lg-4 t-mb-15">
                    <button
                      onClick={handleLogout}
                      className="t-link w-100 product-card t-bg-white pb-2 border-0 text-left"
                      style={{height:"100%"}}
                    >
                      <div className="product-card__head">
                        <img
                          // src="/assets/img/product-img-6.jpg"
                          src="/assets/img/logout1.jpg"
                          alt={_t(t("Logout"))}
                          className="img-fluid"
                        />
                      </div>
                      <div className="product-card__body">
                        <div className="product-card__add">
                          <span className="product-card__add-icon">
                            <span className="las la-plus"></span>
                          </span>
                        </div>
                        <span
                          className={`product-card__sub-title t-text-alpha text-uppercase`}
                        >
                          <span className="fa fa-clock-o"></span>{" "}
                          {_t(t("Logout"))}
                        </span>
                        <span className="product-card__title text-capitalize">
                          {_t(t("Logout"))}
                        </span>
                      </div>
                    </button>
                  </div>:null}
                </>
                {
                  /*
            
              ) : (
                <>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                </>
              )}
              */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withRouter(RestaurantHome);
