import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const OrderTracking = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch } =
    useContext(RestaurantContext);
  const { authUserInfo } = useContext(UserContext);

  let [newOrderTracking, setNewOrderTracking] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);

  const componentRef = useRef();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()

  const [branchSearch, setBranchSearch] = useState(null);
  const [option,setOption] = useState("view");
  const [viewReport,setViewReport] = useState(false)

  useEffect(() => {
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }

    setSiteName(getSystemSettings(generalSettings, "siteName"));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  const handleOption  = (e) =>{
    setOption(e.target.value)
  }

  const handleBranch = (branch) => {
    setNewOrderTracking({ ...newOrderTracking, branch });
    // setBranch(branch);
  };

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setReportData(null);

    if (
      // newOrderTracking?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      setLoading(true);
      let fromDate = moment(startDate).format("YYYY-MM-DD");
      let toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-kds-tracking-data";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newOrderTracking?.branch?.id ? newOrderTracking.branch?.id : "");

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("OrderTrackingResult", res);
          setReportData(res.data);
          if(option==="excel"){
            setGeneratedReport(true);
            componentRef.current.handleDownload();
          }else{
            setViewReport(true);
          }
          setLoading(false);
          setNewOrderTracking(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Order tracking report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="order tracking report"
        sheet="sheet 1"
        buttonText="Order Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {"\
          @page { size: landscape; }\
        "}
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                {console.log("Abcd44",generatedReport)}
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        {console.log("Abcd44",reportData)}
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="16" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Order Tracking Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                        
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Branch Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Token No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Punching Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Dispatch Time"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                   {_t(t("Online Order Accept Time"))}
                                 </th>
                                 
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                   {_t(t("Online Order Cancel Time"))}
                                 </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cancel Time"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Settled Time"))}
                                  </th>
                                  
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Kitchen Order Ready Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Refund Time"))}
                                  </th>
                                 
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                  {_t(t("Ref No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("User Name"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                  {_t(t("Status"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {reportData.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center "
                                          >
                                           {index + 1}
                                          </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                             <Moment format="LL">
                                                {item.order_date}
                                             </Moment>
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.branch_name}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.token}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.document_no}
                                            </td>
                                            
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.order_punched ? item.order_punched : "-"}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.order_dispatched ? item.order_dispatched : "-"}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.online_order_accept ? item.online_order_accept : "-"}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.online_order_cancel ? item.online_order_cancel : "-"}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.cancelled ? item.cancelled : "-"}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.settled ? item.settled : "-"}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.kitchen_order_ready ? item.kitchen_order_ready : "-"}
                                            </td>
                                            
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.refund ? item.refund : "-"}
                                            </td>
                                            
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.ref_no}
                                            </td>

                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                            {item.user_name}
                                            </td>
                                            <td class="xsm-text text-capitalize align-middle text-center">
                                            {parseInt(
                                              item.is_cancelled
                                            ) === 0 ? (
                                              [
                                                parseInt(
                                                  item. is_paid
                                                ) === 1 &&
                                                parseInt(
                                                  item.is_refund
                                                ) === 0 ? (
                                                  <span class="btn btn-transparent btn-success xsm-text text-capitalize px-4">
                                                    {_t(t("Paid"))}
                                                  </span>
                                                ) : parseInt(
                                                    item.is_refund
                                                  ) === 1 ? (
                                                  <span class="btn btn-transparent btn-secondary xsm-text text-capitalize ">
                                                    {_t(t("Refund"))}
                                                  </span>
                                                ) : (
                                                  <span class="btn btn-transparent btn-secondary xsm-text text-capitalize ">
                                                    {_t(t("processing"))}
                                                  </span>
                                                ),
                                              ]
                                            ) : (
                                              <span class="btn btn-transparent btn-primary xsm-text text-capitalize px-3">
                                                {_t(t("Cancelled"))}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading <div className={`${dataPaginating && "loading"}`}></div>*/}
                    
                    {/* spin loading ends */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* Loading effect 
                    {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-6 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Order tracking report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-6 d-flex justify-content-end p-0">
                            <div className="d-inline mr-2 d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input pointer-cursor"
                              id="view_report"
                              name="view_report"
                              value="view"
                              onChange={(e) => {
                                handleOption(e);
                              }}
                              checked={
                                option === "view"
                                  ? 1
                                  : 0
                              }
                            />
                            <label
                              className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                              htmlFor="view_report"
                              style={{fontSize:"16px",borderRadius:"5px"}}
                            >
                              <i class="fa fa-eye" aria-hidden="true"></i> {_t(t("View"))}
                            </label>
                            </div>
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="excel_report"
                                  name="excel_report"
                                  value="excel"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "excel"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="excel_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                                </label>
                              </div>

                           </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-3 t-mb-15 mb-md-0">
                            <Select
                              options={branchSearch && branchSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              value={newOrderTracking?.branch}
                              key={newOrderTracking?.branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div>
                          <div className="col-2"></div>
                          <div className="col-3 t-mb-15 mb-md-0 d-none d-md-block text-right">
                          <button
                            className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                            onClick={(e) => getBranchWiseSelected(e)}
                          >
                            Order Tracking Report
                          </button>
                        </div>
                        </div>
                       
                      </div>
                      {reportData !== null &&
                        reportData !== undefined &&
                        viewReport === true && (
                          <div className="mt-3">
                              <div className="text-center xslg-text">
                                Order Tracking Report
                              </div>
                              <div className="fk-scroll--order-submitted" data-simplebar>
                              <div className="t-pl-15 t-pr-15"> 
                                <table
                                  className="table table-bordered table-hover min-table-height mt-3"
                                >
                                  <thead className="align-middle">
                                    <tr>
                                      
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("S/L"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Date"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_child_ordertype"
                                      >
                                        {_t(t("Branch Name"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Token No"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Document No"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Punching Time"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Dispatch Time"))}
                                      </th>
                                      <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                       {_t(t("Online Accept Time"))}
                                     </th>
                                     
                                      <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                       {_t(t("Online Cancel Time"))}
                                     </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Cancel Time"))}
                                      </th>
                                      <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Settled Time"))}
                                      </th>
                                      
                                      <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Ready Time"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("Refund Time"))}
                                      </th>
                                     
                                      <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                      {_t(t("Ref No"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                        {_t(t("User Name"))}
                                      </th>
                                      <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border table_report_col"
                                      >
                                      {_t(t("Status"))}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                    {/* loop here*/}
                                    {reportData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                              <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center "
                                              >
                                               {index + 1}
                                              </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                 <Moment format="LL">
                                                    {item.order_date}
                                                 </Moment>
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.branch_name}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.token}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.document_no}
                                                </td>
                                                
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.order_punched ? item.order_punched : "-"}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.order_dispatched ? item.order_dispatched : "-"}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.online_order_accept ? item.online_order_accept : "-"}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.online_order_cancel ? item.online_order_cancel : "-"}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.cancelled ? item.cancelled : "-"}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.settled ? item.settled : "-"}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.kitchen_order_ready ? item.kitchen_order_ready : "-"}
                                                </td>
                                                
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.refund ? item.refund : "-"}
                                                </td>
                                                
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.ref_no}
                                                </td>
          
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                {item.user_name}
                                                </td>
                                                <td class="xsm-text text-capitalize align-middle text-center">
                                                {parseInt(
                                                  item.is_cancelled
                                                ) === 0 ? (
                                                  [
                                                    parseInt(
                                                      item. is_paid
                                                    ) === 1 &&
                                                    parseInt(
                                                      item.is_refund
                                                    ) === 0 ? (
                                                      <span class="btn btn-transparent btn-success xsm-text text-capitalize px-4">
                                                        {_t(t("Paid"))}
                                                      </span>
                                                    ) : parseInt(
                                                        item.is_refund
                                                      ) === 1 ? (
                                                      <span class="btn btn-transparent btn-danger xsm-text text-capitalize">
                                                        {_t(t("Refund"))}
                                                      </span>
                                                    ) : (
                                                      <span class="btn btn-transparent btn-secondary xsm-text text-capitalize">
                                                        {_t(t("processing"))}
                                                      </span>
                                                    ),
                                                  ]
                                                ) : (
                                                  <span class="btn btn-transparent btn-danger xsm-text text-capitalize px-3">
                                                    {_t(t("Cancelled"))}
                                                  </span>
                                                )}
                                              </td>
                                            </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              </div>
                            </div>
                        )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default OrderTracking;
