import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


//context consumer
import { SettingsContext } from "../../../../contexts/Settings";


const CompleteSales = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, generalSettings } =
      useContext(SettingsContext);
    
    //all data
    const [reportData, setReportData] = useState(null);
  
    const componentRef = useRef();
    //settle order
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [checkPass, setCheckPass] = useState("");
    const passModal = useRef("");
    const password = "adsy#280@"
    //useEffect == componentDidMount()
  
    useEffect(() => {
      setSiteName(getSystemSettings(generalSettings, "siteName"));
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, [generalSettings]);

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(checkPass !== password){
            toast.error(
                `${_t(t("Incorrect Password"))}`,
              );
        }else{
            passModal.current.classList.remove("showing");
            handleBranchWiseReport();
        }
    }

  
      //get BranchWise reports filter
  const handleBranchWiseReport = () => {
    if (startDate !== null && endDate !== null) {
      if(checkPass !== password){
        passModal.current.classList += " showing";
        return false;
      }  
      setLoading(true);
     
      var startdate = moment(startDate).format("YYYY-MM-DD");
      var enddate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/complete-sales-report";

      let formData = new FormData();
      formData.append("from_date", startdate);
      formData.append("to_date", enddate);
     
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setReportData(res.data[0]);
          setLoading(false);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setStartDate(null);
          setEndDate(null);
          setCheckPass("")
        })
        .catch((error) => {
        
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, branch"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };
  return (
    <>
    <Helmet>
      <title>{_t(t("Complete Sales"))}</title>
    </Helmet>
    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Complete Sales"
      sheet="sheet 1"
      buttonText="Sales Detail"
      ref={componentRef}
    />

    {/* Print Button */}
    <div className="d-none">
      <div>
        <style type="text/css" media="print">
          {
            "\
        @page { size: landscape; }\
      "
          }
        </style>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {reportData !== null &&
                  reportData !== undefined &&
                  generatedReport === true && (
                    <>
                      <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                <th colspan="7" style={{ border: "0px" }}>
                                  <div className="row">
                                    <div className="col-12">
                                      <h3 className="text-center mb-0">
                                        {siteName}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                       Complete Sales
                                      </h3>
                                      <p className="text-center">
                                        FROM&nbsp;
                                        <Moment format="LL">
                                          {startDate}
                                        </Moment>
                                        To&nbsp; <Moment format="LL">{endDate}</Moment>
                                      </p>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                              
                                <th
                                  scope="col"
                                >
                                  {_t(t("S/L"))}
                                </th>
                                <th
                                    scope="col"
                                >
                                    {_t(t("DB Name"))}
                                </th>
                                <th
                                  scope="col"
                                >
                                  {_t(t("Site Name"))}
                                </th>
                                
                                <th
                                  scope="col"
                                >
                                  {_t(t("Total Branches"))}
                                </th>

                                <th
                                  scope="col"
                                >
                                  {_t(t("On Branches"))}
                                </th>
                               
                                <th
                                  scope="col"
                                >
                                  {_t(t("Order Count"))}
                                </th>

                                <th
                                  scope="col"
                                >
                                  {_t(t("Total Amount"))}
                                </th>
                               
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here*/}
                             
                              {reportData.map((item, i) => {
                                const newDbName = item.db_name.replace("indoljposcpanel_", "");
                                 
                                return (
                                  <tr>
                                    <td
                                      scope="row"
                                    >
                                      {i + 1}
                                    </td>
                                    <td
                                      scope="row"
                                    >
                                      {newDbName}
                                    </td>
                                    <td
                                      scope="row"
                                    >
                                      {item.site_name}
                                    </td>
                                    <td
                                      scope="row"
                                    >
                                      {item.branches}
                                    </td>
                                    <td
                                      scope="row"
                                    >
                                      {item.on_branches}
                                    </td>
                                    <td
                                      scope="row"
                                    >
                                      {item.order_count}
                                    </td>
                                    
                                    <td
                                      scope="row"
                                    >
                                     <strong>
                                      {item.total_payable_sum ? formatPrice(item.total_payable_sum) : 0}
                                     </strong>
                                    </td>
                                  
                                  </tr>
                                );
                              })}
                             
                            </tbody>

                            <tfoot style={{ border: "0px" }}>
                              <tr style={{ border: "0px" }}>
                                <td
                                  colspan="5"
                                  className="text-center"
                                  style={{ border: "0px" }}
                                >
                                  <h5 className="mt-3">
                                    Powered by indolj.pk
                                  </h5>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          <div className="col-12 t-mb-2 mb-lg-0">
            <div className="t-bg-white p-1">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                   <>
                    <div key="smtp-form mt-2">
                      <div className="row mt-2 gx-2 align-items-center t-pt-2 t-pb-2">
                        <div className="col-12 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize mt-2">
                                {_t(t("Complete Sales"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row gx-2 mt-2">
                        <div className="col-md-2 d-md-block mt-1">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2 t-mb-2 mb-md-0"
                            placeholderText={_t(t("Start Date"))}
                            shouldCloseOnSelect={true}
                          />
                        </div>
                        <div className="col-md-2 d-md-block mt-1">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control sm-text py-2 t-mb-2 mb-md-0"
                          placeholderText={_t(t("End Date"))}
                          shouldCloseOnSelect={true}
                        />
                      </div>

                        <div className="col-md-6 t-mb-15 mb-md-0 mt-1">
                       
                        </div>

                        <div className="col-md-2 t-mb-15 mb-md-0 mt-1">
                            <button
                            className="float-right btn btn-block btn-primary text-uppercase sm-text py-2"
                            onClick={(e) => handleBranchWiseReport(e)}
                            >
                            Generate Report
                            </button>
                          
                        </div>
                        
                      </div>

                    </div>

                   </>
                </div>

              </div>
                
            </div>
            
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
      {/* password Modal */}
       <div
        className="modal fade"
      ref={passModal}
      id="passModal"
      aria-hidden="true"
       >
        <div className="modal-dialog modal-md">
          <div className="modal-content" style={{ borderRadius: "1rem" }}>
           <div className="modal-body py-3">
             {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <div>
                    <div className="addons-list__item mt-1 mx-1 row">
                      <label
                        htmlFor="name"
                        className="form-label p-0 d-flex justify-content-between font-weight-md xslg-text mb-3"
                      >
                        <span className="mt-2">Enter Your Password</span>
                        <span
                        onClick={(e) => {
                            passModal.current.classList.remove("showing");
                        }}
                        >
                        <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                        </span>
                      </label>
                        
                        <input
                            type="password"
                            onKeyDown={(evt) =>
                            ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                            evt.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}  
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Enter Password"
                            autoComplete="off"
                            value={checkPass}
                            required
                            onChange={(e) => setCheckPass(e.target.value)}
                        />
                    </div>
                    <div className="mt-4">
                        <div className="row mt-1">
                         <div className="col-8"></div>
                         <div className="col-4">
                            <button
                            type="submit"
                            className="btn btn-success font-weight-bold sm-text text-uppercase t-width-max w-100"
                            >
                            {_t(t("Submit"))}
                            </button>
                         </div>
                        </div>
                    </div>
                    </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default CompleteSales
